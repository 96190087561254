import { FetchResult } from '@apollo/client';
import { internalApiClient } from '/@/api/graphql/appApolloClient';
import { getAnnouncementGql } from './index.gql';
import {
  GetAnnouncementQuery,
  GetAnnouncementQueryVariables,
} from '/@/api/graphql/internalApi/types';

type GetAnnouncementResponse = FetchResult<GetAnnouncementQuery>;

const getAnnouncement = (
  variables?: GetAnnouncementQueryVariables,
): Promise<GetAnnouncementResponse> => {
  return internalApiClient.query({
    query: getAnnouncementGql,
    variables,
  });
};

export { getAnnouncement };

export type { GetAnnouncementResponse };
