import { Reducer } from 'redux';
import type { RootAction } from '/@/store/actions';
import { createPointChargeWithSerialCodeTypes } from '/@/store/api/mutations/createPointChargeWithSerialCode';

type ChargedPointState = ChargedPoint | null;

type ChargedPoint = {
  pointAmount: number;
  withdrawableUntilAt: string;
};

const initialState: ChargedPointState = null;

const chargedPoint: Reducer<ChargedPointState, RootAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case createPointChargeWithSerialCodeTypes.CREATE_POINT_CHARGE_WITH_SERIAL_CODE_SUCCESS:
      return action.createPointChargeWithSerialCodeResponse.pointCharge;
    default:
      return state;
  }
};

export { chargedPoint };
export type { ChargedPointState };
