import { PATHS } from '/@/routes/paths';
import { DestinationEnum } from '/@/api/graphql/publicApi/types';
import { AuthorizationPageState } from '../store/page/oauthPage/authorizationPage';
import { Location } from 'history';

type OauthSession = NonNullable<AuthorizationPageState['oauthSession']>;
type GiftSavingOption = NonNullable<OauthSession['giftSavingOption']>;

const recomposeForQuery = (
  giftSavingOption: GiftSavingOption,
): Record<string, string> => {
  if (typeof giftSavingOption.referer === 'string') {
    return {
      gift_url: giftSavingOption.url,
      gift_origin: giftSavingOption.origin,
      referer: giftSavingOption.referer,
      convert_to_point: giftSavingOption.convertToPoint?.toString() || 'false',
    };
  } else {
    return {
      gift_url: giftSavingOption.url,
      gift_origin: giftSavingOption.origin,
      convert_to_point: giftSavingOption.convertToPoint?.toString() || 'false',
    };
  }
};

const decideGoForwardLocation = (oauthSession: OauthSession): Location => {
  if (oauthSession.giftSavingOption) {
    const query = new URLSearchParams(
      recomposeForQuery(oauthSession.giftSavingOption),
    ).toString();
    return {
      pathname: PATHS.GIFTS_NEW,
      search: `?${query.toString()}`,
      hash: '',
      state: undefined,
      key: PATHS.GIFTS_NEW,
    };
  }

  if (oauthSession.destinationOption) {
    switch (oauthSession.destinationOption.name) {
      case DestinationEnum.Promotions:
        return {
          pathname: PATHS.PROMOTIONS,
          search: '',
          hash: '',
          state: undefined,
          key: PATHS.PROMOTIONS,
        };
      case DestinationEnum.Gifts:
        return {
          pathname: PATHS.HOME,
          search: '',
          hash: '',
          state: undefined,
          key: PATHS.HOME,
        };
      case DestinationEnum.Inquiry:
        return {
          pathname: PATHS.INQUIRIES_NEW,
          search: '',
          hash: '',
          state: undefined,
          key: PATHS.INQUIRIES_NEW,
        };
      case DestinationEnum.Brands:
        return {
          pathname: PATHS.POINT_BRANDS,
          search: '',
          hash: '',
          state: undefined,
          key: PATHS.POINT_BRANDS,
        };
      case DestinationEnum.PointLogs:
        return {
          pathname: PATHS.POINT_POINT_LOGS,
          search: '',
          hash: '',
          state: undefined,
          key: PATHS.POINT_POINT_LOGS,
        };
    }
  }

  if (oauthSession.pointSerialOption) {
    return {
      pathname: PATHS.POINT_CHARGE_SERIAL_CODE_NEW,
      search: `?serial_code=${oauthSession.pointSerialOption.serialCode}`,
      hash: '',
      state: undefined,
      key: PATHS.POINT_CHARGE_SERIAL_CODE_NEW,
    };
  }

  return {
    pathname: PATHS.HOME,
    search: '',
    hash: '',
    state: undefined,
    key: PATHS.HOME,
  };
};

const decideGoBackLocation = (oauthSession: OauthSession): Location => {
  if (oauthSession.giftSavingOption) {
    const query = new URLSearchParams(
      recomposeForQuery(oauthSession.giftSavingOption),
    );
    return {
      pathname: PATHS.SIGN_IN,
      search: `?${query.toString()}`,
      hash: '',
      state: undefined,
      key: PATHS.SIGN_IN,
    };
  }
  if (oauthSession.destinationOption) {
    return {
      pathname: PATHS.SIGN_IN,
      search: `?destination=${oauthSession.destinationOption.name}`,
      hash: '',
      state: undefined,
      key: PATHS.SIGN_IN,
    };
  }
  if (oauthSession.pointSerialOption) {
    return {
      pathname: PATHS.SIGN_IN,
      search: `?serial_code=${oauthSession.pointSerialOption.serialCode}`,
      hash: '',
      state: undefined,
      key: PATHS.SIGN_IN,
    };
  }
  return {
    pathname: PATHS.SIGN_IN,
    search: '',
    hash: '',
    state: undefined,
    key: PATHS.SIGN_IN,
  };
};

export { decideGoForwardLocation, decideGoBackLocation };
