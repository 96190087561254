import React from 'react';
import { Container, Typography } from '@mui/material';
import { styled } from '@mui/system';
import pointChargeImage from '/@/assets/shared/benefitOfPointCharge/illustration/point-charge-image.png';

const StyledContainer = styled(Container)({
  width: '100%',
  textAlign: 'center',
});
const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.125rem',
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
  textAlign: 'center',
}));
const StyledTextPart1 = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontHeight: '1.2rem',
  marginTop: theme.spacing(2),
  color: theme.palette.secondary.dark,
}));
const StyledTextPart2 = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontHeight: '1.2rem',
  fontWeight: 'bold',
  color: theme.palette.warning.main,
}));
const StyledAttention = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontHeight: '1.2rem',
  color: theme.palette.secondary.light,
  marginTop: theme.spacing(2),
}));
const StyledImageContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
const StyledImage = styled('img')({
  display: 'block',
  margin: 'auto',
  width: '80%',
});

const Presenter: React.VFC<Record<string, never>> = () => (
  <StyledContainer maxWidth="sm" data-cy="benefitOfPointChargeIllustration">
    <StyledTitle>ポイントに移行するとさらにお得</StyledTitle>
    <StyledTextPart1>
      ポイントへ移行することで
      <br />
      多くの商品と交換ができるのはもちろんのこと、
    </StyledTextPart1>
    <StyledTextPart2>
      還元やポイント追加付与など
      <br />
      さらにお得にご利用することができます！
    </StyledTextPart2>
    <StyledAttention>
      ※ 獲得したポイント毎に有効期限が異なります。
      <br />※ 有効期限の近いポイントから利用されます。
    </StyledAttention>
    <StyledImageContainer>
      <StyledImage
        src={pointChargeImage}
        alt="ポイント移行イメージ"
        data-cy="pointChargeImage"
      />
    </StyledImageContainer>
  </StyledContainer>
);

export { Presenter };
