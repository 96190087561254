import { gql } from '@apollo/client';

export const getGiftConvertToPointRequestGql = gql`
  query GetGiftConvertToPointRequest($requestUid: String!) {
    giftConvertToPointRequest(requestUid: $requestUid) {
      convertPointAmount
      errorMessage
      myGift {
        pointMergeable
      }
      requestUid
    }
  }
`;
