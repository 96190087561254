import { FetchResult } from '@apollo/client';
import { internalApiClient } from '/@/api/graphql/appApolloClient';
import { getPointFeatureGroupsGql } from './index.gql';
import {
  GetPointFeatureGroupsQuery,
  GetPointFeatureGroupsQueryVariables,
} from '/@/api/graphql/internalApi/types';

type GetPointFeatureGroupsResponse = FetchResult<GetPointFeatureGroupsQuery>;

const getPointFeatureGroups = (
  variables: GetPointFeatureGroupsQueryVariables,
): Promise<GetPointFeatureGroupsResponse> => {
  return internalApiClient.query({
    query: getPointFeatureGroupsGql,
    variables: variables,
  });
};

export { getPointFeatureGroups };

export type { GetPointFeatureGroupsResponse };
