import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { ErrorMessage } from '/@/store/api/mutations/saveGifteeContent/selectors';
import { SavingGift } from './savingGift/Index';
import {
  LoginButton,
  LoginOption,
} from '/@/components/shared/loginButton/Index';
import { formatedISO8601Date } from '/@/utils/formatDate';

const StyledLoginSectionContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(4),
}));
const StyledAuthenticationErrorMessage = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 'bold',
  color: theme.palette.warning.main,
  padding: theme.spacing(3),
}));
const StyledSerialCodeOutOfPeriodMessage = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  textAlign: 'center',
  fontWeight: 'bold',
  color: theme.palette.warning.main,
}));
const StyledSerialCodePeriodMessage = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  textAlign: 'center',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
}));

type Props = LoginOption & {
  authenticationErrorMessage: ErrorMessage;
  isSerialCodeOutOfPeriod: boolean;
  serialCodeEndAt: string | null;
};

const Presenter: React.VFC<Props> = ({
  url,
  origin,
  convertToPoint,
  destination,
  serialCode,
  authenticationErrorMessage,
  isSerialCodeOutOfPeriod,
  serialCodeEndAt,
}) => {
  const buttonText = serialCode
    ? 'ログインしてポイントをチャージ'
    : url && origin
      ? 'ログインしてギフトを保存する'
      : 'ログインする';
  return (
    <div data-cy="signInPageLoginSection">
      {authenticationErrorMessage && (
        <StyledAuthenticationErrorMessage variant="body2">
          {authenticationErrorMessage}
        </StyledAuthenticationErrorMessage>
      )}
      {isSerialCodeOutOfPeriod && (
        <StyledSerialCodeOutOfPeriodMessage variant="body2">
          {`このURLは有効期限切れです`}
        </StyledSerialCodeOutOfPeriodMessage>
      )}
      {serialCodeEndAt && !isSerialCodeOutOfPeriod && (
        <StyledSerialCodePeriodMessage
          variant="body2"
          data-cy="serialCodePage-expiredAlertMessage"
        >
          {`チャージの有効期限は${formatedISO8601Date(serialCodeEndAt.replace(' ', '+'))}です`}
        </StyledSerialCodePeriodMessage>
      )}
      {url && origin && (
        <div>
          <SavingGift url={url} origin={origin} />
        </div>
      )}
      <StyledLoginSectionContainer>
        <LoginButton
          url={null}
          origin={null}
          referer={null}
          convertToPoint={convertToPoint}
          destination={destination}
          serialCode={serialCode}
        >
          {buttonText}
        </LoginButton>
      </StyledLoginSectionContainer>
    </div>
  );
};

export { Presenter };
