import {
  refreshMyGift,
  RefreshMyGiftResponse,
} from '/@/api/graphql/internalApi/mutations/refreshMyGift';
import type { AppThunk } from '/@/store';
import {
  refreshMyGiftStart,
  refreshMyGiftSuccess,
  refreshMyGiftFailure,
} from './actions';
import { handleErrorThunk } from '../../handleError';
import type { ApiError } from '/@/api/graphql/client';
import { RefreshMyGiftMutationVariables } from '/@/api/graphql/internalApi/types';

const refreshMyGiftThunk =
  (variables: RefreshMyGiftMutationVariables): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(refreshMyGiftStart());
    return refreshMyGift(variables)
      .then((response) => dispatch(refreshMyGiftSuccessThunk(response)))
      .catch((error) => dispatch(refreshMyGiftFailureThunk(error)));
  };

const refreshMyGiftSuccessThunk =
  (response: RefreshMyGiftResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { myGift } = response.data!.refreshMyGift;
    dispatch(refreshMyGiftSuccess(myGift));
    return Promise.resolve();
  };

const refreshMyGiftFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(refreshMyGiftFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { refreshMyGiftThunk };
