import React from 'react';
import { Presenter } from './Presenter';
import { PointFeatureGroup } from '/@/api/graphql/internalApi/types';

type Props = {
  pointFeatureGroup: PointFeatureGroup;
};

const Container: React.VFC<Props> = ({ pointFeatureGroup }) => {
  return <Presenter pointFeatureGroup={pointFeatureGroup} />;
};

export { Container as PointFeatureGroup };
