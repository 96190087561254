import React from 'react';
import {
  Typography,
  Grid2 as Grid,
  CardActionArea,
  TypographyProps,
} from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '/@/utils/theme';

const TICKET_IMAGE_SIZE = 70;
const StyledCardContent = styled(Grid)({
  minWidth: 0,
});
const StyledTicketImage = styled('img')(({ theme }) => ({
  marginRight: theme.spacing(2),
  height: TICKET_IMAGE_SIZE,
  width: TICKET_IMAGE_SIZE,
  borderRadius: theme.spacing(),
}));
const StyledTicketName = styled(Typography)(({ theme }) => ({
  maxWidth: '100%',
  fontWeight: 'bold',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  color: theme.palette.primary.dark,
}));
type StyledTicketExpiredAtProps = TypographyProps & { isExpiring: boolean };
const StyledTicketExpiredAt = styled(Typography)<StyledTicketExpiredAtProps>(
  ({ isExpiring, theme }) => ({
    fontWeight: 'bold',
    color: isExpiring
      ? theme.palette.warning.main
      : theme.palette.secondary.main,
  }),
);

export type Props = {
  url: string;
  name: string;
  imageUrl: string;
  expirationText: string;
  isExpiring: boolean;
};

const Presenter: React.VFC<Props> = ({
  url,
  name,
  imageUrl,
  expirationText,
  isExpiring,
}) => (
  <CardActionArea
    href={url}
    role="link"
    data-cy={`ticketSkelton-${url}`}
    style={{ padding: theme.spacing(2) }}
  >
    <Grid container wrap="nowrap" direction="row">
      <StyledTicketImage src={imageUrl} alt={name} />
      <StyledCardContent container justifyContent="center" direction="column">
        <StyledTicketName variant="body1">{name}</StyledTicketName>
        <StyledTicketExpiredAt
          data-cy="expirationText"
          variant="caption"
          isExpiring={isExpiring}
        >
          {expirationText}
        </StyledTicketExpiredAt>
      </StyledCardContent>
    </Grid>
  </CardActionArea>
);

export { Presenter };
