import React from 'react';
import {
  Typography,
  Container,
  Box,
  Grid2 as Grid,
  Button,
  ButtonProps,
} from '@mui/material';
import { styled } from '@mui/system';
import { GiftsAvailabilityTabs } from '../giftsPage/giftsAvailablityTabs/Index';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { ExpiringMyGifts } from '../giftsPage/expiringMyGifts/Index';
import { PATHS } from '/@/routes/paths';
import { Link } from 'react-router-dom';
import { PointSection } from './pointSection/Index';

const StyledBackground = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.contrast,
}));
const StyledBaseBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
}));
const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    borderRadius: theme.spacing(2),
  },
  backgroundColor: theme.palette.background.default,
  padding: 0,
}));
const StyledSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  padding: theme.spacing(2),
  fontWeight: 'bold',
  lineHeight: '1',
}));
type StyledButtonProps = ButtonProps & { to: string };
const StyledGiftsEditPageButton = styled(Button)<StyledButtonProps>(
  ({ theme }) => ({
    minWidth: '0',
    margin: theme.spacing(2),
    backgroundColor: theme.palette.background.hint,
    color: theme.palette.primary.dark,
    '&.MuiButton-contained': {
      '&.Mui-disabled': {
        backgroundColor: theme.palette.background.hint,
        color: theme.palette.secondary.main,
      },
    },
  }),
);
const StyledGiftsEditPageButtonLabel = styled(Typography)({
  fontWeight: 'bold',
});

type Props = {
  isGiftsEditPageButtonDisabled: boolean;
};
const Presenter: React.VFC<Props> = ({ isGiftsEditPageButtonDisabled }) => (
  <MainLayout>
    <StyledBackground>
      <PointSection />
      <StyledBaseBox>
        <StyledContainer maxWidth="sm">
          <Grid container alignItems="center" justifyContent="space-between">
            <StyledSubtitle variant="subtitle1">マイギフト</StyledSubtitle>
            <StyledGiftsEditPageButton
              disabled={isGiftsEditPageButtonDisabled}
              variant="contained"
              disableElevation
              component={Link}
              to={PATHS.GIFTS_EDIT}
              data-cy="giftsEditPageButton"
            >
              <StyledGiftsEditPageButtonLabel color="inherit" variant="caption">
                利用済みにする
              </StyledGiftsEditPageButtonLabel>
            </StyledGiftsEditPageButton>
          </Grid>
          <ExpiringMyGifts />
          <GiftsAvailabilityTabs />
        </StyledContainer>
      </StyledBaseBox>
    </StyledBackground>
  </MainLayout>
);

export { Presenter };
