import { gql } from '@apollo/client';

export const getItemDetailGql = gql`
  query GetItemDetail($uid: String!) {
    item(uid: $uid) {
      brand {
        uid
        name
        pointCaution
      }
      caution
      contentImageUrl
      contentName
      readableExpiration
      currentItemPointPhase {
        endAt
        point
        startAt
        variable {
          maxPoint
          minPoint
          step
        }
      }
      currentPointBackCampaign {
        beginAt
        description
        discountPercentage
        discountRate
        endAt
        normalDiscountPercentage
        normalDiscountRate
        uid
        limited
      }
      description
      uid
    }
  }
`;
