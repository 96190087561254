import React, { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '/@/store';
import { getUnavailableMyGiftsConnectionThunk } from '/@/store/api/queries/getMyGiftsConnection';
import { Presenter } from './Presenter';
import { useIntersectionObserver } from '/@/common/hooks/useIntersectionObserver';
import { STATUS } from '/@/store/api/constants';
import { selectUnavailableAtGroupedUnavailableMyGifts } from '/@/store/app/unavailableMyGiftsConnection/selectors';

const Container: React.VFC<Record<string, never>> = () => {
  const groupedUnavailableMyGifts = useAppSelector(
    selectUnavailableAtGroupedUnavailableMyGifts,
  );
  const unavailableMyGiftsConnectionPageInfo = useAppSelector(
    (state) => state.app.unavailableMyGiftsConnection.pageInfo,
  );
  const getUnavailableMyGiftsConnectionStatus = useAppSelector(
    (state) => state.app.unavailableMyGiftsConnection.status,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUnavailableMyGiftsConnectionThunk()).catch(() => {});
  }, [dispatch]);

  const infiniteScrollTarget = useRef<HTMLDivElement>(null);
  const { startObserving, isIntersect } =
    useIntersectionObserver(infiniteScrollTarget);

  useEffect(() => {
    startObserving(true);

    return () => {
      startObserving(false);
    };
  }, [startObserving]);

  useEffect(() => {
    if (
      isIntersect &&
      getUnavailableMyGiftsConnectionStatus !== STATUS.LOADING &&
      unavailableMyGiftsConnectionPageInfo?.hasNextPage
    ) {
      dispatch(
        getUnavailableMyGiftsConnectionThunk(
          unavailableMyGiftsConnectionPageInfo.endCursor,
        ),
      ).catch(() => {});
    }
  }, [
    dispatch,
    isIntersect,
    getUnavailableMyGiftsConnectionStatus,
    groupedUnavailableMyGifts,
    unavailableMyGiftsConnectionPageInfo,
  ]);

  return (
    <Presenter
      groupedUnavailableMyGifts={groupedUnavailableMyGifts}
      infiniteScrollTarget={infiniteScrollTarget}
      getUnavailableMyGiftsConnectionStatus={
        getUnavailableMyGiftsConnectionStatus
      }
    />
  );
};

export { Container as UnavailableMyGifts };
