import { getPointWallet } from '/@/api/graphql/internalApi/queries/getPointWallet';
import type { GetPointWalletResponse } from '/@/api/graphql/internalApi/queries/getPointWallet';
import type { AppThunk } from '/@/store';
import {
  getPointWalletStart,
  getPointWalletSuccess,
  getPointWalletFailure,
} from './actions';
import { handleErrorThunk } from '../../handleError';
import type { ApiError } from '/@/api/graphql/client';

const getPointWalletThunk = (): AppThunk<Promise<void>> => (dispatch) => {
  dispatch(getPointWalletStart());

  return getPointWallet()
    .then((response) => dispatch(getPointWalletSuccessThunk(response)))
    .catch((error) => dispatch(getPointWalletFailureThunk(error)));
};

const getPointWalletSuccessThunk =
  (response: GetPointWalletResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { pointWallet } = response.data!;
    dispatch(getPointWalletSuccess(pointWallet));
    return Promise.resolve();
  };

const getPointWalletFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getPointWalletFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { getPointWalletThunk };
