import { readAnnouncement } from '/@/api/graphql/internalApi/mutations/readAnnouncement';
import type { AppThunk } from '/@/store';
import {
  readAnnouncementStart,
  readAnnouncementSuccess,
  readAnnouncementFailure,
} from './actions';
import type { ReadUserAnnouncementInput } from '/@/api/graphql/internalApi/types';
import { handleErrorThunk } from '../../handleError';
import type { ApiError } from '/@/api/graphql/client';

const readAnnouncementThunk =
  (input: ReadUserAnnouncementInput): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(readAnnouncementStart());
    return readAnnouncement(input)
      .then(() => dispatch(readAnnouncementSuccessThunk()))
      .catch((error) => dispatch(readAnnouncementFailureThunk(error)));
  };

const readAnnouncementSuccessThunk =
  (): AppThunk<Promise<void>> => (dispatch) => {
    dispatch(readAnnouncementSuccess());
    return Promise.resolve();
  };

const readAnnouncementFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(readAnnouncementFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { readAnnouncementThunk };
