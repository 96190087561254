import React from 'react';
import { Typography, Grid2 as Grid, Card, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { GifteeBoxGifteeContentBase } from '/@/store/app/shared/types';
import { GifteeBox } from './gifteeBox/Index';
import { formatToDateJa } from '/@/utils/formatDate';

const StyledCard = styled(Card)(({ theme }) => ({
  border: 'none',
  borderRadius: theme.spacing(2),
}));

const StyledContent = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const StyledContentExpiredAt = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

const StyledContentName = styled(Typography)(({ theme }) => ({
  overflowWrap: 'break-word',
  fontWeight: 'bold',
  color: theme.palette.secondary.main,
}));

export type GifteeBoxGifteeContent = GifteeBoxGifteeContentBase;

type Props = {
  gifteeBoxGifteeContent: GifteeBoxGifteeContent;
};
const Presenter: React.VFC<Props> = ({ gifteeBoxGifteeContent }) => (
  <StyledCard data-cy="gifteeBoxGifteeContent" variant="outlined">
    <StyledContent>
      {gifteeBoxGifteeContent.savedAt && (
        <StyledContentExpiredAt
          variant="caption"
          data-cy="gifteeBoxGifteeContentSavedAt"
        >
          保存日時：{formatToDateJa(gifteeBoxGifteeContent.savedAt)}
        </StyledContentExpiredAt>
      )}
      <StyledContentName variant="body1">
        {gifteeBoxGifteeContent.content.name}
      </StyledContentName>
    </StyledContent>
    <Divider />
    <GifteeBox gifteeBox={gifteeBoxGifteeContent.content} />
  </StyledCard>
);

export { Presenter };
