import { createSelector } from 'reselect';
import { RootState } from '/@/store';
import { MyGiftBase } from '/@/store/app/shared/types';
import { PointConvertableMyGiftsState } from '/@/store/app/pointConvertableMyGiftsConnection';

export const myGifts = (state: RootState): PointConvertableMyGiftsState =>
  state.app.pointConvertableMyGiftsConnection.myGifts;

const renameContentNameMyGifts = (myGifts: PointConvertableMyGiftsState) => {
  const filteredNodes = myGifts
    ?.filter((node): node is MyGiftBase => !!node)
    .map((node) => {
      return {
        ...node,
        contentName: `${node.contentName}(${node.remainingPoint.toLocaleString('ja-JP')}ポイント)`,
      };
    });
  return filteredNodes || [];
};

export const formattedPointConvertableMyGifts = createSelector(
  [myGifts],
  (myGifts): MyGiftBase[] => renameContentNameMyGifts(myGifts),
);
