import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '/@/store';
import { getPointFeatureGroupsThunk } from '/@/store/api/queries/getPointFeatureGroups';
import { Presenter } from './Presenter';
import { STATUS } from '/@/store/api/constants';

const Container: React.VFC<Record<string, never>> = () => {
  const pointFeatureGroups = useAppSelector(
    (state) => state.app.pointFeatureGroups.nodes,
  );
  const pageInfo = useAppSelector(
    (state) => state.app.pointFeatureGroups.pageInfo,
  );
  const getPointFeatureGroupsStatus = useAppSelector(
    (state) => state.api.getPointFeatureGroups.status,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      getPointFeatureGroupsStatus === STATUS.IDLE &&
      pointFeatureGroups === null &&
      pageInfo === null
    ) {
      dispatch(getPointFeatureGroupsThunk({})).catch(() => {});
    }
  }, [dispatch, getPointFeatureGroupsStatus, pointFeatureGroups, pageInfo]);

  useEffect(() => {
    if (
      getPointFeatureGroupsStatus !== STATUS.LOADING &&
      pageInfo?.hasNextPage
    ) {
      dispatch(
        getPointFeatureGroupsThunk({ after: pageInfo?.endCursor ?? undefined }),
      ).catch(() => {});
    }
  }, [dispatch, getPointFeatureGroupsStatus, pageInfo]);

  return <Presenter pointFeatureGroups={pointFeatureGroups} />;
};

export { Container as PointFeatureGroups };
