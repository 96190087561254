import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { styled } from '@mui/system';
import { IconButton, Typography } from '@mui/material';

const StyledArrowBackIcon = styled(IconButton)({
  padding: 0,
});
const StyledTitle = styled(Typography)({
  fontWeight: 'bold',
});

type Props = {
  headerTitle: string;
  handleNavigateBack: () => void;
};

const Presenter: React.VFC<Props> = ({ headerTitle, handleNavigateBack }) => (
  <>
    <StyledArrowBackIcon
      color="secondary"
      onClick={handleNavigateBack}
      data-gtm-click="templates-header-handleNavigateBackButton"
    >
      <ArrowBackIosIcon />
    </StyledArrowBackIcon>
    <StyledTitle>{headerTitle}</StyledTitle>
  </>
);

export { Presenter };
