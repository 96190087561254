import { FetchResult } from '@apollo/client';
import { internalApiClient } from '/@/api/graphql/appApolloClient';
import { getAnnouncementsConnectionGql } from './index.gql';
import {
  GetAnnouncementsConnectionQuery,
  GetAnnouncementsConnectionQueryVariables,
} from '/@/api/graphql/internalApi/types';

type GetAnnouncementsConnectionResponse =
  FetchResult<GetAnnouncementsConnectionQuery>;

const getAnnouncementsConnection = (
  variables?: GetAnnouncementsConnectionQueryVariables,
): Promise<GetAnnouncementsConnectionResponse> => {
  return internalApiClient.query({
    query: getAnnouncementsConnectionGql,
    variables,
  });
};

export { getAnnouncementsConnection };

export type { GetAnnouncementsConnectionResponse };
