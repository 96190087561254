import { Reducer } from 'redux';
import type { RootAction } from '/@/store/actions';
import { GetMyGiftQuery } from '/@/api/graphql/internalApi/types';
import { getMyGiftTypes } from '/@/store/api/queries/getMyGift';
import { refreshMyGiftTypes } from '/@/store/api/mutations/refreshMyGift';

type MyGiftState = MyGift | null;

type MyGift = GetMyGiftQuery['myGift'];

const initialState: MyGiftState = null;

const myGift: Reducer<MyGiftState, RootAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getMyGiftTypes.GET_MY_GIFT_SUCCESS:
      return action.myGift;
    case refreshMyGiftTypes.REFRESH_MY_GIFT_SUCCESS:
      return action.myGift;
    default:
      return state;
  }
};

export { myGift };
export type { MyGiftState };
