import { getItemDetail } from '/@/api/graphql/internalApi/queries/getItemDetail';
import type { GetItemDetailResponse } from '/@/api/graphql/internalApi/queries/getItemDetail';
import type { AppThunk } from '/@/store';
import {
  getItemDetailStart,
  getItemDetailSuccess,
  getItemDetailFailure,
} from './actions';
import type { GetItemDetailQueryVariables } from '/@/api/graphql/internalApi/types';
import { handleErrorThunk } from '../../handleError';
import type { ApiError } from '/@/api/graphql/client';

const getItemDetailThunk =
  (variables: GetItemDetailQueryVariables): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getItemDetailStart());

    return getItemDetail(variables)
      .then((response) => dispatch(getItemDetailSuccessThunk(response)))
      .catch((error) => dispatch(getItemDetailFailureThunk(error)));
  };

const getItemDetailSuccessThunk =
  (response: GetItemDetailResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { item } = response.data!;
    dispatch(getItemDetailSuccess(item));
    return Promise.resolve();
  };

const getItemDetailFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getItemDetailFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { getItemDetailThunk };
