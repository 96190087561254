import React from 'react';
import { Presenter } from './Presenter';
import { LABEL_SIZE, LabelSizeTypes } from '../baseLabel/Presenter';

type Props = {
  labelSize?: LabelSizeTypes;
};

const Container: React.FC<Props> = ({ labelSize = LABEL_SIZE.MEDIUM }) => {
  return <Presenter labelSize={labelSize} />;
};

export { Container as LimitedLabel };
