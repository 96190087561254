import { Reducer } from 'redux';
import type { GetAnnouncementsConnectionQuery } from '/@/api/graphql/internalApi/types';
import type { RootAction } from '/@/store/actions';
import { combineReducers } from 'redux';
import { getAnnouncementsConnectionTypes } from '/@/store/api/queries/getAnnouncementsConnection';
import { STATUS, Status } from '/@/store/api/constants';
import { types } from './types';

type AnnouncementsState =
  GetAnnouncementsConnectionQuery['userAnnouncements']['nodes'];
type PageInfoState =
  | GetAnnouncementsConnectionQuery['userAnnouncements']['pageInfo']
  | null;
type AnnouncementsConnectionState = {
  announcements: AnnouncementsState;
  status: Status;
  pageInfo: PageInfoState;
};

const initialState: AnnouncementsState = null;
const pageInfoInitialState: PageInfoState = null;

const announcements: Reducer<AnnouncementsState, RootAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getAnnouncementsConnectionTypes.GET_ANNOUNCEMENTS_CONNECTION_SUCCESS: {
      if (!action.announcementsConnection.nodes) {
        return state;
      }

      if (state === null) {
        return action.announcementsConnection.nodes;
      } else {
        const uniqueAnnouncements = Array.from(
          new Map(
            [...state, ...action.announcementsConnection.nodes].map(
              (announcement) => [announcement?.uid, announcement],
            ),
          ).values(),
        );
        return uniqueAnnouncements;
      }
    }
    case types.INITIALISE_ANNOUNCEMENTS_CONNECTION:
      return initialState;
    default:
      return state;
  }
};

const status: Reducer<Status, RootAction> = (state = STATUS.IDLE, action) => {
  switch (action.type) {
    case getAnnouncementsConnectionTypes.GET_ANNOUNCEMENTS_CONNECTION_START:
      return STATUS.LOADING;
    case getAnnouncementsConnectionTypes.GET_ANNOUNCEMENTS_CONNECTION_SUCCESS:
      return STATUS.SUCCESS;
    case getAnnouncementsConnectionTypes.GET_ANNOUNCEMENTS_CONNECTION_FAILURE:
      return STATUS.FAILURE;
    default:
      return state;
  }
};

const pageInfo: Reducer<PageInfoState, RootAction> = (
  state = pageInfoInitialState,
  action,
) => {
  switch (action.type) {
    case getAnnouncementsConnectionTypes.GET_ANNOUNCEMENTS_CONNECTION_SUCCESS: {
      return action.announcementsConnection?.pageInfo ?? pageInfoInitialState;
    }
    case types.INITIALISE_ANNOUNCEMENTS_CONNECTION:
      return pageInfoInitialState;
    default:
      return state;
  }
};

const announcementsConnection = combineReducers({
  announcements,
  status,
  pageInfo,
});

export { announcementsConnection };
export type { AnnouncementsConnectionState, AnnouncementsState };
