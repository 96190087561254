import { gql } from '@apollo/client';

export const getAnnouncementsConnectionGql = gql`
  query GetAnnouncementsConnection(
    $after: String
    $first: Int
    $categoryUidIn: [UserAnnouncementTypeCategoryUidEnumType!]
  ) {
    userAnnouncements(
      after: $after
      first: $first
      categoryUidIn: $categoryUidIn
    ) {
      nodes {
        uid
        title
        createdAt
        readAt
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;
