import { Reducer } from 'redux';
import { getUserAuthenticatorsTypes } from '/@/store/api/queries/getUserAuthenticators';
import type { RootAction } from '/@/store/actions';

type UserAuthenticator = {
  label: string;
  uid: string;
};
type UserAuthenticatorsState = UserAuthenticator[] | null;

const userAuthenticators: Reducer<UserAuthenticatorsState, RootAction> = (
  state = null,
  action,
) => {
  switch (action.type) {
    case getUserAuthenticatorsTypes.GET_USER_AUTHENTICATORS_SUCCESS:
      return action.userAuthenticators;
    default:
      return state;
  }
};

export { userAuthenticators };
export type { UserAuthenticator, UserAuthenticatorsState };
