import { gql } from '@apollo/client';

export const getAnnouncementGql = gql`
  query GetAnnouncement($uid: String!) {
    userAnnouncement(uid: $uid) {
      uid
      title
      body
      createdAt
      readAt
      content {
        imageFileUrl
        linkUrl
      }
    }
  }
`;
