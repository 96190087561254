import { types } from './types';
import type { GetPublicAssetsQuery } from '/@/api/graphql/publicApi/types';
import { ApiError } from '/@/api/graphql/client';

export const getPublicAssetsStart = () =>
  ({
    type: types.GET_PUBLIC_ASSETS_START,
  }) as const;

export const getPublicAssetsSuccess = (
  publicAssets: GetPublicAssetsQuery['publicAssets'],
) =>
  ({
    type: types.GET_PUBLIC_ASSETS_SUCCESS,
    publicAssets,
  }) as const;

export const getPublicAssetsFailure = (error: ApiError) =>
  ({
    type: types.GET_PUBLIC_ASSETS_FAILURE,
    error,
  }) as const;
