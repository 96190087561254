import { types } from './types';
import type { GetMyGiftsConnectionQuery } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';
import { MyGiftsPatternTypes } from './operations';

export const getMyGiftsConnectionStart = (
  myGiftsPattern: MyGiftsPatternTypes,
) =>
  ({
    type: types.GET_MY_GIFTS_CONNECTION_START,
    pattern: myGiftsPattern,
  }) as const;

export const getMyGiftsConnectionSuccess = (
  myGiftsConnection: GetMyGiftsConnectionQuery['myGiftsConnection'],
  myGiftsPattern: MyGiftsPatternTypes,
) =>
  ({
    type: types.GET_MY_GIFTS_CONNECTION_SUCCESS,
    pattern: myGiftsPattern,
    myGiftsConnection,
  }) as const;

export const getMyGiftsConnectionFailure = (
  error: ApiError,
  myGiftsPattern: MyGiftsPatternTypes,
) =>
  ({
    type: types.GET_MY_GIFTS_CONNECTION_FAILURE,
    pattern: myGiftsPattern,
    error,
  }) as const;
