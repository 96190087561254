import { FetchResult } from '@apollo/client';
import { internalApiClient } from '/@/api/graphql/appApolloClient';
import { getGiftConvertToPointRequestGql } from './index.gql';
import {
  GetGiftConvertToPointRequestQuery,
  QueryGiftConvertToPointRequestArgs,
} from '/@/api/graphql/internalApi/types';

type GetGiftConvertToPointRequestResponse =
  FetchResult<GetGiftConvertToPointRequestQuery>;

const getGiftConvertToPointRequest = (
  variables: QueryGiftConvertToPointRequestArgs,
): Promise<GetGiftConvertToPointRequestResponse> => {
  return internalApiClient.query({
    query: getGiftConvertToPointRequestGql,
    variables,
  });
};

export { getGiftConvertToPointRequest };

export type { GetGiftConvertToPointRequestResponse };
