import { FetchResult } from '@apollo/client';
import { internalApiClient } from '/@/api/graphql/appApolloClient';
import { createPointExchangeGql } from './index.gql';
import {
  CreatePointExchangeInput,
  CreatePointExchangeMutation,
} from '/@/api/graphql/internalApi/types';

type CreatePointExchangeResponse = FetchResult<CreatePointExchangeMutation>;

const createPointExchange = (
  input: CreatePointExchangeInput,
): Promise<CreatePointExchangeResponse> => {
  return internalApiClient.mutate({
    mutation: createPointExchangeGql,
    variables: input,
  });
};

export { createPointExchange };

export type { CreatePointExchangeResponse };
