import React from 'react';
import { Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/system';
import { Header } from '/@/components/templates/header/Index';
import { HEADER_HEIGHT } from '/@/components/templates/header/globalHeader/Presenter';
import { Footer } from '/@/components/templates/footer/Index';
import { AlertMessage } from '/@/components/shared/alertMessage/Index';

const StyledLayoutContainer = styled(Grid)({
  minHeight: '100vh',
});
const StyledChildrenContainer = styled('div')({
  flex: 1,
  maxWidth: '100%',
});
const StyledHeaderContainer = styled('div')({
  marginTop: `${HEADER_HEIGHT}px`,
});

type Props = {
  children: React.ReactNode;
  headerTitle?: string;
};

const Presenter: React.VFC<Props> = ({ children, headerTitle }) => (
  <StyledLayoutContainer container direction="column" data-cy="mainLayout">
    <StyledHeaderContainer>
      <Header
        isMenuVisible={true}
        isLogoClickable={true}
        headerTitle={headerTitle}
      />
    </StyledHeaderContainer>
    <StyledChildrenContainer>
      <AlertMessage />
      {children}
    </StyledChildrenContainer>
    <Footer />
  </StyledLayoutContainer>
);

export { Presenter };
