import React from 'react';
import { Card, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { ItemBase } from '/@/store/app/shared/types';
import { Points } from '../../points/Index';
import '/@/assets/font/font.css';
import { DiscountPercentageLabel } from '/@/components/shared/label/discountPercentageLabel';
import { LimitedLabel } from '/@/components/shared/label/limitedLabel/Index';
import { LABEL_SIZE } from '/@/components/shared/label/baseLabel/Presenter';

type StyledCardProps = {
  isExchangeable: boolean;
};
const StyledCard = styled(Card)<StyledCardProps>(
  ({ isExchangeable, theme }) => ({
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.1)',
    width: '100%',
    ...(!isExchangeable && {
      backgroundColor: theme.palette.grey[300],
      opacity: 0.5,
    }),
  }),
);
const StyledLogoAndTitle = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  height: '100%',
  width: '100%',
});
const StyledLogoContainer = styled('div')(({ theme }) => ({
  width: theme.spacing(8),
  height: theme.spacing(8),
  marginTop: 'auto',
  marginBottom: 'auto',
  borderRadius: theme.spacing(1),
  border: '1px solid ' + theme.palette.grey.A200,
}));
const StyledLogo = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});
const StyledRightContent = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(1.5),
  width: '-webkit-fill-available',
}));
const StyledBrandName = styled('p')(({ theme }) => ({
  margin: 0,
  fontSize: '0.625rem',
  lineHeight: '0.625rem',
  color: theme.palette.grey.A700,
}));
const StyledItemName = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  fontSize: '0.875rem',
  lineHeight: '1.4rem',
  fontWeight: 'bold',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  textOverflow: 'ellipsis',
}));
const StyledEndAt = styled('p')(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: 0,
  fontSize: '0.625rem',
  lineHeight: '0.625rem',
  color: theme.palette.grey.A700,
}));
const StyledLabels = styled('div')({
  '& > * + *': {
    marginLeft: '0.25rem',
  },
});
const StyledPointDisplayArea = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
}));
const StyledPointText = styled(Typography)(({ theme }) => ({
  fontSize: '0.625rem',
  lineHeight: '1rem',
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
}));
const StyledPoints = styled('span')({
  fontFamily: 'mamelon',
  fontSize: '1.25rem',
  fontWeight: 400,
});

type Props = {
  exchangeable: boolean;
  itemContent: ItemBase;
  selectItemDetail: (uid: string) => void;
};

const Presenter: React.VFC<Props> = ({
  exchangeable,
  itemContent,
  selectItemDetail,
}) => (
  <StyledCard
    isExchangeable={exchangeable}
    variant="outlined"
    onClick={() => exchangeable && selectItemDetail(itemContent.uid)}
    data-cy="itemCard"
    data-gtm-click="shared-pointItemContents-itemCard-card"
  >
    <StyledLogoAndTitle>
      <StyledLogoContainer>
        <StyledLogo
          src={itemContent.contentImageUrl}
          alt={itemContent.contentName}
        />
      </StyledLogoContainer>
      <StyledRightContent>
        <StyledBrandName>{itemContent.brand.name}</StyledBrandName>
        <StyledItemName>{itemContent.contentName}</StyledItemName>
        {itemContent.currentItemPointPhase && (
          <>
            <StyledEndAt>
              {'有効期限 ' + itemContent.readableExpiration}
            </StyledEndAt>
            <StyledLabels>
              {itemContent.currentPointBackCampaign?.limited && (
                <LimitedLabel labelSize={LABEL_SIZE.SMALL} />
              )}
              {itemContent.currentPointBackCampaign && (
                <DiscountPercentageLabel
                  discountPercentage={
                    itemContent.currentPointBackCampaign.discountPercentage
                  }
                  labelSize={LABEL_SIZE.SMALL}
                />
              )}
            </StyledLabels>
            <StyledPointDisplayArea>
              {itemContent.currentItemPointPhase.variable ? (
                <StyledPointText>
                  <StyledPoints>
                    {itemContent.currentItemPointPhase.variable.minPoint.toLocaleString(
                      'ja-JP',
                    )}
                    {' ~ '}
                    {itemContent.currentItemPointPhase.variable.maxPoint.toLocaleString(
                      'ja-JP',
                    )}
                  </StyledPoints>
                  ポイント
                </StyledPointText>
              ) : (
                <Points points={itemContent.currentItemPointPhase.point} />
              )}
            </StyledPointDisplayArea>
          </>
        )}
      </StyledRightContent>
    </StyledLogoAndTitle>
  </StyledCard>
);

export { Presenter };
export type { Props as PresenterProps };
