import { gql } from '@apollo/client';

export const getPointFeatureGroupsGql = gql`
  query GetPointFeatureGroups($first: Int, $after: String) {
    pointFeatureGroups(first: $first, after: $after) {
      nodes {
        pointFeatures {
          destination {
            kind
            uid
          }
          imageUrl
          title
          uid
        }
        layout
        label
        uid
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
