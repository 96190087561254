import React from 'react';
import { styled } from '@mui/system';
import { SignInPageState } from '/@/store/page/signInPage';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { GifteeContent } from '/@/components/shared/gifteeContent/Index';

const StyledSavingGift = styled('div')({
  borderRadius: 16,
  boxShadow: '0 0 8px rgba(0, 0, 0, 0.15)',
});

type Props = {
  getSavingGifteeContentStatus: Status;
  savingGifteeContent: SignInPageState['savingGifteeContent'];
};

const Presenter: React.VFC<Props> = ({
  getSavingGifteeContentStatus,
  savingGifteeContent,
}) => (
  <div data-cy="signInPageSavingGift">
    {getSavingGifteeContentStatus === STATUS.LOADING || !savingGifteeContent ? (
      <PartialLoader />
    ) : (
      <StyledSavingGift>
        <GifteeContent gifteeContent={savingGifteeContent} />
      </StyledSavingGift>
    )}
  </div>
);

export { Presenter };
