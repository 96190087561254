import { FetchResult } from '@apollo/client';
import { internalApiClient } from '/@/api/graphql/appApolloClient';
import { saveGifteeContentGql } from './index.gql';
import {
  SaveGifteeContentMutation,
  SaveGifteeContentMutationVariables,
} from '/@/api/graphql/internalApi/types';

type SaveGifteeContentResponse = FetchResult<SaveGifteeContentMutation>;

const saveGifteeContent = (
  input: SaveGifteeContentMutationVariables,
): Promise<SaveGifteeContentResponse> => {
  return internalApiClient.mutate({
    mutation: saveGifteeContentGql,
    variables: {
      url: input['url'],
      origin: input['origin'],
      referer: input['referer'],
      notifyTarget: input['notifyTarget'],
    },
  });
};

export { saveGifteeContent };

export type { SaveGifteeContentResponse };
