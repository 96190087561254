import { Reducer } from 'redux';
import type { GetGiftConvertToPointRequestQuery } from '/@/api/graphql/internalApi/types';
import type { RootAction } from '/@/store/actions';
import { getGiftConvertToPointRequestTypes } from '/@/store/api/queries/getGiftConvertToPointRequest';

type GiftConvertToPointRequestState =
  | GetGiftConvertToPointRequestQuery['giftConvertToPointRequest']
  | null;

const initialState: GiftConvertToPointRequestState = null;

const giftConvertToPointRequest: Reducer<
  GiftConvertToPointRequestState,
  RootAction
> = (state = initialState, action) => {
  switch (action.type) {
    case getGiftConvertToPointRequestTypes.GET_GIFT_CONVERT_TO_POINT_REQUEST_SUCCESS: {
      return action.giftConvertToPointRequest;
    }
    default:
      return state;
  }
};

export { giftConvertToPointRequest };
export type { GiftConvertToPointRequestState };
