import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GlobalHeader } from './globalHeader/Index';
import { ConditionOpenedType, SearchHeader } from './searchHeader';

const Container: React.VFC<{
  isMenuVisible: boolean;
  isLogoClickable: boolean;
  headerTitle?: string;
}> = ({ isMenuVisible, isLogoClickable, headerTitle }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const searchMenuPath = ['/point/brand', '/point/point_logs'];

  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const handleToggleMenu = useCallback(() => {
    setIsMenuOpened((prevState) => !prevState);
  }, []);

  const isSearchMenuVisible = searchMenuPath.some((path) =>
    currentPath.startsWith(path),
  );

  const [isConditionOpened, setIsConditionOpened] =
    useState<ConditionOpenedType>(null);

  const handleOpenCondition = (type: ConditionOpenedType) => {
    setIsConditionOpened(type);
  };

  return isSearchMenuVisible ? (
    <SearchHeader
      headerTitle={headerTitle ?? ''}
      isMenuOpened={isMenuOpened}
      handleToggleMenu={handleToggleMenu}
      isConditionOpened={isConditionOpened}
      handleOpenCondition={handleOpenCondition}
    />
  ) : (
    <GlobalHeader
      headerTitle={headerTitle}
      isMenuOpened={isMenuOpened}
      handleToggleMenu={handleToggleMenu}
      isLogoClickable={isLogoClickable}
      isMenuVisible={isMenuVisible}
    />
  );
};

export { Container as Header };
