import React from 'react';
import { Typography, Grid2 as Grid, Card } from '@mui/material';
import { styled } from '@mui/system';
import {
  MyGiftUsageStatus,
  MY_GIFT_USAGE_STATUS,
} from '/@/utils/calcMyGiftUsageStatus';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const MY_GIFT_IMAGE_SIZE = 64;
const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  boxShadow: 'none',
}));
const StyledCardContent = styled(Grid)({
  minWidth: 0,
});
const StyledPointChargeableText = styled(Typography)(({ theme }) => ({
  minWidth: '8.125rem',
  fontSize: '0.625rem',
  lineHeight: '0.625rem',
  fontWeight: 'bold',
  color: theme.palette.success.main,
  marginBottom: theme.spacing(1),
}));
const StyledMyGiftImage = styled('img')(({ theme }) => ({
  marginRight: theme.spacing(1.5),
  height: MY_GIFT_IMAGE_SIZE,
  width: MY_GIFT_IMAGE_SIZE,
  borderRadius: theme.spacing(),
  border: `1px solid ${theme.palette.grey[300]}`,
  objectFit: 'contain',
}));
const StyledMyGiftName = styled(Typography)({
  maxWidth: '100%',
  fontWeight: 'bold',
  overflow: 'hidden',
  fontSize: '0.875rem',
  lineHeight: '1.3',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-line-clamp': 2,
  '-webkit-box-orient': 'vertical',
});
type StyledMyGiftExpiredAtProps = {
  isExpiring: boolean;
};
const StyledMyGiftExpiredAt = styled(Typography)<StyledMyGiftExpiredAtProps>(
  ({ isExpiring, theme }) => ({
    ...(isExpiring
      ? {
          maxWidth: '100%',
          fontWeight: 'bold',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          color: theme.palette.warning.main,
        }
      : {
          marginTop: theme.spacing(0.25),
          maxWidth: '100%',
          fontWeight: 'bold',
          fontSize: '0.625rem',
          lineHeight: '1',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          color: theme.palette.secondary.main,
        }),
  }),
);
const StyledExpiredCheckCircleIcon = styled(CheckCircleIcon)(({ theme }) => ({
  '&.MuiSvgIcon-root': {
    marginRight: theme.spacing(0.5),
    width: '0.875rem',
    color: theme.palette.warning.main,
  },
}));
const StyledDiscardedCheckCircleIcon = styled(CheckCircleIcon)(({ theme }) => ({
  '&.MuiSvgIcon-root': {
    marginRight: theme.spacing(0.5),
    width: '0.875rem',
    color: theme.palette.primary.main,
  },
}));

type Props = {
  name: string;
  imageUrl: string;
  expirationText: string;
  usageStatus: MyGiftUsageStatus;
  pointMergeable: boolean;
  handleClick: () => void;
};

const Presenter: React.VFC<Props> = ({
  name,
  imageUrl,
  expirationText,
  usageStatus,
  pointMergeable,
  handleClick,
}) => (
  <StyledCard
    onClick={handleClick}
    data-cy="myGiftSkelton"
    data-gtm-click="shared-myGift-myGiftSkelton-card"
  >
    <Grid container wrap="nowrap" direction="row">
      <StyledMyGiftImage src={imageUrl} alt={name} />
      <StyledCardContent container justifyContent="center" direction="column">
        {pointMergeable && (
          <StyledPointChargeableText>
            ポイントに移行可能なギフト
          </StyledPointChargeableText>
        )}
        <StyledMyGiftName variant="subtitle1">{name}</StyledMyGiftName>
        <Grid container alignItems="center">
          {usageStatus === MY_GIFT_USAGE_STATUS.DISCARDED ? (
            <StyledDiscardedCheckCircleIcon data-cy="discardedCheckCircleIcon" />
          ) : usageStatus === MY_GIFT_USAGE_STATUS.EXPIRED ? (
            <StyledExpiredCheckCircleIcon data-cy="expiredCheckCircleIcon" />
          ) : null}
          <StyledMyGiftExpiredAt
            variant="caption"
            isExpiring={usageStatus === MY_GIFT_USAGE_STATUS.EXPIRING}
            data-cy="expirationText"
          >
            {expirationText}
          </StyledMyGiftExpiredAt>
        </Grid>
      </StyledCardContent>
    </Grid>
  </StyledCard>
);

export { Presenter };
