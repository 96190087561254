import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import {
  LayoutEnum,
  PointFeatureGroup,
} from '/@/api/graphql/internalApi/types';
import { FullImagePointFeatures } from './FullImagePointFeatures/Index';

const StyledPointFeatureGroupContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
const StyledTitle = styled(Typography)({
  fontSize: '0.75rem',
  lineHeight: '0.75rem',
  fontWeight: 'bold',
});
const StyledPointFeaturesContainer = styled('div')({});

type Props = {
  pointFeatureGroup: PointFeatureGroup;
};

const Presenter: React.VFC<Props> = ({ pointFeatureGroup }) => (
  <StyledPointFeatureGroupContainer>
    <StyledTitle>{pointFeatureGroup.label}</StyledTitle>
    <StyledPointFeaturesContainer>
      {pointFeatureGroup.layout === LayoutEnum.FullImage && (
        <FullImagePointFeatures
          pointFeatures={pointFeatureGroup.pointFeatures}
        />
      )}
    </StyledPointFeaturesContainer>
  </StyledPointFeatureGroupContainer>
);

export { Presenter };
