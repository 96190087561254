import React from 'react';
import {
  Card,
  Grid2 as Grid,
  Typography,
  Button,
  ButtonProps,
  Container,
} from '@mui/material';
import { styled } from '@mui/system';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { PATHS } from '/@/routes/paths';
import { Link } from 'react-router-dom';
import { HeadingWithTitle } from '/@/components/shared/headingWithTitle/Index';

const StyledBackground = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.contrast,
}));
const StyledQAContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
}));
const StyledQACard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  border: 'none',
  borderRadius: theme.spacing(2),
  minWidth: '100%',
  '&:last-child': {
    marginBottom: 0,
  },
}));
const StyledQuestionCardSection = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.highContrast,
  padding: theme.spacing(2),
  color: theme.palette.text.disabled,
}));
const StyledQuestionText = styled(Typography)(() => ({
  fontWeight: 'bold',
  color: 'white',
}));
const StyledAnswerCardSection = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
}));
const StyledAnswerMark = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.light,
  paddingRight: theme.spacing(0.5),
}));
const StyledHomeLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  margin: theme.spacing(2),
}));
const StyledHomeLinkText = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
}));
const StyledInquiryContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
}));
type StyledButtonProps = ButtonProps & { to: string };
const StyledInquiryButton = styled(Button)<StyledButtonProps>(({ theme }) => ({
  marginTop: theme.spacing(3),
  paddingTop: theme.spacing(1.25),
  paddingBottom: theme.spacing(1.25),
  paddingRight: theme.spacing(6.75),
  paddingLeft: theme.spacing(6.75),
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.common.white,
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: theme.spacing(1),
}));
const StyledInquiriesNewPageLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const Presenter: React.VFC<Record<string, never>> = () => (
  <MainLayout>
    <StyledBackground>
      <StyledQAContainer maxWidth="sm">
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          data-cy="frequentlyAskedQuestions"
        >
          <HeadingWithTitle title="Q&A" subTitle="よくあるご質問" />
          <StyledQACard variant="outlined">
            <StyledQuestionCardSection container wrap="nowrap">
              <StyledQuestionText variant="body1">
                Q. 保存したギフトに戻れません
              </StyledQuestionText>
            </StyledQuestionCardSection>
            <StyledAnswerCardSection container alignItems="baseline">
              <Grid container wrap="nowrap">
                <StyledAnswerMark variant="body1">A.</StyledAnswerMark>
                <Typography variant="body2">
                  マイギフトページで、保存されているギフトを確認してみてください。
                </Typography>
              </Grid>
              <Grid container width="100%" justifyContent="flex-end">
                <StyledHomeLink to={PATHS.HOME}>
                  <StyledHomeLinkText variant="body2">
                    マイギフトを確認する →
                  </StyledHomeLinkText>
                </StyledHomeLink>
              </Grid>
            </StyledAnswerCardSection>
          </StyledQACard>
          <StyledQACard variant="outlined">
            <StyledQuestionCardSection container wrap="nowrap">
              <StyledQuestionText variant="body1">
                Q. ギフトが保存できません
              </StyledQuestionText>
            </StyledQuestionCardSection>
            <StyledAnswerCardSection
              container
              wrap="nowrap"
              alignItems="baseline"
            >
              <StyledAnswerMark variant="body1">A.</StyledAnswerMark>
              <Typography variant="body2">
                申し訳ありません。時間をおいて再度お試しください。それでも解決しない場合は、
                <StyledInquiriesNewPageLink
                  to={PATHS.INQUIRIES_NEW}
                  data-cy="inquiriesNewPageLink"
                >
                  こちら
                </StyledInquiriesNewPageLink>
                からお問い合わせください。
              </Typography>
            </StyledAnswerCardSection>
          </StyledQACard>
          <StyledQACard variant="outlined">
            <StyledQuestionCardSection container wrap="nowrap">
              <StyledQuestionText variant="body1">
                Q. ギフトの使い方がわかりません
              </StyledQuestionText>
            </StyledQuestionCardSection>
            <StyledAnswerCardSection
              container
              wrap="nowrap"
              alignItems="baseline"
            >
              <StyledAnswerMark variant="body1">A.</StyledAnswerMark>
              <Typography variant="body2">
                ギフトによって使い方が異なります。各ギフトのチケットページ内に記載されている利用方法を確認してみてください。
              </Typography>
            </StyledAnswerCardSection>
          </StyledQACard>
        </Grid>
      </StyledQAContainer>
    </StyledBackground>
    <StyledInquiryContainer maxWidth="sm">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="body2" style={{ textAlign: 'center' }}>
          問題が解決しない場合やその他のご質問は
          <br />
          こちらからお問い合わせください。
        </Typography>
        <StyledInquiryButton
          data-cy="inquiriesNewPageButton"
          variant="contained"
          disableElevation
          component={Link}
          to={PATHS.INQUIRIES_NEW}
        >
          お問い合わせ
        </StyledInquiryButton>
      </Grid>
    </StyledInquiryContainer>
  </MainLayout>
);

export { Presenter };
