import { getPublicAssets } from '/@/api/graphql/publicApi/queries/getPublicAssets';
import type { GetPublicAssetsResponse } from '/@/api/graphql/publicApi/queries/getPublicAssets';
import type { AppThunk } from '/@/store';
import {
  getPublicAssetsStart,
  getPublicAssetsSuccess,
  getPublicAssetsFailure,
} from './actions';
import { ApiError } from '/@/api/graphql/client';
import { GetPublicAssetsQueryVariables } from '/@/api/graphql/publicApi/types';

const getPublicAssetsThunk =
  (variables: GetPublicAssetsQueryVariables): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getPublicAssetsStart());
    return getPublicAssets(variables)
      .then((response) => dispatch(getPublicAssetsSuccessThunk(response)))
      .catch((error) => dispatch(getPublicAssetsFailureThunk(error)));
  };

const getPublicAssetsSuccessThunk =
  (response: GetPublicAssetsResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getPublicAssetsSuccess(response.data!.publicAssets));
    return Promise.resolve();
  };

const getPublicAssetsFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getPublicAssetsFailure(error));
    // NOTE: 500 ページに飛ばさないので、errorHandler を利用しない
    return Promise.reject(error);
  };

export { getPublicAssetsThunk };
