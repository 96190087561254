import { FetchResult } from '@apollo/client';
import { publicApiClient } from '/@/api/graphql/appApolloClient';
import { getPublicAssetsGql } from './index.gql';
import {
  GetPublicAssetsQuery,
  GetPublicAssetsQueryVariables,
} from '/@/api/graphql/publicApi/types';

type GetPublicAssetsResponse = FetchResult<GetPublicAssetsQuery>;

const getPublicAssets = (
  variables: GetPublicAssetsQueryVariables,
): Promise<GetPublicAssetsResponse> =>
  publicApiClient.query({
    query: getPublicAssetsGql,
    variables,
  });

export { getPublicAssets };

export type { GetPublicAssetsResponse };
