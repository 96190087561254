import { FetchResult } from '@apollo/client';
import { internalApiClient } from '/@/api/graphql/appApolloClient';
import { getPointLogsGql } from './index.gql';
import {
  GetPointLogsQuery,
  GetPointLogsQueryVariables,
  PointLogType_StatusEnumType,
  PointWalletType_PointLogs_LogTypeEnumType,
} from '/@/api/graphql/internalApi/types';

type GetPointLogsResponse = FetchResult<GetPointLogsQuery>;

const isPointsCharged = (func: (result: boolean) => void) =>
  getPointLogs({
    statusIn: [PointLogType_StatusEnumType.Success],
    logTypeEq: PointWalletType_PointLogs_LogTypeEnumType.PointCharge,
    first: 1,
  })
    .then((res) =>
      res.data?.pointWallet?.pointLogs?.nodes?.length &&
      res.data.pointWallet.pointLogs.nodes.length > 0
        ? func(true)
        : func(false),
    )
    .catch(() => {});

const getPointLogs = (
  variables?: GetPointLogsQueryVariables,
): Promise<GetPointLogsResponse> => {
  return internalApiClient.query({
    query: getPointLogsGql,
    variables,
  });
};

export { getPointLogs, isPointsCharged };

export type { GetPointLogsResponse };
