import { Reducer } from 'redux';
import type { RootAction } from '/@/store/actions';
import { types as createPointExchangeTypes } from '/@/store/api/mutations/createPointExchange/types';
import { CreatePointExchangeMutation } from '/@/api/graphql/internalApi/types';

type ExchangedGiftsState = ExchangedGifts | null;

type ExchangedGifts =
  CreatePointExchangeMutation['createPointExchange']['pointExchange'];

const initialState: ExchangedGiftsState = null;

const exchangedGifts: Reducer<ExchangedGiftsState, RootAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case createPointExchangeTypes.CREATE_POINT_EXCHANGE_SUCCESS:
      return action.pointExchange ?? state;
    default:
      return state;
  }
};

export { exchangedGifts };
export type { ExchangedGiftsState };
