import { RootState } from '/@/store';
import { createSelector } from 'reselect';
import { formatToDateJa } from '/@/utils/formatDate';
import {
  GetMyGiftsConnectionQuery,
  MyGiftSortConditionTargetEnumType,
} from '/@/api/graphql/internalApi/types';
import { MyGiftBase } from '/@/store/app/shared/types';
import { UnavailableMyGiftsState } from '/@/store/app/unavailableMyGiftsConnection';

export type GroupedUnavailableMyGifts = {
  [key: string]: GetMyGiftsConnectionQuery['myGiftsConnection']['nodes'];
};

const formatGroupTarget = (
  target: MyGiftSortConditionTargetEnumType,
  myGift: MyGiftBase,
) => {
  switch (target) {
    case MyGiftSortConditionTargetEnumType.ChangedAt:
      return formatToDateJa(myGift.changedAt);
    case MyGiftSortConditionTargetEnumType.UnavailableAt:
      return myGift.unavailableAt
        ? formatToDateJa(myGift.unavailableAt)
        : '発行中';
    default:
      return formatToDateJa(myGift.changedAt);
  }
};

const selectUnavailableMyGifts = (state: RootState) =>
  state.app.unavailableMyGiftsConnection.myGifts;

const groupUnavailableMyGifts = (
  unavailableMyGifts: UnavailableMyGiftsState,
  groupTarget: MyGiftSortConditionTargetEnumType,
): GroupedUnavailableMyGifts => {
  if (!unavailableMyGifts) {
    return {};
  }

  return unavailableMyGifts.reduce(
    (groupedUnavailableMyGifts, unavailableMyGift) => {
      const formattedGroupTarget = formatGroupTarget(
        groupTarget,
        unavailableMyGift as MyGiftBase,
      );

      const unavailableMyGiftsGroup =
        groupedUnavailableMyGifts[formattedGroupTarget];

      if (unavailableMyGiftsGroup) {
        return {
          ...groupedUnavailableMyGifts,
          [formattedGroupTarget]: [
            ...unavailableMyGiftsGroup,
            unavailableMyGift,
          ],
        };
      } else {
        return {
          ...groupedUnavailableMyGifts,
          [formattedGroupTarget]: [unavailableMyGift],
        };
      }
    },
    {} as GroupedUnavailableMyGifts,
  );
};

export const selectUnavailableAtGroupedUnavailableMyGifts = createSelector(
  [selectUnavailableMyGifts],
  (unavailableMyGifts): GroupedUnavailableMyGifts =>
    groupUnavailableMyGifts(
      unavailableMyGifts,
      MyGiftSortConditionTargetEnumType.UnavailableAt,
    ),
);

export const selectChangedAtGroupedUnavailableMyGifts = createSelector(
  [selectUnavailableMyGifts],
  (unavailableMyGifts): GroupedUnavailableMyGifts =>
    groupUnavailableMyGifts(
      unavailableMyGifts,
      MyGiftSortConditionTargetEnumType.ChangedAt,
    ),
);
