import React from 'react';
import { Divider } from '@mui/material';
import { GiftCardBase } from '/@/store/app/shared/types';
import { GiftCardTicket } from './giftCardTicket/Index';

export type GiftCard = GiftCardBase;
type Props = {
  giftCard: GiftCard;
};
const Presenter: React.VFC<Props> = ({ giftCard }) => (
  <div data-cy="giftCard">
    {giftCard.giftCardTickets.map((giftCardTicket) => (
      <React.Fragment key={giftCardTicket.ticket.url}>
        <GiftCardTicket giftCardTicket={giftCardTicket} />
        <Divider />
      </React.Fragment>
    ))}
  </div>
);

export { Presenter };
