import React from 'react';
import { ChoosableGiftCardTicketBase } from '/@/store/app/shared/types';
import { TicketSkelton } from '/@/components/shared/gifteeContent/ticketSkelton/Index';

export type ChoosableGiftCardTicket = ChoosableGiftCardTicketBase;

type Props = {
  choosableGiftCardTicket: ChoosableGiftCardTicket;
};
const Presenter: React.VFC<Props> = ({ choosableGiftCardTicket }) => (
  <div data-cy={`choosableGiftCardTicket-${choosableGiftCardTicket.url}`}>
    <TicketSkelton
      name={choosableGiftCardTicket.name}
      imageUrl={choosableGiftCardTicket.imageUrl}
      url={choosableGiftCardTicket.url}
      expiredAt={choosableGiftCardTicket.expiredAt}
      expiredAtLabel="選択"
    />
  </div>
);

export { Presenter };
