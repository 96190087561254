import { gql } from '@apollo/client';
import { FRAGMENT_USER } from '/@/api/graphql/internalApi/fragments/user.gql';
import { FRAGMENT_ERRORS } from '/@/api/graphql/internalApi/fragments/errors.gql';

export const readAnnouncementsGql = gql`
  ${FRAGMENT_USER}
  ${FRAGMENT_ERRORS}
  mutation ReadAnnouncements(
    $clientMutationId: String
    $categoryUid: UserAnnouncementTypeCategoryUidEnumType
  ) {
    readUserAnnouncements(
      input: { clientMutationId: $clientMutationId, categoryUid: $categoryUid }
    ) {
      user {
        ...UserFields
      }
      errors {
        ...ErrorsFields
      }
    }
  }
`;
