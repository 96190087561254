import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  ButtonProps,
  Box,
  Grid2 as Grid,
  Typography,
  Container,
} from '@mui/material';
import { styled } from '@mui/system';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { PATHS } from '/@/routes/paths';
import { HeadingWithTitle } from '/@/components/shared/headingWithTitle/Index';

const StyledMainContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
  minHeight: '100vh',
}));
const StyledBaseBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  backgroundColor: theme.palette.background.default,
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  borderRadius: theme.spacing(2),
}));
type StyledButtonProps = ButtonProps & { to: string };
const StyledServiceTopButton = styled(Button)<StyledButtonProps>(
  ({ theme }) => ({
    fontWeight: 'bold',
    padding: theme.spacing(),
    borderRadius: theme.spacing(2.8),
  }),
);
const StyledBackground = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.contrast,
}));

const Presenter: React.VFC<Record<string, never>> = () => (
  <MainLayout>
    <StyledBackground>
      <StyledMainContainer maxWidth="sm">
        <HeadingWithTitle title="Thank You" subTitle="送信しました" />

        <StyledBaseBox>
          <Grid container direction="column" alignItems="stretch" spacing={2}>
            <Grid size="grow">
              <Grid
                container
                direction="column"
                alignItems="center"
                spacing={2}
              >
                <Grid size="grow">
                  <Typography variant="body2" gutterBottom>
                    お問い合わせいただき、ありがとうございます。
                  </Typography>
                </Grid>
                <Grid size="grow">
                  <Typography variant="body2" gutterBottom>
                    土日または祝日に送られた場合、返信は翌営業日以降になりますのでご了承ください。
                  </Typography>
                </Grid>
                <Grid size="grow">
                  <Typography variant="body2" gutterBottom>
                    平日の場合は、順に返信しますので、
                    <br />
                    しばらくお待ちください。
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid size="grow">
              <StyledServiceTopButton
                disableElevation
                variant="contained"
                color="primary"
                fullWidth
                component={Link}
                to={PATHS.ROOT}
              >
                サービスTOPに戻る
              </StyledServiceTopButton>
            </Grid>
          </Grid>
        </StyledBaseBox>
      </StyledMainContainer>
    </StyledBackground>
  </MainLayout>
);

export { Presenter };
