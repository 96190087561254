import {
  getAnnouncement,
  GetAnnouncementResponse,
} from '/@/api/graphql/internalApi/queries/getAnnouncement';
import type { AppThunk } from '/@/store';
import {
  getAnnouncementStart,
  getAnnouncementSuccess,
  getAnnouncementFailure,
} from './actions';
import { handleErrorThunk } from '../../handleError';
import type { ApiError } from '/@/api/graphql/client';
import { GetAnnouncementQueryVariables } from '/@/api/graphql/internalApi/types';

const getAnnouncementThunk =
  (variables: GetAnnouncementQueryVariables): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getAnnouncementStart());

    return getAnnouncement(variables)
      .then((response) => dispatch(getAnnouncementSuccessThunk(response)))
      .catch((error) => dispatch(getAnnouncementFailureThunk(error)));
  };

const getAnnouncementSuccessThunk =
  (response: GetAnnouncementResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { userAnnouncement } = response.data!;
    dispatch(getAnnouncementSuccess(userAnnouncement));
    return Promise.resolve();
  };

const getAnnouncementFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getAnnouncementFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { getAnnouncementThunk };
