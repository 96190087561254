import React from 'react';
import { Presenter } from './Presenter';
import { LpTypes } from '../types';

type OwnProps = {
  children?: React.ReactNode;
  lpType: LpTypes;
};

const Container: React.FC<OwnProps> = ({ children, lpType }) => {
  return <Presenter lpType={lpType}>{children}</Presenter>;
};

export { Container as AboutSection };
