import { FetchResult } from '@apollo/client';
import { internalApiClient } from '/@/api/graphql/appApolloClient';
import { getCurrentUserGql } from './index.gql';
import { GetCurrentUserQuery } from '/@/api/graphql/internalApi/types';

type GetCurrentUserResponse = FetchResult<GetCurrentUserQuery>;

const getCurrentUser = (): Promise<GetCurrentUserResponse> => {
  return internalApiClient.query({
    query: getCurrentUserGql,
  });
};

export { getCurrentUser };

export type { GetCurrentUserResponse };
