import React from 'react';
import { Presenter } from './Presenter';

type Props = {
  labelText: string;
  labelSize?: 'small' | 'medium';
  color: string;
  backgroundColor: string;
};

const Container: React.FC<Props> = ({
  labelText,
  labelSize = 'medium',
  color,
  backgroundColor,
}) => {
  return (
    <Presenter
      labelText={labelText}
      labelSize={labelSize}
      color={color}
      backgroundColor={backgroundColor}
    />
  );
};

export { Container as BaseLabel };
