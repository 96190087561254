import { getOauthSession } from '/@/api/graphql/publicApi/queries/getOauthSession';
import type { GetOauthSessionResponse } from '/@/api/graphql/publicApi/queries/getOauthSession';
import type { AppThunk } from '/@/store';
import {
  getOauthSessionStart,
  getOauthSessionSuccess,
  getOauthSessionFailure,
} from './actions';
import { GetOauthSessionQueryVariables } from '/@/api/graphql/publicApi/types';
import { handleErrorThunk } from '../../handleError';
import type { ApiError } from '/@/api/graphql/client';

const getOauthSessionThunk =
  (variables: GetOauthSessionQueryVariables): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getOauthSessionStart());
    return getOauthSession(variables)
      .then((response) => dispatch(getOauthSessionSuccessThunk(response)))
      .catch((error) => dispatch(getOauthSessionFailureThunk(error)));
  };

const getOauthSessionSuccessThunk =
  (response: GetOauthSessionResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getOauthSessionSuccess(response.data!.oauthSession));
    return Promise.resolve();
  };

const getOauthSessionFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getOauthSessionFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { getOauthSessionThunk };
