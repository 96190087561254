import React from 'react';
import { Container, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { RecommendedContents } from '/@/components/shared/brandContents/recommendedContents/Index';

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 'bold',
  lineHeight: '1.6rem',
  textAlign: 'center',
  marginBottom: theme.spacing(2),
}));

const Presenter: React.VFC<Record<string, never>> = () => (
  <StyledContainer maxWidth="sm">
    <StyledTitle>今おトクに交換できるギフト</StyledTitle>
    <RecommendedContents />
  </StyledContainer>
);

export { Presenter };
