import React, { useMemo } from 'react';
import { useAppSelector } from '/@/store';
import { UserAnnouncementTypeCategoryUidEnumType } from '/@/api/graphql/internalApi/types';
import { AnnouncementBasePage } from '../announcementBasePage/Index';

const Container: React.VFC<Record<string, never>> = () => {
  const headerTitle = 'ニュース';
  const noAnnouncementText =
    'サービスに関するお知らせなどが\nこちらに通知されます';
  const unreadAnnouncementsCountForAll = useAppSelector(
    (state) =>
      state.app.currentUser?.unreadAnnouncementsCountByCategoryUid.forAll,
  );
  const isDisabledReadButton = useMemo(() => {
    return (
      unreadAnnouncementsCountForAll === undefined ||
      unreadAnnouncementsCountForAll === 0
    );
  }, [unreadAnnouncementsCountForAll]);

  return (
    <AnnouncementBasePage
      headerTitle={headerTitle}
      isDisabledReadButton={isDisabledReadButton}
      noAnnouncementText={noAnnouncementText}
      userAnnouncementTypeCategory={
        UserAnnouncementTypeCategoryUidEnumType.ForAll
      }
    />
  );
};

export { Container as AnnouncementsForAllPage };
