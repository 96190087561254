import { FetchResult } from '@apollo/client';
import { publicApiClient } from '/@/api/graphql/appApolloClient';
import { getUserAUthenticatorsGql } from './index.gql';
import { GetUserAuthenticatorsQuery } from '/@/api/graphql/publicApi/types';

type GetUserAuthenticatorsResponse = FetchResult<GetUserAuthenticatorsQuery>;

const getUserAuthenticators = (): Promise<GetUserAuthenticatorsResponse> =>
  publicApiClient.query({
    query: getUserAUthenticatorsGql,
    variables: {},
  });

export { getUserAuthenticators };

export type { GetUserAuthenticatorsResponse };
