import React from 'react';
import { Grid2 as Grid, Button } from '@mui/material';
import { styled } from '@mui/system';
import { Modal } from '/@/components/shared/modal/Index';
import { BenefitOfPointCharge } from '../illustration/Index';

const StyledHandleCloseModalButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.75),
  fontSize: '0.875rem',
  fontWeight: 'bold',
  lineHeight: '1',
  borderRadius: theme.spacing(2.75),
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  border: `2px solid ${theme.palette.primary.main}`,
  boxShadow: 'none',
}));

type Props = {
  handleClose: () => void;
};
const Presenter: React.VFC<Props> = ({ handleClose }) => (
  <Modal handleClose={handleClose}>
    <Grid
      container
      direction="column"
      justifyContent="center"
      data-cy="benefitOfPointChargeModal"
    >
      <BenefitOfPointCharge />
      <StyledHandleCloseModalButton
        variant="contained"
        onClick={handleClose}
        data-cy="benefitOfPointChargeModalCloseButton"
        data-gtm-click="shared-benefitOfPointCharge-modal-closeButton"
      >
        閉じる
      </StyledHandleCloseModalButton>
    </Grid>
  </Modal>
);

export { Presenter };
