import { types } from './types';
import { ApiError } from '/@/api/graphql/client';

export const readAnnouncementStart = () =>
  ({
    type: types.READ_ANNOUNCEMENT_START,
  }) as const;

export const readAnnouncementSuccess = () =>
  ({
    type: types.READ_ANNOUNCEMENT_SUCCESS,
  }) as const;

export const readAnnouncementFailure = (error: ApiError) =>
  ({
    type: types.READ_ANNOUNCEMENT_FAILURE,
    error,
  }) as const;
