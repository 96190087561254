import { types } from './types';
import type { GetPointWalletQuery } from '/@/api/graphql/internalApi/types';
import { ApiError } from '/@/api/graphql/client';

export const pointUpdatedSubscriptionStart = () =>
  ({
    type: types.POINT_UPDATED_SUBSCRIPTION_START,
  }) as const;

export const pointUpdatedSubscriptionSuccess = (
  pointWallet: NonNullable<GetPointWalletQuery['pointWallet']>,
) =>
  ({
    type: types.POINT_UPDATED_SUBSCRIPTION_SUCCESS,
    pointWallet,
  }) as const;

export const pointUpdatedSubscriptionFailure = (error: ApiError) =>
  ({
    type: types.POINT_UPDATED_SUBSCRIPTION_FAILURE,
    error,
  }) as const;
