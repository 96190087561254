import { FetchResult } from '@apollo/client';
import { publicApiClient } from '/@/api/graphql/appApolloClient';
import { createOauthSessionGql } from './index.gql';
import {
  CreateOauthSessionInput,
  CreateOauthSessionMutation,
} from '/@/api/graphql/publicApi/types';

type CreateOauthSessionResponse = FetchResult<CreateOauthSessionMutation>;

const createOauthSession = (
  createOauthSessionInput: CreateOauthSessionInput,
): Promise<CreateOauthSessionResponse> => {
  return publicApiClient.mutate({
    mutation: createOauthSessionGql,
    variables: { createOauthSessionInput },
  });
};

export { createOauthSession };
export type { CreateOauthSessionResponse };
