import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import internalServerErrorIcon from '/@/assets/page/internalServerErrorPage/internal-server-error-icon.svg';

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  fontWeight: 'bold',
}));
const StyledContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const Presenter: React.VFC<Record<string, never>> = () => (
  <MainLayout>
    <StyledContainer
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      data-cy="internalServerErrorPage"
    >
      <img
        src={internalServerErrorIcon}
        alt="500 intenal server error"
        width="100%"
        height="250"
        data-cy="internalServerErrorIcon"
      />
      <StyledSubtitle variant="body2">
        システムエラーが発生しました。
      </StyledSubtitle>
      <Typography variant="caption">
        復旧作業中のため、アクセスができなくなっています。
      </Typography>
      <Typography variant="caption">
        時間をおいて、もう一度試してみてください。
      </Typography>
    </StyledContainer>
  </MainLayout>
);

export { Presenter };
