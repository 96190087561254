import React from 'react';
import { Presenter } from './Presenter';
import { LABEL_SIZE, LabelSizeTypes } from '../baseLabel/Presenter';
import { theme } from '/@/utils/theme';

type Props = {
  discountPercentage: number;
  isEmphased?: boolean;
  labelSize?: LabelSizeTypes;
};

const Container: React.FC<Props> = ({
  discountPercentage,
  isEmphased = true,
  labelSize = LABEL_SIZE.MEDIUM,
}) => {
  const color = isEmphased
    ? theme.palette.error.light
    : theme.palette.secondary.dark;
  const backgroundColor = isEmphased ? '#F7E9E8' : theme.palette.grey.A200;
  return (
    <Presenter
      discountPercentage={discountPercentage}
      labelSize={labelSize}
      color={color}
      backgroundColor={backgroundColor}
    />
  );
};

export { Container as DiscountPercentageLabel };
