import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';
import { Button, Typography, Link } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled('div')({
  textAlign: 'center',
});
const StyledLoginButton = styled(Button)(({ theme }) => ({
  fontWeight: 'bold',
  width: '100%',
  marginTop: theme.spacing(1),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  borderRadius: theme.spacing(4),
}));

type Props = {
  children: string;
  onClick: () => Promise<void> | undefined;
};

const Presenter: React.VFC<Props> = ({ children, onClick }) => (
  <StyledContainer data-cy="signInPageLoginButton">
    <div>
      <Typography variant="caption">
        <Link
          data-cy="termsOfServiceLink"
          component={RouterLink}
          to={PATHS.TERMS_OF_SERVICE}
          style={{ textDecoration: 'none' }}
        >
          サービス利用規約
        </Link>
        に同意したことになります
      </Typography>
    </div>
    <StyledLoginButton
      variant="contained"
      color="primary"
      disableElevation
      onClick={onClick}
      data-gtm-click="shared-loginButton-loginButton"
    >
      {children}
    </StyledLoginButton>
  </StyledContainer>
);

export { Presenter };
