import { gql } from '@apollo/client';

export const FRAGMENT_USER = gql`
  fragment UserFields on User {
    uid
    lastSignInAt
    profile {
      birthday
      gender {
        code
        name
      }
      prefecture {
        code
        name
      }
    }
    unreadAnnouncementsCountByCategoryUid {
      forAll
      forYou
    }
  }
`;
