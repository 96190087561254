import { gql } from '@apollo/client';
import { FRAGMENT_USER } from '/@/api/graphql/internalApi/fragments/user.gql';

export const getCurrentUserGql = gql`
  ${FRAGMENT_USER}
  query GetCurrentUser {
    currentUser {
      ...UserFields
    }
  }
`;
