import React from 'react';
import { Typography, Grid2 as Grid, Card, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { GiftCard } from './giftCard/Index';
import { formatToDateJa } from '/@/utils/formatDate';
import { GiftCardGifteeContentBase } from '/@/store/app/shared/types';

export type GiftCardGifteeContent = GiftCardGifteeContentBase;

const StyledCard = styled(Card)(({ theme }) => ({
  border: 'none',
  borderRadius: theme.spacing(2),
}));
const StyledContent = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
}));
const StyledContentExpiredAt = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));
const StyledContentName = styled(Typography)(({ theme }) => ({
  overflowWrap: 'break-word',
  fontWeight: 'bold',
  color: theme.palette.secondary.main,
}));
const StyledContentCount = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

type Props = {
  giftCardGifteeContent: GiftCardGifteeContent;
  ticketsCountText: string;
};

const Presenter: React.VFC<Props> = ({
  giftCardGifteeContent,
  ticketsCountText,
}) => (
  <StyledCard data-cy="giftCardGifteeContent" variant="outlined">
    <StyledContent>
      {giftCardGifteeContent.savedAt && (
        <StyledContentExpiredAt
          variant="caption"
          data-cy="giftCardGifteeContentSavedAt"
        >
          保存日時：{formatToDateJa(giftCardGifteeContent.savedAt)}
        </StyledContentExpiredAt>
      )}
      <StyledContentName variant="body1">
        {giftCardGifteeContent.content.name}
      </StyledContentName>
      <StyledContentCount variant="caption">
        {ticketsCountText}
      </StyledContentCount>
    </StyledContent>
    <Divider />
    <GiftCard giftCard={giftCardGifteeContent.content} />
  </StyledCard>
);

export { Presenter };
