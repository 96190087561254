import React from 'react';
import { Container, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { getHtmlString } from '/@/utils/getHtmlString';
import { formatToDateAndTimeJa } from '/@/utils/formatDate';
import { AnnouncementState } from '/@/store/app/announcementDetail';
import { STATUS, Status } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';

const StyledAnnouncementDetailContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
  textAlign: 'center',
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 'bold',
  color: theme.palette.secondary.dark,
}));

const StyledDate = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
  textAlign: 'left',
  fontSize: '0.625rem',
  fontWeight: 'bold',
  color: theme.palette.grey[400],
}));

const StyledBody = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  fontSize: '0.875rem',
  color: theme.palette.secondary.dark,
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  wordBreak: 'break-all',
}));

const StyledImage = styled('img')(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
  height: 'auto',
}));

type Props = {
  headerTitle: string;
  isDisplayAnnouncementDetail: boolean;
  announcementDetail: AnnouncementState;
  getAnnouncementStatus: Status;
  handleClickImage?: () => void;
};

const Presenter: React.VFC<Props> = ({
  headerTitle,
  isDisplayAnnouncementDetail,
  announcementDetail,
  getAnnouncementStatus,
  handleClickImage,
}) => (
  <MainLayout headerTitle={headerTitle}>
    <StyledAnnouncementDetailContainer>
      {getAnnouncementStatus === STATUS.LOADING && <PartialLoader />}
      {isDisplayAnnouncementDetail && announcementDetail !== null && (
        <>
          <StyledTitle variant="subtitle1">
            {announcementDetail.title}
          </StyledTitle>
          <StyledDate variant="h6">
            {formatToDateAndTimeJa(announcementDetail.createdAt)}
          </StyledDate>
          {announcementDetail.content !== null && (
            <>
              {announcementDetail.content.linkUrl ? (
                <StyledImage
                  src={announcementDetail.content.imageFileUrl}
                  alt={'お知らせ画像'}
                  onClick={handleClickImage}
                />
              ) : (
                <StyledImage
                  src={announcementDetail.content.imageFileUrl}
                  alt={'お知らせ画像'}
                />
              )}
            </>
          )}
          {announcementDetail.body !== null && (
            <StyledBody
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: getHtmlString(announcementDetail.body),
              }}
            />
          )}
        </>
      )}
    </StyledAnnouncementDetailContainer>
  </MainLayout>
);

export { Presenter };
