import { getCurrentUser } from '/@/api/graphql/internalApi/queries/getCurrentUser';
import type { GetCurrentUserResponse } from '/@/api/graphql/internalApi/queries/getCurrentUser';
import type { AppThunk } from '/@/store';
import {
  getCurrentUserStart,
  getCurrentUserSuccess,
  getCurrentUserFailure,
} from './actions';
import { ApiError } from '/@/api/graphql/client';

const getCurrentUserThunk = (): AppThunk<Promise<void>> => (dispatch) => {
  dispatch(getCurrentUserStart());
  return getCurrentUser()
    .then((response) => dispatch(getCurrentUserSuccessThunk(response)))
    .catch((error) => dispatch(getCurrentUserFailureThunk(error)));
};

const getCurrentUserSuccessThunk =
  (response: GetCurrentUserResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getCurrentUserSuccess(response.data!.currentUser));
    return Promise.resolve();
  };

const getCurrentUserFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getCurrentUserFailure(error));
    // NOTE: 500 ページに飛ばさないので、errorHandler を利用しない
    return Promise.reject(error);
  };

export { getCurrentUserThunk };
