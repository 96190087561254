import { FetchResult } from '@apollo/client';
import { internalApiClient } from '/@/api/graphql/appApolloClient';
import { createPointChargeWithSerialCodeGql } from './index.gql';
import {
  CreatePointChargeWithSerialCodeMutationVariables,
  CreatePointChargeWithSerialCodeMutation,
} from '/@/api/graphql/internalApi/types';

type CreatePointChargeWithSerialCodeResponse =
  FetchResult<CreatePointChargeWithSerialCodeMutation>;

const createPointChargeWithSerialCode = (
  input: CreatePointChargeWithSerialCodeMutationVariables,
): Promise<CreatePointChargeWithSerialCodeResponse> => {
  return internalApiClient.mutate({
    mutation: createPointChargeWithSerialCodeGql,
    variables: input,
  });
};

export { createPointChargeWithSerialCode };

export type { CreatePointChargeWithSerialCodeResponse };
