import { getGifteeContent } from '/@/api/graphql/internalApi/queries/getGifteeContent';
import type { GetGifteeContentResponse } from '/@/api/graphql/internalApi/queries/getGifteeContent';
import type { AppThunk } from '/@/store';
import {
  getGifteeContentStart,
  getGifteeContentSuccess,
  getGifteeContentFailure,
} from './actions';
import type { GetGifteeContentQueryVariables } from '/@/api/graphql/internalApi/types';
import { handleErrorThunk } from '../../handleError';
import type { ApiError } from '/@/api/graphql/client';

const getGifteeContentThunk =
  (variables: GetGifteeContentQueryVariables): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getGifteeContentStart());

    return getGifteeContent(variables)
      .then((response) => dispatch(getGifteeContentSuccessThunk(response)))
      .catch((error) => dispatch(getGifteeContentFailureThunk(error)));
  };

const getGifteeContentSuccessThunk =
  (response: GetGifteeContentResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { gifteeContent } = response.data!;
    dispatch(getGifteeContentSuccess(gifteeContent));
    return Promise.resolve();
  };

const getGifteeContentFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getGifteeContentFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { getGifteeContentThunk };
