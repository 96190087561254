import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { ItemCard } from './ItemCard/Index';
import { STATUS, Status } from '/@/store/api/constants';
import { PartialLoader } from '../loader/partialLoader/Index';
import { ItemDetailModal } from './itemDetailModal/Index';
import { SearchedItemsState } from '/@/store/app/searchedItemsConnection';

const StyledGrid = styled(Grid)(({ theme }) => ({
  rowGap: theme.spacing(2),
}));

type Props = {
  searchedItemContents: SearchedItemsState;
  infiniteScrollTarget: React.RefObject<HTMLDivElement>;
  getSearchedItemsConnectionStatus: Status;
  selectItemDetail: (uid: string) => void;
};

const Presenter: React.VFC<Props> = ({
  searchedItemContents,
  infiniteScrollTarget,
  getSearchedItemsConnectionStatus,
  selectItemDetail,
}) => (
  <>
    {searchedItemContents && searchedItemContents.length > 0 ? (
      <StyledGrid
        container
        justifyContent="center"
        alignItems="center"
        data-cy="itemContents"
      >
        {searchedItemContents.map(
          (searchedItemContent, index) =>
            searchedItemContent && (
              <ItemCard
                key={index}
                itemContent={searchedItemContent}
                selectItemDetail={selectItemDetail}
              />
            ),
        )}
        {getSearchedItemsConnectionStatus === STATUS.LOADING && (
          <PartialLoader />
        )}
      </StyledGrid>
    ) : getSearchedItemsConnectionStatus === STATUS.LOADING ? (
      <PartialLoader />
    ) : (
      <Typography>現在交換可能なチケットはありません</Typography>
    )}
    <div ref={infiniteScrollTarget} />
    <ItemDetailModal />
  </>
);

export { Presenter };
