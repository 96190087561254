import React from 'react';
import { Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/system';
import {
  SelectedGiftsAvailabilityTabState,
  GIFTS_AVAILABILITY,
} from '/@/store/ui/selectedGiftsAvailabilityTab';
import { AvailableMyGifts } from '/@/components/page/giftsPage/availableMyGifts/Index';
import { UnavailableMyGifts } from '/@/components/page/giftsPage/unavailableMyGifts/Index';
const StyledTab = styled(Tab)(({ theme }) => ({
  height: '3.5rem',
  borderBottom: '2px solid ' + theme.palette.grey[300],
}));

const StyledTabLabel = styled(Typography)({
  fontWeight: 'bold',
});

type Props = {
  selectedGiftsAvailabilityTab: SelectedGiftsAvailabilityTabState;
  handleGiftsAvailabilityTab: (
    selectedGiftsAvailabilityTab: SelectedGiftsAvailabilityTabState,
  ) => void;
};
const Presenter: React.VFC<Props> = ({
  selectedGiftsAvailabilityTab,
  handleGiftsAvailabilityTab,
}) => (
  <>
    <Tabs
      value={selectedGiftsAvailabilityTab}
      onChange={(_, value) => handleGiftsAvailabilityTab(value)}
      aria-label="ギフトの表示を切り替えるタブ"
      variant="fullWidth"
      indicatorColor="primary"
      textColor="primary"
    >
      <StyledTab
        label={<StyledTabLabel variant="button">利用可能</StyledTabLabel>}
        value={GIFTS_AVAILABILITY.AVAILABLE}
        data-cy="selectAvailableGiftsTab"
      />
      <StyledTab
        label={
          <StyledTabLabel variant="button">利用済み・期限切れ</StyledTabLabel>
        }
        value={GIFTS_AVAILABILITY.UNAVAILABLE}
        data-cy="selectUnavailableGiftsTab"
      />
    </Tabs>
    {selectedGiftsAvailabilityTab === GIFTS_AVAILABILITY.AVAILABLE ? (
      <AvailableMyGifts />
    ) : (
      <UnavailableMyGifts />
    )}
  </>
);

export { Presenter };
