import { notifyInquiry } from '/@/api/graphql/publicApi/mutations/notifyInquiry';
import {
  notifyInquiryStart,
  notifyInquirySuccess,
  notifyInquiryFailure,
} from './actions';
import { getSessionTokenFromCookie } from '/@/utils/getSessionTokenFromCookie';
import type { NotifyInquiryInput } from '/@/api/graphql/publicApi/types';
import type { AppThunk } from '/@/store';
import { handleErrorThunk } from '../../handleError';
import { resetInquiriesNewPageStatesReEnterNeededThunk } from '/@/store/page/inquiriesPage/newPage/operations';
import { ApiError } from '/@/api/graphql/client';

const notifyInquiryThunk =
  (
    input: NotifyInquiryInput,
  ): // eslint-disable-next-line @typescript-eslint/no-explicit-any
  AppThunk<Promise<void | any>> =>
  (dispatch) => {
    dispatch(notifyInquiryStart());

    const sessionToken = getSessionTokenFromCookie();

    return notifyInquiry({ ...input, sessionToken })
      .then(() => dispatch(notifyInquirySuccessThunk()))
      .catch((error) => dispatch(notifyInquiryFailureThunk(error)));
  };

const notifyInquirySuccessThunk = (): AppThunk<Promise<void>> => (dispatch) => {
  dispatch(notifyInquirySuccess());
  dispatch(resetInquiriesNewPageStatesReEnterNeededThunk());
  return Promise.resolve();
};

const notifyInquiryFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(notifyInquiryFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { notifyInquiryThunk };
