import React from 'react';
import { CircularProgress, Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/system';

const StyledProgress = styled(CircularProgress)(({ theme }) => ({
  margin: theme.spacing(2),
}));

const Presenter: React.VFC<Record<string, never>> = () => (
  <Grid container justifyContent="center" data-cy="partialLoader">
    <StyledProgress color="primary" />
  </Grid>
);

export { Presenter };
