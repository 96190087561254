import React from 'react';
import { Typography, Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/system';
import { DestinationEnum } from '/@/api/graphql/publicApi/types';
import { Modal } from '/@/components/shared/modal/Index';
import { LoginButton } from '/@/components/shared/loginButton/Index';

const StyledDescription = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const StyledCloseLink = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  textDecoration: 'none',
}));

type Props = {
  modalOpen: boolean;
  handleClose: () => void;
};

const Presenter: React.VFC<Props> = ({ modalOpen, handleClose }) => (
  <>
    {modalOpen && (
      <Modal handleClose={handleClose}>
        <Grid container direction="column">
          <Grid>
            <Typography variant="body1">【お問い合わせに関して】</Typography>
          </Grid>
          <Grid>
            <StyledDescription variant="body2">
              {`LINEログインすることで
            お客様の状況確認がスムーズに行え、
            より早く適切なご案内ができる場合があります。`}
            </StyledDescription>
          </Grid>
          <LoginButton
            url={null}
            origin={null}
            referer={null}
            convertToPoint={null}
            destination={DestinationEnum.Inquiry}
            serialCode={null}
            data-gtm-click="inquiriesPage-newPage-loginModal-loginButton"
          >
            ログインする
          </LoginButton>
          <StyledCloseLink
            variant="caption"
            onClick={handleClose}
            data-cy="closeButton"
            data-gtm-click="inquiriesPage-newPage-loginModal-closeButton"
          >
            このまま問い合わせる
          </StyledCloseLink>
        </Grid>
      </Modal>
    )}
  </>
);

export { Presenter };
