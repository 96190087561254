import React from 'react';
import { Typography, Grid2 as Grid, Box, Container } from '@mui/material';
import { styled } from '@mui/system';
import { Promotion } from '/@/components/shared/promotion/Index';
import { PromotionSpotsState } from '/@/store/app/promotionSpots';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { STATUS } from '/@/store/api/constants';
import type { Status } from '/@/store/api/constants';
import getGiftHeading from '/@/assets/page/promotionsPage/get-gift-heading.svg';

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
}));
const StyledHeadingImageBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.contrast,
  lineHeight: 0,
}));
const StyledTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.dark,
  fontWeight: 'bold',
}));
const StyledLoaderContainer = styled(Grid)({
  position: 'fixed',
  inset: 0,
});

type Props = {
  promotionSpots: PromotionSpotsState;
  getPromotionSpotsStatus: Status;
};

const Presenter: React.VFC<Props> = ({
  promotionSpots,
  getPromotionSpotsStatus,
}) => (
  <MainLayout>
    <StyledHeadingImageBox>
      <img
        src={getGiftHeading}
        alt="ギフトをもらう"
        width="100%"
        height="100"
        data-cy="getGiftHeading"
      />
    </StyledHeadingImageBox>
    <StyledContainer maxWidth="sm">
      <Grid container direction="column">
        <StyledTitle variant="subtitle1">もらえるギフト</StyledTitle>
        {getPromotionSpotsStatus === STATUS.LOADING ? (
          <StyledLoaderContainer container alignItems="center">
            <PartialLoader />
          </StyledLoaderContainer>
        ) : (
          <Grid>
            {promotionSpots?.length ? (
              promotionSpots.map((promotionSpot) => (
                <Promotion
                  key={`${promotionSpot.name}${promotionSpot.order}`}
                  promotionSpot={promotionSpot}
                />
              ))
            ) : (
              <Typography variant="body2">
                現在応募可能なキャンペーンはありません。
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
    </StyledContainer>
  </MainLayout>
);

export { Presenter };
