import React from 'react';
import { Presenter } from './Presenter';

const Container: React.VFC<{
  headerTitle: string;
  handleNavigateBack: () => void;
}> = ({ headerTitle, handleNavigateBack }) => {
  return (
    <Presenter
      headerTitle={headerTitle}
      handleNavigateBack={handleNavigateBack}
    />
  );
};

export { Container as HeaderTitle };
