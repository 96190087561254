import { combineReducers, Reducer } from 'redux';
import { types } from './types';
import { STATUS } from '/@/store/api/constants';
import type { Status } from '/@/store/api/constants';
import type { RootAction } from '/@/store/actions';
import type { Error } from '/@/store/api/types';

const status: Reducer<Status, RootAction> = (state = STATUS.IDLE, action) => {
  switch (action.type) {
    case types.INITIALISE_GIFT_CONVERT_TO_POINT_REQUEST_SUBSCRIPTION_STATUS:
      return STATUS.IDLE;
    case types.GIFT_CONVERT_TO_POINT_REQUEST_SUBSCRIPTION_START:
      return STATUS.LOADING;
    case types.GIFT_CONVERT_TO_POINT_REQUEST_SUBSCRIPTION_SUCCESS:
      return STATUS.SUCCESS;
    case types.GIFT_CONVERT_TO_POINT_REQUEST_SUBSCRIPTION_FAILURE:
      return STATUS.FAILURE;
    default:
      return state;
  }
};

const error: Reducer<Error | string, RootAction> = (state = null, action) => {
  switch (action.type) {
    case types.GIFT_CONVERT_TO_POINT_REQUEST_SUBSCRIPTION_START:
      return null;
    case types.GIFT_CONVERT_TO_POINT_REQUEST_SUBSCRIPTION_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const giftConvertToPointRequestSubscription = combineReducers({
  status,
  error,
});

export { giftConvertToPointRequestSubscription };
