import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import list from '/@/assets/page/signInPage/featureSection/signin-feature-list.svg';
import gift from '/@/assets/page/signInPage/featureSection/signin-feature-gift.svg';
import line from '/@/assets/page/signInPage/featureSection/signin-feature-line.svg';

const StyledSectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.gifteeCoral.main,
}));
const StyledSectionSubTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&::before': {
    borderTop: '3px solid',
    borderColor: theme.palette.primary.light,
    content: '""',
    width: '12px',
    marginRight: theme.spacing(1),
  },
  '&::after': {
    borderTop: '3px solid',
    borderColor: theme.palette.primary.light,
    content: '""',
    width: '12px',
    marginLeft: theme.spacing(1),
  },
}));
const StyledFeature = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));
const StyledListImageContainer = styled('div')({
  marginLeft: 'auto',
  textAlign: 'right',
});
const StyledGiftImageContainer = styled('div')({
  marginRight: 'auto',
  textAlign: 'left',
});
const StyledLineImageContainer = styled('div')({
  marginLeft: 'auto',
  textAlign: 'right',
});
const StyledListImage = styled('img')({
  width: '80%',
});
const StyledGiftImage = styled('img')({
  width: '80%',
});
const StyledLineImage = styled('img')({
  width: '80%',
});
const StyledFeatureTextContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(2),
  textAlign: 'center',
}));
const StyledFeatureTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
}));
const StyledFeatureDescription = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  textAlign: 'left',
  fontWeight: 'normal',
  color: theme.palette.secondary.main,
}));

type Props = Record<string, never>;

const Presenter: React.VFC<Props> = () => (
  <div data-cy="signInPageFeatureSection">
    <Grid container direction="row" justifyContent="center">
      <Grid size={{ xs: 12 }}>
        <StyledSectionTitle align="center" variant="h5">
          FEATURE
        </StyledSectionTitle>
        <StyledSectionSubTitle align="center" variant="body2">
          gift wallet でできること
        </StyledSectionSubTitle>
      </Grid>

      <StyledFeature size={{ xs: 12 }}>
        <StyledListImageContainer>
          <StyledListImage src={list} alt="list" />
        </StyledListImageContainer>
        <StyledFeatureTextContainer>
          <StyledFeatureTitle variant="body1">
            もらったギフトを一覧で管理できる
          </StyledFeatureTitle>
          <StyledFeatureDescription variant="caption">
            「マイギフト」ページから確認できるので、ギフトの期限切れ、使い忘れを減らせます。
          </StyledFeatureDescription>
        </StyledFeatureTextContainer>
      </StyledFeature>

      <StyledFeature size={{ xs: 12 }}>
        <StyledGiftImageContainer>
          <StyledGiftImage src={gift} alt="gift" />
        </StyledGiftImageContainer>
        <StyledFeatureTextContainer>
          <StyledFeatureTitle variant="body1">
            保存したチケットをすぐに表示できる
          </StyledFeatureTitle>
          <StyledFeatureDescription variant="caption">
            gift walletのLINEアカウントを開いて、チケットをタップするだけ。
          </StyledFeatureDescription>
        </StyledFeatureTextContainer>
      </StyledFeature>

      <StyledFeature size={{ xs: 12 }}>
        <StyledLineImageContainer>
          <StyledLineImage src={line} alt="line" />
        </StyledLineImageContainer>
        <StyledFeatureTextContainer>
          <StyledFeatureTitle variant="body1">
            ギフトはLINEから確認できる
          </StyledFeatureTitle>
          <StyledFeatureDescription variant="caption">
            ギフトがgiftee walletに保存されると、LINEアカウントに通知されます。
          </StyledFeatureDescription>
        </StyledFeatureTextContainer>
      </StyledFeature>
    </Grid>
  </div>
);

export { Presenter };
