import { gql } from '@apollo/client';
import { FRAGMENT_ERRORS } from '/@/api/graphql/internalApi/fragments/errors.gql';

export const readAnnouncementGql = gql`
  ${FRAGMENT_ERRORS}
  mutation ReadAnnouncement(
    $clientMutationId: String
    $userAnnouncementUid: ID!
  ) {
    readUserAnnouncement(
      input: {
        clientMutationId: $clientMutationId
        userAnnouncementUid: $userAnnouncementUid
      }
    ) {
      errors {
        ...ErrorsFields
      }
    }
  }
`;
