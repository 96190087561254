import React, { ReactNode } from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledValidationMessage = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  color: theme.palette.error.main,
  fontSize: '0.75rem',
  lineHeight: '1',
}));

type Props = {
  children: ReactNode;
  validationMessage: string | null;
};

const Presenter: React.VFC<Props> = ({ children, validationMessage }) => (
  <div>
    {children}
    {validationMessage && (
      <StyledValidationMessage>{validationMessage}</StyledValidationMessage>
    )}
  </div>
);

export { Presenter };
