import { types } from './types';
import type { GetAnnouncementQuery } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const getAnnouncementStart = () =>
  ({
    type: types.GET_ANNOUNCEMENT_START,
  }) as const;

export const getAnnouncementSuccess = (
  announcement: GetAnnouncementQuery['userAnnouncement'],
) =>
  ({
    type: types.GET_ANNOUNCEMENT_SUCCESS,
    announcement,
  }) as const;

export const getAnnouncementFailure = (error: ApiError) =>
  ({
    type: types.GET_ANNOUNCEMENT_FAILURE,
    error,
  }) as const;
