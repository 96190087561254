import { types } from './types';
import { ApiError } from '/@/api/graphql/client';
import type { ReadAnnouncementsMutation } from '/@/api/graphql/internalApi/types';

export const readAnnouncementsStart = () =>
  ({
    type: types.READ_ANNOUNCEMENTS_START,
  }) as const;

export const readAnnouncementsSuccess = (
  user: NonNullable<ReadAnnouncementsMutation['readUserAnnouncements']['user']>,
) =>
  ({
    type: types.READ_ANNOUNCEMENTS_SUCCESS,
    message: '全てのお知らせを既読にしました',
    user,
  }) as const;

export const readAnnouncementsFailure = (error: ApiError) =>
  ({
    type: types.READ_ANNOUNCEMENTS_FAILURE,
    message: '既読に失敗しました',
    error,
  }) as const;
