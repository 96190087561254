import React from 'react';
import { Typography, Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/system';
import topImage from '/@/assets/page/lpPage/lpChargeSerialCodePage/lp-hero-top-image.png';

const StyledContainer = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(4, 3),
}));
const StyledMainTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '1.5rem',
  fontWeight: 'bold',
  lineHeight: '1.3',
  textAlign: 'center',
}));
const StyledTopImage = styled('img')(({ theme }) => ({
  display: 'block',
  width: '90%',
  margin: `${theme.spacing(3)} auto`,
}));
const StyledMainText = styled(Typography)({
  fontSize: '0.875rem',
  fontWeight: 'bold',
  lineHeight: '1.4',
  textAlign: 'left',
});

type Props = {
  children: React.ReactNode;
};

const Presenter: React.VFC<Props> = ({ children }) => (
  <StyledContainer data-cy="signInPageHeroSection">
    <Grid container direction="column" alignContent="center">
      <Grid size="grow">
        <StyledMainTitle variant="h1">
          gift wallet ポイントを
          <br />
          チャージしてお得に活用！
        </StyledMainTitle>
      </Grid>
      <Grid size="grow">
        <StyledTopImage src={topImage} alt="giftWalletTopimage" />
        <StyledMainText variant="body1">
          gift wallet
          ポイントをチャージするとお得で便利なポイント活用ができます。
        </StyledMainText>
      </Grid>
      <Grid size="grow">{children}</Grid>
    </Grid>
  </StyledContainer>
);

export { Presenter };
