import React from 'react';
import { styled } from '@mui/system';
import menuIcon from '/@/assets/templates/header/globalHeader/menu-icon.svg';
import { IconButton } from '@mui/material';

const StyledMenuIconButton = styled(IconButton)({
  padding: 0,
});

type Props = {
  handleToggleMenu: () => void;
  handleClickGlobalMenu: () => void;
};

const Presenter: React.VFC<Props> = ({
  handleToggleMenu,
  handleClickGlobalMenu,
}) => (
  <StyledMenuIconButton
    color="primary"
    aria-label="menu-icon-button"
    onClick={() => {
      handleToggleMenu();
      handleClickGlobalMenu();
    }}
    data-cy="menuIcon"
    data-gtm-click="templates-header-menuButton"
  >
    <img height={20} width={20} src={menuIcon} alt="メニューアイコン" />
  </StyledMenuIconButton>
);

export { Presenter };
