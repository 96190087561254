import { gql } from '@apollo/client';

export const getPublicAssetsGql = gql`
  query GetPublicAssets($uidIn: [String!]!) {
    publicAssets(uidIn: $uidIn) {
      uid
      uploadedAssetUrl
    }
  }
`;
