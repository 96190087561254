import { Reducer } from 'redux';
import { types } from './types';
import type { RootAction } from '/@/store/actions';

type RenewalDesignFlagState = boolean;

const renewalDesignFlag: Reducer<RenewalDesignFlagState, RootAction> = (
  state = false,
  action,
) => {
  switch (action.type) {
    case types.SET_RENEWAL_DESIGN_FLAG:
      return action.flag;
    default:
      return state;
  }
};

export { renewalDesignFlag };
export type { RenewalDesignFlagState };
