import React from 'react';
import { Grid2 as Grid, Container, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { Link } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';

const StyledPointChargeContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.contrast,
  padding: theme.spacing(3),
}));
const StyledPointChargeTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
  marginBottom: theme.spacing(3),
}));
const StyledCautionaryMessage = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  textAlign: 'left',
  fontWeight: 'bold',
  color: theme.palette.grey[700],
  marginTop: theme.spacing(3),
}));
const StyledInquiriesNewPageLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const Presenter: React.VFC<Record<string, never>> = () => (
  <MainLayout>
    <StyledPointChargeContainer maxWidth="sm">
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        direction="column"
      >
        <StyledPointChargeTitle>
          シリアルコードを読み込んでいます
        </StyledPointChargeTitle>
        <PartialLoader />
        <StyledCautionaryMessage>
          しばらく待っても画面が切り替わらない場合は、時間をおいて再度お試しください。
          <br />
          それでも解決しない場合は、
          <StyledInquiriesNewPageLink
            to={PATHS.INQUIRIES_NEW}
            data-cy="inquiriesNewPageLink"
          >
            こちら
          </StyledInquiriesNewPageLink>
          からお問い合わせください。
        </StyledCautionaryMessage>
      </Grid>
    </StyledPointChargeContainer>
  </MainLayout>
);

export { Presenter };
