import { types } from './types';
import type { GetItemsConnectionQuery } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';
import { ItemsPatternTypes } from './operations';

export const getItemsConnectionStart = (itemsPattern: ItemsPatternTypes) =>
  ({
    type: types.GET_ITEMS_CONNECTION_START,
    pattern: itemsPattern,
  }) as const;

export const getItemsConnectionSuccess = (
  itemsConnection: GetItemsConnectionQuery['items'],
  itemsPattern: ItemsPatternTypes,
) =>
  ({
    type: types.GET_ITEMS_CONNECTION_SUCCESS,
    itemsConnection,
    pattern: itemsPattern,
  }) as const;

export const getItemsConnectionFailure = (
  error: ApiError,
  itemsPattern: ItemsPatternTypes,
) =>
  ({
    type: types.GET_ITEMS_CONNECTION_FAILURE,
    error,
    pattern: itemsPattern,
  }) as const;
