import { combineReducers, Reducer } from 'redux';
import { types } from './types';
import { STATUS } from '/@/store/api/constants';
import type { Status } from '/@/store/api/constants';
import type { RootAction } from '/@/store/actions';
import type { Error } from '/@/store/api/types';

const status: Reducer<Status, RootAction> = (state = STATUS.IDLE, action) => {
  switch (action.type) {
    case types.GET_POINT_LOGS_START:
      return STATUS.LOADING;
    case types.GET_POINT_LOGS_SUCCESS:
      return STATUS.SUCCESS;
    case types.GET_POINT_LOGS_FAILURE:
      return STATUS.FAILURE;
    default:
      return state;
  }
};

const error: Reducer<Error, RootAction> = (state = null, action) => {
  switch (action.type) {
    case types.GET_POINT_LOGS_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const getPointLogs = combineReducers({ status, error });

export { getPointLogs };
