import React from 'react';
import {
  Grid2 as Grid,
  Typography,
  Divider,
  Box,
  Container,
} from '@mui/material';
import { styled } from '@mui/system';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { Link } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
}));
const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));
const StyledListTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
}));
const StyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));
const StyledOrderedList = styled('ol')(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}));
const StyledUnorderedList = styled('ul')(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}));
const StyledListText = styled('li')(({ theme }) => ({
  paddingBottom: theme.spacing(),
  fontSize: '0.75rem',
}));
const StyledBodyText = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(),
  fontSize: '0.75rem',
}));
const StyledSingleItem = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const Presenter: React.VFC<Record<string, never>> = () => (
  <MainLayout>
    <StyledContainer maxWidth="sm">
      <Grid container direction="column" data-cy="termsOfService">
        <StyledTitle variant="h6">gift walletサービス利用規約</StyledTitle>
        <Box>
          <StyledListTitle variant="subtitle1">
            第１条（規約の目的及び適用）
          </StyledListTitle>
          <StyledDivider />
          <StyledOrderedList>
            <StyledListText>
              株式会社ギフティ（以下「当社」といいます。）は、本「gift
              walletサービス利用規約」（以下本条第2項に定義する「個別規定」と合わせて「本規約」といいます。）に基づき、別紙に規定するサービス（以下総称して「本サービス」といいます。）を、本サービスの利用者（以下「ユーザー」といいます。）に対して提供します。
            </StyledListText>
            <StyledListText>
              当社は、本規約に関する条項の追加、削除、特約等の条件または本サービスに関する説明（以下「個別規定」といいます。）を別途定めることがあります。この場合、個別規定は本規約の一部を構成するものとし、本規約と個別規定との間に齟齬が生じた場合は、個別規定が本規約に優先して適用されるものとします。
            </StyledListText>
            <StyledListText>
              本サービスの提供区域は、日本国内に限定されるものとします。
            </StyledListText>
          </StyledOrderedList>
        </Box>
        <Box>
          <StyledListTitle variant="subtitle1">
            第２条（アカウント登録）
          </StyledListTitle>
          <StyledDivider />
          <StyledOrderedList>
            <StyledListText>
              ユーザーは、予め本規約の内容を確認したうえ、当社所定の方法により、本サービスのアカウント（以下「アカウント」といいます）の登録（以下「アカウント登録」といいます。）を行うものとします。なお、当社及び第三者が提供する他のサービスと連携して認証を行うことにより、アカウント登録ができる場合があります。当該他のサービスの利用状況によっては、アカウント登録及び本サービスの利用に影響する場合がありますが、当社は当該他のサービスに起因して生じる事由については、当社の責めに帰すべき事由による場合を除き、一切責任を負わないものとします。
            </StyledListText>
            <StyledListText>
              未成年者が本サービスを利用する場合には、親権者の同意を得るものとします。
            </StyledListText>
            <StyledListText>
              当社は、ユーザーが以下の各号に該当する場合には、アカウント登録を承諾せず、または事後的に当該承諾を取り消すことにより当該ユーザーの登録を取り消す場合があります。当社は、アカウント登録の承諾に関する当社の判断につき、その理由をユーザーに開示する義務を負いません。
              <StyledOrderedList type="a">
                <StyledListText>
                  本条第1項の方法によらずにアカウント登録を行った場合
                </StyledListText>
                <StyledListText>
                  ユーザーが、過去において、本規約に違反する等の理由によりアカウントを停止または削除されたことがある場合
                </StyledListText>
                <StyledListText>
                  アカウント登録情報（第4項に定義します。）に虚偽、誤記または記入漏れがあった場合
                </StyledListText>
                <StyledListText>
                  暴力団その他の反社会的勢力の構成員もしくはこれに準ずる者であり、または過去においてこれらの者であった場合
                </StyledListText>
                <StyledListText>
                  前各号のほか、当社がユーザーに不適当な者であると判断した場合本サービスの提供区域は、日本国内に限定されるものとします。
                </StyledListText>
              </StyledOrderedList>
            </StyledListText>
            <StyledListText>
              ユーザーはアカウント登録のために当社に提供した情報（以下「アカウント登録情報」といいます。）を、本サービス、及びアカウントに対応する他のサービスの認証及び参照できる情報として当社が使用することについて事前に同意するものとします。当社は当社の定めるプライバシーポリシーに従いアカウント登録情報を利用するものとします。
            </StyledListText>
          </StyledOrderedList>
        </Box>
        <Box>
          <StyledListTitle variant="subtitle1">
            第３条（アカウントの管理）
          </StyledListTitle>
          <StyledDivider />
          <StyledOrderedList>
            <StyledListText>
              ユーザーは、自己の責任をもって、アカウント（ID、パスワードを含みます。以下同じ）を管理するものとします。
            </StyledListText>
            <StyledListText>
              ユーザーは、有償無償を問わず、アカウントを第三者に譲渡・貸与・質入れ等し、または利用させないものとします。
            </StyledListText>
            <StyledListText>
              ユーザーは、アカウントの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任を負うものとします。
            </StyledListText>
            <StyledListText>
              ユーザーは、アカウント登録情報に変更が生じた場合には、速やかに当社所定の方法により通知しなければならないものとします。
            </StyledListText>
            <StyledListText>
              ユーザーは、前項の通知を怠ったときは、当社からの通知が遅滞又は不到達となった場合にも、当該通知が通常到達するべき時に到達したとみなされるものとします。また、前項の通知を怠ったことによってユーザーに生じた損害について当社は一切の責任を負わず、当社および第三者に生じた損害についてユーザーが一切の責任を負うものとします。
            </StyledListText>
            <StyledListText>
              ユーザーは、1人につき1つのアカウントを保有するものとします。複数人が1つのアカウントを共同して保有することはできません。
            </StyledListText>
            <StyledListText>
              本サービスに登録した情報について、ユーザーは必要に応じてバックアップを取るものとします。
            </StyledListText>
          </StyledOrderedList>
        </Box>
        <Box>
          <StyledListTitle variant="subtitle1">
            第４条（アカウントの停止または削除）
          </StyledListTitle>
          <StyledDivider />
          <StyledOrderedList>
            <StyledListText>
              ユーザーがアカウント削除を希望する場合には、当該ユーザーは、当社所定の方法により、アカウント削除を行うものとします。
            </StyledListText>
            <StyledListText>
              当社は、ユーザーが以下の各号に該当する場合、ユーザーへの予告および理由の通知なしに、アカウントの停止または削除を行うことができるものとします。
              <StyledOrderedList type="a">
                <StyledListText>
                  当該ユーザーが、過去において、本規約に違反する等の理由によりアカウントを停止または削除されたことがある場合
                </StyledListText>
                <StyledListText>
                  アカウント登録情報に虚偽があった場合
                </StyledListText>
                <StyledListText>
                  暴力団その他の反社会的勢力の構成員もしくはこれに準ずる者であり、または過去においてこれらの者であった場合
                </StyledListText>
                <StyledListText>
                  当社のサービスに係る料金等の債務の履行遅滞または履行不能が発生した場合
                </StyledListText>
                <StyledListText>本規約に違反した場合</StyledListText>
                <StyledListText>
                  その他当社が不適切と判断する行為を行った場合
                </StyledListText>
              </StyledOrderedList>
            </StyledListText>
            <StyledListText>
              アカウントの削除を行った場合には、当該アカウントに係るユーザーの情報は削除され、いかなる場合であっても復元できません。
            </StyledListText>
          </StyledOrderedList>
        </Box>
        <Box>
          <StyledListTitle variant="subtitle1">
            第５条（gift walletポイントへの交換）
          </StyledListTitle>
          <StyledDivider />
          <StyledOrderedList>
            <StyledListText>
              ユーザーは、本サービスを通して、自己が保有する電子チケットを、本サービス内で利用できるポイント(以下「gift
              walletポイント」といいます。)に交換することができます。
            </StyledListText>
            <StyledListText>
              gift
              walletポイントは、ユーザーが他の電子チケットを本サービス内で交換して取得したgift
              walletポイントと合算することができます。ただし、それぞれのgift
              walletポイントの有効期限は交換の対象となった電子チケットに応じて、それぞれ設定されるものとします。
            </StyledListText>
          </StyledOrderedList>
        </Box>
        <Box>
          <StyledListTitle variant="subtitle1">
            第6条（有効期限）
          </StyledListTitle>
          <StyledDivider />
          <StyledSingleItem>
            <StyledBodyText>
              gift
              walletポイントは、本サービス内において表示された有効期限内に使用できるものとし、有効期限の経過によりgift
              walletポイントは失効します。理由の如何を問わず、有効期限を過ぎたことによるgift
              walletポイントの失効について、当社は一切の責任を負わないものとします。
            </StyledBodyText>
          </StyledSingleItem>
        </Box>
        <Box>
          <StyledListTitle variant="subtitle1">
            第７条（ギフトへの交換）
          </StyledListTitle>
          <StyledDivider />
          <StyledSingleItem>
            <StyledBodyText>
              gift
              walletポイントは、当社所定の、電子チケットまたはその他の商品と交換することができます。gift
              walletポイントと交換可能な電子チケットまたはその他の商品は当社の判断により変更することができるものとし、当社は本サービス上に表示している商品を除き、gift
              walletポイントを特定の商品と交換することをユーザーに保証するものではありません。
            </StyledBodyText>
          </StyledSingleItem>
        </Box>
        <Box>
          <StyledListTitle variant="subtitle1">
            第８条（利用環境の整備）
          </StyledListTitle>
          <StyledDivider />
          <StyledOrderedList>
            <StyledListText>
              ユーザーは、自己の費用と責任で本サービスを利用するために必要な機器・設備・ソフトウェア・通信手段等の利用環境を準備し、適切に維持するものとします。当社は、ユーザーがこの利用環境が準備・維持できず、本サービスを利用できない場合の一切の責任を負わないものとします。
            </StyledListText>
            <StyledListText>
              ユーザーは自己の利用環境に応じて、コンピュータウィルスの感染の防止、不正アクセスおよび情報漏洩の防止等のセキュリティ対策を講じるものとします。
            </StyledListText>
          </StyledOrderedList>
        </Box>
        <Box>
          <StyledListTitle variant="subtitle1">
            第９条（情報利用について）
          </StyledListTitle>
          <StyledDivider />
          <StyledOrderedList>
            <StyledListText>
              当社は、本サービスについてユーザーの下記情報を取得します。
              <StyledOrderedList type="a">
                <StyledListText>本サービスのID</StyledListText>
                <StyledListText>アカウント登録情報</StyledListText>
                <StyledListText>
                  利用端末情報（IPアドレス、OSバージョン、cookie情報、機種情報等）
                </StyledListText>
                <StyledListText>
                  本サービスの利用履歴（アクセス日時等）
                </StyledListText>
              </StyledOrderedList>
            </StyledListText>
            <StyledListText>
              当社は、前項各号で定めた情報を以下の目的で利用します。
              <StyledOrderedList type="a">
                <StyledListText>
                  本サービスの各種施策の告知、その他当社の新サービスの告知のため
                </StyledListText>
                <StyledListText>本サービスの不正防止利用のため</StyledListText>
                <StyledListText>
                  ユーザーが本サービスを円滑に利用できるようにするため
                </StyledListText>
                <StyledListText>
                  当社が提供するサービス（本サービス以外のサービスも含みます）を改善充実させる、または新サービスを検討することを目的とした分析・抽出等を行うため
                </StyledListText>
                <StyledListText>
                  本サービス運営上の事務連絡のため
                </StyledListText>
                <StyledListText>
                  当社から当社または第三者の商品・サービス等に関するお知らせまたは広告を送信するため
                </StyledListText>
                <StyledListText>
                  ユーザーからのお問い合わせに対応および返答するため
                </StyledListText>
                <StyledListText>
                  その他上記各目的に準ずるか、これらに密接に関連する目的のため
                </StyledListText>
              </StyledOrderedList>
            </StyledListText>
            <StyledListText>
              当社は、第1項により取得したユーザーの情報から推測される興味、関心、年齢層等の特定の条件によってユーザーを分類し、この分類に関する情報（以下「セグメント」といいます。）を前項の目的に利用し、および電子チケットのコンテンツ提供会社（電子チケットで交換することができる商品または役務の提供会社）に対して提供することがあります。ユーザーは、かかる目的での当社の情報の利用、及びコンテンツ提供会社への提供について事前に同意するものとします。
            </StyledListText>
          </StyledOrderedList>
        </Box>
        <Box>
          <StyledListTitle variant="subtitle1">
            第１０条（禁止事項）
          </StyledListTitle>
          <StyledDivider />
          <StyledOrderedList>
            <StyledListText>
              当社は、ユーザーが、本サービスの利用にあたって、以下の行為を行うことを禁じます。ユーザーが禁止事項に該当すると当社が認める場合には、ユーザーへの何らの通知・催告なく、ユーザーの有するwalletポイント並びに交換された電子チケットのURLを無効化することができるものとします。
              <StyledOrderedList type="a">
                <StyledListText>
                  当社または第三者に損害を与える行為、または損害を与える恐れのある行為
                </StyledListText>
                <StyledListText>
                  当社または第三者の財産、名誉、プライバシー、著作権等の知的財産権等を侵害する行為、または侵害する恐れのある行為
                </StyledListText>
                <StyledListText>
                  当社または第三者に対し誹謗中傷、差別、脅迫、いやがらせその他の経済的・精神的損害または不利益を与える行為
                </StyledListText>
                <StyledListText>
                  公序良俗に反する行為、またはその恐れのある行為
                </StyledListText>
                <StyledListText>
                  当社または第三者に対し、虚偽の申告、届出を行う行為
                </StyledListText>
                <StyledListText>当社に成りすます行為</StyledListText>
                <StyledListText>
                  コンピュータウィルス等有害なプログラムを使用または提供する行為
                </StyledListText>
                <StyledListText>
                  迷惑メールやメールマガジン等を一方的に送付する行為
                </StyledListText>
                <StyledListText>
                  当社または第三者のサーバーに負担をかける行為、もしくは、本サービスの円滑な運営やシステム等に影響を与える行為
                </StyledListText>
                <StyledListText>
                  法令に違反する行為、またはその恐れがある行為
                </StyledListText>
                <StyledListText>
                  法令に違反する行為、またはその恐れがある行為を幇助、勧誘、強制、助長する行為
                </StyledListText>
                <StyledListText>
                  本サービス上で表示される画像、データ、情報等の全てについて、その有償無償や形態のいかんを問わず、事前に当社から書面による承諾を得ることなく、複製、転載、再配布等する行為
                </StyledListText>
                <StyledListText>
                  本サービスについて逆アセンブル、逆コンパイル、リバースエンジニアリングを行うこと、またその他の方法でソースコードを解読する行為
                </StyledListText>
                <StyledListText>
                  当社の承諾のない商業行為（営利、非営利目的を問わない）
                </StyledListText>
                <StyledListText>
                  その他当社が不適切と判断する行為
                </StyledListText>
              </StyledOrderedList>
            </StyledListText>
          </StyledOrderedList>
        </Box>
        <Box>
          <StyledListTitle variant="subtitle1">
            第１１条（損害賠償）
          </StyledListTitle>
          <StyledDivider />
          <StyledSingleItem>
            <StyledBodyText>
              ユーザーは、本規約に違反したことにより、当社その他の第三者に損害を及ぼした場合、当該損害を賠償する責任を負うものとします。
            </StyledBodyText>
          </StyledSingleItem>
        </Box>
        <Box>
          <StyledListTitle variant="subtitle1">
            第１２条（本サービスの中断・中止）
          </StyledListTitle>
          <StyledDivider />
          <StyledOrderedList>
            <StyledListText>
              当社は、次のいずれかに該当する場合、ユーザーへの予告なしに、本サービスの提供の全部または一部を中断・中止することができるものとします。
              <StyledOrderedList type="a">
                <StyledListText>
                  天災・事変（地震、津波等の天災、火災、ストライキ、洪水、感染症・疾病、暴動又は戦争行為等の非常事態を含むが、これらに限定されない）により本サービスの遂行が妨げられたとき
                </StyledListText>
                <StyledListText>
                  本サービスの用に供する建物、通信回線、電子計算機、サーバーその他の設備の保守・工事・法令その他の定めに基づく設備点検その他やむを得ない事情があるとき
                </StyledListText>
                <StyledListText>
                  電気通信事業者が提供する電気通信が中断・中止したとき、その他本サービスに供するコンピュータ・システム、オンラインサービス、回線等の障害が発生したとき
                </StyledListText>
                <StyledListText>
                  ユーザーが本規約に違反する行為をしたとき
                </StyledListText>
                <StyledListText>
                  本サービスに重大な障害が発生したとき
                </StyledListText>
                <StyledListText>
                  その他当社が運用上、技術上その他の理由でサービスの中断・中止が必要と判断したとき
                </StyledListText>
              </StyledOrderedList>
            </StyledListText>
            <StyledListText>
              前項に定める本サービスの停止及び中止により、ユーザーが本サービスを利用できなかったことに関し、当社は一切責任を負わないものとします。
            </StyledListText>
          </StyledOrderedList>
        </Box>
        <Box>
          <StyledListTitle variant="subtitle1">
            第１３条（本サービスの追加、変更）
          </StyledListTitle>
          <StyledDivider />
          <StyledOrderedList>
            <StyledListText>
              当社は、理由の如何を問わず、ユーザーへの予告なしに、本サービスの全部または一部を追加、変更、終了することができるものとします。
            </StyledListText>
            <StyledListText>
              前項に定める本サービスの追加、変更、終了により、ユーザーが本サービスを利用できなかったことに関し、当社は一切責任を負わないものとします。
            </StyledListText>
          </StyledOrderedList>
        </Box>
        <Box>
          <StyledListTitle variant="subtitle1">
            第１４条（免責及び責任の制限）
          </StyledListTitle>
          <StyledDivider />
          <StyledOrderedList>
            <StyledListText>
              当社は、本サービスおよび本サービスにおいて提供する広告または情報について、その内容の正確性、適法性及び完全性等を含め、いかなる明示または黙示の保証も行わず、また、それに起因する損害についても一切の責任を負わないものとします。
            </StyledListText>
            <StyledListText>
              当社は、当社に故意または重過失がある場合を除いて、本サービスの提供に起因してユーザーに生じたあらゆる損害について、一切の損害を負いません。当社の故意または重過失によってユーザーに損害が生じた場合、当社は、逸失利益その他の特別の事情によって生じた損害を賠償する責任を負わず、通常生ずべき損害の範囲内で損害賠償責任を負うものとします。前項にかかわらず、ユーザーと当社との間の契約が消費者契約法に定める消費者契約（以下、「消費者契約」といいます。）となる場合、当社は、当社の過失（重過失を除きます。）による債務不履行責任または不法行為責任については、逸失利益その他の特別の事情によって生じた損害を賠償する責任を負わず、通常生ずべき損害の範囲内で損害賠償責任を負うものとし、当社の重過失による債務不履行または不法行為責任については、相当因果関係の範囲内で損害賠償責任を負うものとします。
            </StyledListText>
            <StyledListText>
              交換可能な電子チケットの種類、内容及びwalletポイントとの交換レートは当社の裁量により設定し、変動する可能性があることをユーザーは了承し、当社は、特定の時点において、交換先に特定の電子チケットがあること及び交換レートの固定につき一切の保証をしないものとします。
            </StyledListText>
            <StyledListText>
              当社は本サービスのシステム等にエラー、バグ、不具合、中断その他の瑕疵がないこと、本サービスにコンピューターウィルス等の有害情報が含まれないこと、並びに本サービスの正確性、信頼性、完全性、適法性、非侵害性、有効性、目的適合性等につき一切の保証をしないものとします。
            </StyledListText>
          </StyledOrderedList>
        </Box>
        <Box>
          <StyledListTitle variant="subtitle1">
            第１５条（著作権等知的財産権）
          </StyledListTitle>
          <StyledDivider />
          <StyledSingleItem>
            <StyledBodyText>
              本サービスに関して当社がユーザーに提供する情報（映像、文章、写真、プログラム等を含みます。）に関する著作権その他の一切の知的財産権は、当社または当社に利用を許諾した権利者に帰属します。
            </StyledBodyText>
          </StyledSingleItem>
        </Box>
        <Box>
          <StyledListTitle variant="subtitle1">
            第１６条（個人情報の取扱い）
          </StyledListTitle>
          <StyledDivider />
          <StyledSingleItem>
            <StyledBodyText>
              当社は、本サービスの提供に際してユーザーから取得した個人情報を、当社の「プライバシーポリシー」に従い、適切に取り扱います。
            </StyledBodyText>
          </StyledSingleItem>
        </Box>
        <Box>
          <StyledListTitle variant="subtitle1">
            第１７条（権利の譲渡等）
          </StyledListTitle>
          <StyledDivider />
          <StyledSingleItem>
            <StyledBodyText>
              ユーザーは、本規約上の地位、本規約に基づく権利義務の全部または一部の、譲渡、貸与、担保設定その他一切の処分（有償無償を問いません。）を行ってはならないものとします。
            </StyledBodyText>
          </StyledSingleItem>
        </Box>
        <Box>
          <StyledListTitle variant="subtitle1">
            第１８条（本規約の変更）
          </StyledListTitle>
          <StyledDivider />
          <StyledSingleItem>
            <StyledBodyText>
              当社は、当社の判断により、ユーザーへの予告なく、本サービス上に表示することにより、いつでも任意の理由で本規約を変更することができるものとします。
            </StyledBodyText>
            <StyledBodyText>
              変更後の規約は、当社が別途定める場合を除いて、本サービス上に表示した時点より効力を生じるものとします。
            </StyledBodyText>
            <StyledBodyText>
              本規約の変更の効力が生じた後にユーザーが本サービスの利用を継続した場合、ユーザーは全ての規約変更に同意したものとみなします。
            </StyledBodyText>
          </StyledSingleItem>
        </Box>
        <Box>
          <StyledListTitle variant="subtitle1">
            第１９条（本規約の有効性）
          </StyledListTitle>
          <StyledDivider />
          <StyledSingleItem>
            <StyledBodyText>
              本規約の一部が無効となる場合についても、本規約のその他の規定は有効とします。
              本規約の一部が、一部ユーザーとの間で無効となる場合についても、本規約はその他のユーザーとの間では有効とします。
            </StyledBodyText>
          </StyledSingleItem>
        </Box>
        <Box>
          <StyledListTitle variant="subtitle1">
            第２０条（準拠法）
          </StyledListTitle>
          <StyledDivider />
          <StyledSingleItem>
            <StyledBodyText>
              本規約及びこれに関するすべての法律関係については、日本国法を準拠法とし、日本国法に従って解釈されるものとします。
            </StyledBodyText>
          </StyledSingleItem>
        </Box>
        <Box>
          <StyledListTitle variant="subtitle1">
            第２１条（管轄裁判所）
          </StyledListTitle>
          <StyledDivider />
          <StyledSingleItem>
            <StyledBodyText>
              本規約及びこれに関して生じた一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
            </StyledBodyText>
          </StyledSingleItem>
        </Box>
        <Box>
          <StyledListTitle variant="subtitle1">別紙</StyledListTitle>
          <StyledDivider />
          <StyledSingleItem>
            <StyledBodyText>
              当社が提供する本サービスの種類、内容その他の詳細は以下のとおりとします。
            </StyledBodyText>
            <StyledOrderedList>
              <StyledListText>
                サービスの内容
                <StyledUnorderedList>
                  <li>
                    gift wallet IDサービス
                    <StyledOrderedList type="a">
                      <li>
                        gift walletのアカウントに紐づけた電子チケットの保存
                      </li>
                      <li>
                        ユーザーが保有する電子チケットのwalletポイントへの交換
                      </li>
                      <li>
                        保存された電子チケット及びwalletポイントの有効期限やその他の通知
                      </li>
                      <li>walletポイントと電子チケットの交換</li>
                    </StyledOrderedList>
                  </li>
                </StyledUnorderedList>
              </StyledListText>
              <StyledListText>
                当社におけるサービス窓口（連絡先）
                <StyledUnorderedList>
                  <li>
                    <Link
                      to={PATHS.INQUIRIES_NEW}
                      data-cy="inquiriesNewPageLink"
                    >
                      gift wallet 問い合わせフォーム
                    </Link>
                  </li>
                </StyledUnorderedList>
              </StyledListText>
              <StyledListText>
                サービス窓口の対応時間
                <StyledUnorderedList>
                  <li>
                    当社営業日（祝日及び年末年始を除く月曜日～金曜日）１０時から１８時３０分まで
                  </li>
                </StyledUnorderedList>
              </StyledListText>
            </StyledOrderedList>
          </StyledSingleItem>
        </Box>
        <StyledBodyText>2024年8月19日ver.2.0.0改定</StyledBodyText>
      </Grid>
    </StyledContainer>
  </MainLayout>
);

export { Presenter };
