import React from 'react';
import {
  Typography,
  Container,
  Box,
  Divider,
  Grid2 as Grid,
  Button,
  ButtonProps,
} from '@mui/material';
import { styled } from '@mui/system';
import { AvailableMyGifts } from './availableMyGifts/Index';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { PATHS } from '/@/routes/paths';
import { Link } from 'react-router-dom';
import { SelectedMyGiftsState } from '/@/store/app/selectedMyGifts';
import { DiscardConfirmationModal } from './discardConfirmationModal/Index';

const StyledBackground = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));
const StyledBaseBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    borderRadius: theme.spacing(2),
  },
  backgroundColor: theme.palette.background.default,
  padding: 0,
}));

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  padding: theme.spacing(2),
  fontWeight: 'bold',
  lineHeight: '1',
}));
type StyledButtonProps = ButtonProps & { to: string };
const StyledMyGiftButton = styled(Button)<StyledButtonProps>(({ theme }) => ({
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  margin: theme.spacing(2),
  backgroundColor: theme.palette.background.hint,
}));

const StyledMyGiftButtonLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontWeight: 'bold',
}));

const StyledDiscardGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const StyledSelectedCount = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
}));

const StyledDiscardButtonLabel = styled(Typography)({
  fontWeight: 'bold',
});

const StyledDiscardButtonIcon = styled(ArrowForwardIosIcon)(({ theme }) => ({
  paddingLeft: theme.spacing(),
}));

type Props = {
  selectedMyGifts: SelectedMyGiftsState;
  handleMyGiftsDiscardConfirmationModalOpen: () => void;
  isDiscardButtonDisabled: boolean;
};

const Presenter: React.VFC<Props> = ({
  selectedMyGifts,
  handleMyGiftsDiscardConfirmationModalOpen,
  isDiscardButtonDisabled,
}) => (
  <MainLayout>
    <StyledBackground>
      <StyledBaseBox>
        <StyledContainer maxWidth="sm">
          <Grid container alignItems="center" justifyContent="space-between">
            <StyledSubtitle variant="subtitle1">マイギフト選択</StyledSubtitle>
            <StyledMyGiftButton
              variant="contained"
              disableElevation
              component={Link}
              to={PATHS.HOME}
              data-cy="giftsPageButton"
            >
              <StyledMyGiftButtonLabel variant="caption">
                マイギフトに戻る
              </StyledMyGiftButtonLabel>
            </StyledMyGiftButton>
          </Grid>
          <Divider />
          <StyledDiscardGrid
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid>
              <Grid container alignItems="center">
                <Typography variant="caption">選択中：</Typography>
                <StyledSelectedCount
                  data-cy="selectedCount"
                  variant="subtitle1"
                >
                  {selectedMyGifts.length}
                </StyledSelectedCount>
              </Grid>
            </Grid>
            <Grid>
              <Button
                onClick={handleMyGiftsDiscardConfirmationModalOpen}
                disableElevation
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                disabled={isDiscardButtonDisabled}
                data-cy="discardButton"
                data-gtm-click="giftsPage-editPage-discardButton"
              >
                <Grid container alignItems="center">
                  <StyledDiscardButtonLabel variant="caption">
                    利用済みにする
                  </StyledDiscardButtonLabel>
                  <StyledDiscardButtonIcon />
                </Grid>
              </Button>
            </Grid>
          </StyledDiscardGrid>
          <Divider />
          {/* <Grid container alignItems='center' className={classes.sortGrid}>
            <SortIcon color='primary' />
            <Typography variant='caption' className={classes.sortLabel}>
              保存日の新しい順
            </Typography>
          </Grid> */}
          <AvailableMyGifts />
        </StyledContainer>
      </StyledBaseBox>
    </StyledBackground>
    <DiscardConfirmationModal
      handleMyGiftsDiscardConfirmationModalOpen={
        handleMyGiftsDiscardConfirmationModalOpen
      }
    />
  </MainLayout>
);

export { Presenter };
