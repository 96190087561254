import React from 'react';
import { Typography, Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/system';

const StyledHeading = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(3),
}));
const StyledHeadingTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
}));
const StyledHeadingSubtitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
  padding: theme.spacing(1),
}));

type Props = {
  title: string;
  subTitle: string;
};

const Presenter: React.VFC<Props> = ({ title, subTitle }) => (
  <StyledHeading>
    <StyledHeadingTitle align="center" variant="h4">
      {title}
    </StyledHeadingTitle>
    <StyledHeadingSubtitle align="center" variant="subtitle1">
      {subTitle}
    </StyledHeadingSubtitle>
  </StyledHeading>
);

export { Presenter };
