import { createGiftConvertToPoint } from '/@/api/graphql/internalApi/mutations/createGiftConvertToPoint/index';
import type { CreateGiftConvertToPointResponse } from '/@/api/graphql/internalApi/mutations/createGiftConvertToPoint/index';
import type { AppThunk } from '/@/store';
import {
  createGiftConvertToPointInit,
  createGiftConvertToPointStart,
  createGiftConvertToPointSuccess,
  createGiftConvertToPointFailure,
} from './actions';
import { handleErrorThunk } from '../../handleError';
import type { ApiError } from '/@/api/graphql/client';
import { CreateGiftConvertToPointMutationVariables } from '/@/api/graphql/internalApi/types';

const createGiftConvertToPointInitThunk =
  (): AppThunk<Promise<void>> => (dispatch) => {
    dispatch(createGiftConvertToPointInit());
    return Promise.resolve();
  };

const createGiftConvertToPointThunk =
  (input: CreateGiftConvertToPointMutationVariables): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(createGiftConvertToPointStart());
    return createGiftConvertToPoint(input)
      .then((response) =>
        dispatch(createGiftConvertToPointSuccessThunk(response)),
      )
      .catch((error) => dispatch(createGiftConvertToPointFailureThunk(error)));
  };

const createGiftConvertToPointSuccessThunk =
  (response: CreateGiftConvertToPointResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const giftConvertToPointRequest =
      response.data!.createGiftConvertToPointRequest.giftConvertToPointRequest!;
    dispatch(createGiftConvertToPointSuccess(giftConvertToPointRequest));
    return Promise.resolve();
  };

const createGiftConvertToPointFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(createGiftConvertToPointFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { createGiftConvertToPointInitThunk, createGiftConvertToPointThunk };
