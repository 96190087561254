import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { MyGift } from '/@/components/shared/myGift/Index';
import { GroupedUnavailableMyGifts } from '/@/store/app/unavailableMyGiftsConnection/selectors';
const StyledUnavailableGiftsContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  paddingBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.hint,
}));
const StyledGroupedLabelText = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  marginTop: theme.spacing(3),
  fontWeight: 'bold',
  lineHeight: '1',
  color: theme.palette.secondary.dark,
}));
const StyledMyGiftCard = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  borderRadius: theme.spacing(1.5),
  boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.1)',
  overflow: 'hidden',
}));
const StyledUnavailableMyGiftsNotFoundContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
}));
const StyledUnavailableMyGiftsNotFoundText = styled(Typography)(
  ({ theme }) => ({
    textAlign: 'center',
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
    marginTop: theme.spacing(4),
  }),
);

type Props = {
  groupedUnavailableMyGifts: GroupedUnavailableMyGifts;
  infiniteScrollTarget: React.RefObject<HTMLDivElement>;
  getUnavailableMyGiftsConnectionStatus: Status;
};

const Presenter: React.VFC<Props> = ({
  groupedUnavailableMyGifts,
  infiniteScrollTarget,
  getUnavailableMyGiftsConnectionStatus,
}) => (
  <Grid data-cy="unavailableMyGifts">
    {Object.keys(groupedUnavailableMyGifts).length ? (
      <StyledUnavailableGiftsContainer>
        {Object.keys(groupedUnavailableMyGifts).map((date) => (
          <React.Fragment key={date}>
            <StyledGroupedLabelText variant="caption">
              {date}
            </StyledGroupedLabelText>
            {groupedUnavailableMyGifts[date]?.map(
              (myGift) =>
                myGift && (
                  <StyledMyGiftCard key={myGift.urlCode}>
                    <MyGift myGift={myGift} />
                  </StyledMyGiftCard>
                ),
            )}
          </React.Fragment>
        ))}
      </StyledUnavailableGiftsContainer>
    ) : (
      <StyledUnavailableMyGiftsNotFoundContainer
        container
        justifyContent="center"
        direction="column"
      >
        <StyledUnavailableMyGiftsNotFoundText variant="body2">
          現在利用済み・期限切れのギフトはありません。
        </StyledUnavailableMyGiftsNotFoundText>
      </StyledUnavailableMyGiftsNotFoundContainer>
    )}
    {getUnavailableMyGiftsConnectionStatus === STATUS.LOADING && (
      <PartialLoader />
    )}
    <div ref={infiniteScrollTarget} />
  </Grid>
);

export { Presenter };
