import React from 'react';
import { Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { AnnouncementsList } from '/@/components/shared/announcementsList/Index';
import { STATUS, Status } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { UserAnnouncementTypeCategoryUidEnumType } from '/@/api/graphql/internalApi/types';

const StyledAnnouncementsContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(0) + ' !important',
  width: '100%',
  textAlign: 'center',
}));
const StyledTopContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
}));
const StyledTopText = styled(Typography)(({ theme }) => ({
  fontSize: '0.6875rem',
  color: theme.palette.grey[400],
}));
const StyledReadAnnouncementsButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '0.75rem',
  fontWeight: 'bold',
  textTransform: 'none',
  padding: theme.spacing(0),
  lineHeight: '1',
  height: 'fit-content',
}));
const StyledNoAnnouncementTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(3),
  fontSize: '1rem',
  lineHeight: '1.6',
  fontWeight: 'bold',
}));
const StyledNoAnnouncementText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  fontSize: '0.875rem',
  lineHeight: '1.4',
  //改行を反映させるため
  whiteSpace: 'pre-line',
}));

type Props = {
  headerTitle: string;
  handleReadAllAnnouncements: () => void;
  readAnnouncementsStatus: Status;
  isDisabledReadButton: boolean;
  isNoAnnouncement: boolean;
  noAnnouncementText: string;
  userAnnouncementTypeCategory: UserAnnouncementTypeCategoryUidEnumType;
};

const Presenter: React.VFC<Props> = ({
  headerTitle,
  handleReadAllAnnouncements,
  readAnnouncementsStatus,
  isDisabledReadButton,
  isNoAnnouncement,
  noAnnouncementText,
  userAnnouncementTypeCategory,
}) => (
  <MainLayout headerTitle={headerTitle}>
    <StyledAnnouncementsContainer>
      {isNoAnnouncement ? (
        <>
          <StyledNoAnnouncementTitle>
            現在お知らせはありません
          </StyledNoAnnouncementTitle>
          <StyledNoAnnouncementText>
            {noAnnouncementText}
          </StyledNoAnnouncementText>
        </>
      ) : (
        <StyledTopContainer>
          <StyledTopText variant="h4">
            直近180日以内のお知らせを表示しています
          </StyledTopText>
          {readAnnouncementsStatus === STATUS.LOADING ? (
            <PartialLoader />
          ) : (
            <StyledReadAnnouncementsButton
              variant="text"
              color="primary"
              onClick={handleReadAllAnnouncements}
              disabled={isDisabledReadButton}
            >
              全て既読にする
            </StyledReadAnnouncementsButton>
          )}
        </StyledTopContainer>
      )}
      <AnnouncementsList
        userAnnouncementTypeCategory={[userAnnouncementTypeCategory]}
      />
    </StyledAnnouncementsContainer>
  </MainLayout>
);

export { Presenter };
