import React from 'react';
import { Grid2 as Grid, Typography, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { PointLog } from '/@/components/shared/pointLog/Index';
import { GroupedAvailablePointCharges } from '/@/store/page/pointLogsPage/availablePointChargesPage/selectors';
import { formatToEndDateJa } from '/@/utils/formatDate';

const StyledGroupedLabel = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.hint,
  paddingTop: theme.spacing(),
  paddingBottom: theme.spacing(),
  paddingLeft: theme.spacing(2),
}));
const StyledGroupedLabelText = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.secondary.main,
}));

type Props = {
  infiniteScrollTarget: React.RefObject<HTMLDivElement>;
  groupedAvailablePointChargesByMonth: GroupedAvailablePointCharges;
  getAvailablePointChargesStatus: Status;
};

const Presenter: React.VFC<Props> = ({
  infiniteScrollTarget,
  groupedAvailablePointChargesByMonth,
  getAvailablePointChargesStatus,
}) => (
  <Grid data-cy="availablePointCharges">
    {getAvailablePointChargesStatus == STATUS.LOADING ? (
      <PartialLoader />
    ) : (
      <>
        {Object.entries(groupedAvailablePointChargesByMonth).map(
          ([month, availablePointCharges]) => (
            <React.Fragment key={month}>
              <StyledGroupedLabel>
                <StyledGroupedLabelText variant="caption">
                  {month}
                </StyledGroupedLabelText>
              </StyledGroupedLabel>
              <Divider />
              <Grid>
                {availablePointCharges.map((availablePointCharge, index) => {
                  const title = `${formatToEndDateJa(
                    availablePointCharge.withdrawableUntilAt,
                  )}まで有効`;
                  return (
                    <React.Fragment key={index}>
                      <PointLog
                        title={title}
                        point={availablePointCharge.remainingAmount}
                      />
                      <Divider />
                    </React.Fragment>
                  );
                })}
              </Grid>
            </React.Fragment>
          ),
        )}
      </>
    )}
    <div ref={infiniteScrollTarget} />
  </Grid>
);

export { Presenter };
