import React from 'react';
import { BaseLabel } from '../baseLabel';
import { LabelSizeTypes } from '../baseLabel/Presenter';
import { theme } from '/@/utils/theme';

type Props = {
  labelSize: LabelSizeTypes;
};

const Presenter: React.VFC<Props> = ({ labelSize }) => (
  <BaseLabel
    labelText={'限定'}
    labelSize={labelSize}
    color={theme.palette.common.white}
    backgroundColor={theme.palette.error.main}
  />
);

export { Presenter };
