import { FetchResult } from '@apollo/client';
import { internalApiClient } from '/@/api/graphql/appApolloClient';
import { getGifteeContentGql } from './index.gql';
import {
  GetGifteeContentQuery,
  QueryGifteeContentArgs,
} from '/@/api/graphql/internalApi/types';

type GetGifteeContentResponse = FetchResult<GetGifteeContentQuery>;

const getGifteeContent = (
  variables: QueryGifteeContentArgs,
): Promise<GetGifteeContentResponse> => {
  return internalApiClient.query({
    query: getGifteeContentGql,
    variables,
  });
};

export { getGifteeContent };

export type { GetGifteeContentResponse };
