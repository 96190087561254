import React from 'react';
import { Card, CardActionArea, Typography, Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/system';
import { PromotionSpotsState } from '/@/store/app/promotionSpots';

const REWARD_SIZE = 70;
const PROMOTER_SIZE = 40;
const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));
const StyledPromotionWrapper = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(),
}));
const StyledPromotionTag = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  borderBottomRightRadius: theme.spacing(2),
  padding: theme.spacing(),
  backgroundColor: theme.palette.background.contrast,
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
}));
const StyledRewardCardSection = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
}));
const StyledRewardImage = styled('img')(({ theme }) => ({
  height: REWARD_SIZE,
  width: REWARD_SIZE,
  marginRight: theme.spacing(),
  borderRadius: theme.spacing(),
}));
const StyledRewardName = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
}));
const StyledPromoterCardSection = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.hint,
}));
const StyledPromoterImage = styled('img')(({ theme }) => ({
  height: PROMOTER_SIZE,
  width: PROMOTER_SIZE,
  marginRight: theme.spacing(),
  borderRadius: theme.spacing(1),
}));
const StyledPromoterName = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
}));
const StyledPromotionDescription = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(),
}));

type Props = {
  promotionSpot: NonNullable<PromotionSpotsState>[number];
};

const Presenter: React.VFC<Props> = ({ promotionSpot }) => (
  <StyledCard>
    <CardActionArea
      href={promotionSpot.promotionPublishment.url}
      role="link"
      data-promotion-name={promotionSpot.promotionPublishment.promotion.name}
      data-promotion-spot-name={promotionSpot.name}
    >
      <StyledPromotionWrapper>
        <StyledPromotionTag color="primary" variant="caption" display="inline">
          {promotionSpot.promotionPublishment.promotion.promotionTag.name}
        </StyledPromotionTag>
      </StyledPromotionWrapper>
      <StyledRewardCardSection container wrap="nowrap">
        <StyledRewardImage
          src={promotionSpot.promotionPublishment.promotion.reward.imageUrl}
          alt={promotionSpot.promotionPublishment.promotion.reward.name}
        />
        <StyledRewardName variant="body1">
          {promotionSpot.promotionPublishment.promotion.reward.name}
        </StyledRewardName>
      </StyledRewardCardSection>
      <StyledPromoterCardSection container wrap="nowrap">
        <StyledPromoterImage
          src={promotionSpot.promotionPublishment.promotion.promoter.imageUrl}
          alt={promotionSpot.promotionPublishment.promotion.promoter.name}
        />
        <Grid container direction="column">
          <StyledPromoterName variant="body2">
            {promotionSpot.promotionPublishment.promotion.name}
          </StyledPromoterName>
          <Typography color="textSecondary" variant="caption">
            {promotionSpot.promotionPublishment.promotion.promoter.name}
          </Typography>
          <StyledPromotionDescription color="textSecondary" variant="caption">
            {promotionSpot.promotionPublishment.promotion.description}
          </StyledPromotionDescription>
        </Grid>
      </StyledPromoterCardSection>
    </CardActionArea>
  </StyledCard>
);

export { Presenter };
