import React from 'react';
import { Presenter } from './Presenter';
import { useDataLayer } from '/@/common/hooks/useDataLayer';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '/@/store';

type OwnProps = {
  headerTitle?: string;
  isLogoClickable: boolean;
  isMenuVisible: boolean;
  isMenuOpened: boolean;
  handleToggleMenu: () => void;
};

const Container: React.VFC<OwnProps> = ({
  headerTitle,
  isLogoClickable,
  isMenuVisible,
  isMenuOpened,
  handleToggleMenu,
}) => {
  const { pushClickEvent } = useDataLayer();

  const handleClickGlobalMenu = () => {
    pushClickEvent({
      clickItem: 'clickGlobalMenu',
      customData: {
        clickItem: 'clickGlobalMenu',
      },
    });
  };

  const navigate = useNavigate();
  const handleNavigateBack = () => {
    navigate(-1); // 1つ前の画面に戻る
  };

  const unreadAnnouncementsCount = useAppSelector(
    (state) => state.app.currentUser?.unreadAnnouncementsCountByCategoryUid,
  );

  return (
    <Presenter
      headerTitle={headerTitle}
      isLogoClickable={isLogoClickable}
      isMenuVisible={isMenuVisible}
      isMenuOpened={isMenuOpened}
      handleToggleMenu={handleToggleMenu}
      handleClickGlobalMenu={handleClickGlobalMenu}
      handleNavigateBack={handleNavigateBack}
      unreadAnnouncementsCount={unreadAnnouncementsCount}
    />
  );
};

export { Container as GlobalHeader };
