import React, { useEffect, useState } from 'react';
import { Presenter } from './Presenter';
import { useAppDispatch, useAppSelector } from '/@/store';
import { getRecommendedBrandsConnectionThunk } from '/@/store/api/queries/getBrandsConnection';
import { getPointWalletThunk } from '/@/store/api/queries/getPointWallet';
import { RecommendedBrandsState } from '/@/store/app/recommendedBrandsConnection';
import { STATUS } from '/@/store/api/constants';

const Container: React.VFC = () => {
  const dispatch = useAppDispatch();
  const brands = useAppSelector(
    (state) => state.app.recommendedBrandsConnection.brands,
  );
  const isDisplayOnlyExchangeableBrands = useAppSelector(
    (state) => state.app.displayOnlyExchangeableBrands,
  );
  const [displayBrands, setDisplayBrands] =
    useState<RecommendedBrandsState>(brands);
  const myPoint = useAppSelector((state) => state.app.pointWallet?.point);
  const getPointWalletStatus = useAppSelector(
    (state) => state.api.getPointWallet.status,
  );
  const getRecommendedBrandsStatus = useAppSelector(
    (state) => state.app.recommendedBrandsConnection.status,
  );

  // 運用でおすすめブランドは6件程度に絞るのでページネーションしない代わりに安全のためfirstで多めに指定する
  useEffect(() => {
    if (getRecommendedBrandsStatus === STATUS.IDLE) {
      dispatch(getRecommendedBrandsConnectionThunk()).catch(() => {});
    }
  }, [dispatch, getRecommendedBrandsStatus]);

  useEffect(() => {
    if (myPoint == null && getPointWalletStatus === STATUS.IDLE) {
      dispatch(getPointWalletThunk()).catch(() => {});
    }
  }, [dispatch, myPoint, getPointWalletStatus]);

  useEffect(() => {
    if (isDisplayOnlyExchangeableBrands && brands && myPoint !== undefined) {
      const exchangeableBrands = brands.filter(
        (brand) => brand?.minPoint && myPoint >= brand.minPoint,
      );
      setDisplayBrands(exchangeableBrands);
    } else {
      setDisplayBrands(brands);
    }
  }, [isDisplayOnlyExchangeableBrands, brands, myPoint]);

  return (
    <Presenter
      brands={displayBrands}
      getRecommendedBrandsStatus={getRecommendedBrandsStatus}
    />
  );
};

export { Container as RecommendedContents };
