import React from 'react';
import {
  Button,
  ButtonProps,
  Card,
  Grid2 as Grid,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { formatToEndDateJa } from '/@/utils/formatDate';
import { PointChargeModal } from '/@/components/shared/modal/pointChargeModal/Index';
import { GetMyGiftQuery } from '/@/api/graphql/internalApi/types';

const StyledMyGiftCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3, 2),
  borderRadius: theme.spacing(2),
}));
const StyledLogoAndTitle = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  height: '100%',
  width: '100%',
});
const StyledLogoContainer = styled('div')(({ theme }) => ({
  width: theme.spacing(8),
  height: theme.spacing(8),
  marginBottom: 'auto',
  borderRadius: theme.spacing(1),
}));
const StyledLogo = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});
const StyledRightContent = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(1),
  width: '-webkit-fill-available',
}));
const StyledPointChargeableText = styled(Typography)(({ theme }) => ({
  fontSize: '0.625rem',
  lineHeight: '0.625rem',
  fontWeight: 'bold',
  color: theme.palette.success.main,
}));
const StyledNotChargeableText = styled(Typography)(({ theme }) => ({
  fontSize: '0.625rem',
  lineHeight: '0.625rem',
  fontWeight: 'bold',
  color: theme.palette.grey.A200,
}));
const StyledItemName = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  fontSize: '0.875rem',
  lineHeight: '1.4rem',
  fontWeight: 'bold',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  textOverflow: 'ellipsis',
}));
const StyledRemainingPoint = styled('span')({
  fontWeight: 'normal',
  fontSize: '0.75rem',
  lineHeight: '1.2rem',
});
const StyledEndAt = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  fontSize: '0.625rem',
  lineHeight: '0.625rem',
  fontWeight: 'bold',
  color: theme.palette.grey.A700,
}));
const StyledButtonsContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));
type StyledPointChargeButtonProps = ButtonProps & { isPointMergeable: boolean };
const StyledPointChargeButton = styled(Button)<StyledPointChargeButtonProps>(
  ({ isPointMergeable, theme }) => ({
    width: '100%',
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 4),
    borderRadius: theme.spacing(4),
    boxShadow: 'none',
    ...(!isPointMergeable && {
      color: theme.palette.common.white + ' !important',
      backgroundColor: theme.palette.grey.A200 + ' !important',
    }),
  }),
);
type StyledUsingGiftButtonProps = ButtonProps & { to: string };
const StyledUsingGiftButton = styled(Button)<StyledUsingGiftButtonProps>(
  ({ theme }) => ({
    width: '100%',
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
    fontWeight: 'bold',
    padding: theme.spacing(2, 4),
    borderRadius: theme.spacing(4),
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: 'none',
  }),
);

type Props = {
  isOpenModal: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  myGift: GetMyGiftQuery['myGift'];
  expiredAtLabel: string;
};
const Presenter: React.VFC<Props> = ({
  isOpenModal,
  handleOpenModal,
  handleCloseModal,
  myGift,
  expiredAtLabel,
}) => (
  <StyledMyGiftCard variant="outlined">
    <StyledLogoAndTitle>
      <StyledLogoContainer>
        <StyledLogo src={myGift.imageUrl} alt={myGift.contentName} />
      </StyledLogoContainer>
      <StyledRightContent>
        {myGift.pointMergeable ? (
          <StyledPointChargeableText>
            ポイントに移行可能なギフト
          </StyledPointChargeableText>
        ) : (
          <StyledNotChargeableText>ポイント移行不可</StyledNotChargeableText>
        )}
        <StyledItemName>
          {myGift.contentName}
          {myGift.remainingPoint ? (
            <StyledRemainingPoint>{`（${myGift.remainingPoint.toLocaleString('ja-JP')}ポイント）`}</StyledRemainingPoint>
          ) : (
            ''
          )}
        </StyledItemName>
        <StyledEndAt>
          {expiredAtLabel +
            '期限 ' +
            (myGift.expiredAt
              ? formatToEndDateJa(myGift.expiredAt)
              : '取得中...')}
        </StyledEndAt>
      </StyledRightContent>
    </StyledLogoAndTitle>
    <StyledButtonsContainer container direction="column" alignContent="center">
      <StyledPointChargeButton
        variant="contained"
        color="primary"
        disableElevation
        isPointMergeable={myGift.pointMergeable}
        onClick={handleOpenModal}
        disabled={myGift.remainingPoint === 0}
        data-cy="pointChargeButton"
        data-gtm-click="pointPage-chargePage-pointChargeSection-chargeableGift-pointChargeButton"
      >
        {myGift.pointMergeable && myGift.remainingPoint > 0
          ? 'ギフトをポイントに移行する'
          : 'ポイント移行不可'}
      </StyledPointChargeButton>
      <StyledUsingGiftButton
        variant="contained"
        disableElevation
        component={Link}
        to={myGift.giftUrl}
        data-cy="usingGiftButton"
      >
        ギフトを利用する
      </StyledUsingGiftButton>
    </StyledButtonsContainer>
    {isOpenModal && <PointChargeModal handleClose={handleCloseModal} />}
  </StyledMyGiftCard>
);

export { Presenter };
