import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import '/@/assets/font/font.css';
import { FONT_SIZE, FontSize } from './Index';

type StyledPointTextProps = {
  fontSize: FontSize;
};
const StyledPointText = styled(Typography)<StyledPointTextProps>(
  ({ fontSize, theme }) => ({
    fontSize:
      fontSize === FONT_SIZE.LG
        ? '0.875rem'
        : fontSize === FONT_SIZE.XL
          ? '1rem'
          : '0.625rem',
    lineHeight: '1.6',
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
  }),
);
type StyledPointsProps = {
  fontSize: FontSize;
};
const StyledPoints = styled('span')<StyledPointsProps>(({ fontSize }) => ({
  fontSize:
    fontSize === FONT_SIZE.LG
      ? '1.75rem'
      : fontSize === FONT_SIZE.XL
        ? '2rem'
        : '1.25rem',
  fontFamily: 'mamelon',
  lineHeight: '1',
  fontWeight: 400,
}));

type Props = {
  points: number | null | undefined;
  fontSize: FontSize;
  isMinus: boolean;
};

const Presenter: React.VFC<Props> = ({ points, fontSize, isMinus }) => (
  <StyledPointText fontSize={fontSize}>
    <StyledPoints fontSize={fontSize}>
      {typeof points === 'number'
        ? (isMinus ? '-' : '') + points.toLocaleString('ja-JP')
        : '-'}
    </StyledPoints>
    {' ポイント'}
  </StyledPointText>
);

export { Presenter };
