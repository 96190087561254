import { types } from './types';
import type { GetAnnouncementsConnectionQuery } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const getAnnouncementsConnectionStart = () =>
  ({
    type: types.GET_ANNOUNCEMENTS_CONNECTION_START,
  }) as const;

export const getAnnouncementsConnectionSuccess = (
  announcementsConnection: GetAnnouncementsConnectionQuery['userAnnouncements'],
) =>
  ({
    type: types.GET_ANNOUNCEMENTS_CONNECTION_SUCCESS,
    announcementsConnection,
  }) as const;

export const getAnnouncementsConnectionFailure = (error: ApiError) =>
  ({
    type: types.GET_ANNOUNCEMENTS_CONNECTION_FAILURE,
    error,
  }) as const;
