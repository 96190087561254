import { getPointLogs } from '/@/api/graphql/internalApi/queries/getPointLogs/index';
import type { GetPointLogsResponse } from '/@/api/graphql/internalApi/queries/getPointLogs/index';
import type { AppThunk } from '/@/store';
import {
  getPointLogsStart,
  getPointLogsSuccess,
  getPointLogsFailure,
} from './actions';
import type { GetPointLogsQueryVariables } from '/@/api/graphql/internalApi/types';
import { handleErrorThunk } from '../../handleError';
import type { ApiError } from '/@/api/graphql/client';

const getPointLogsThunk =
  (variables: GetPointLogsQueryVariables = {}): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getPointLogsStart());

    return getPointLogs(variables)
      .then((response) => dispatch(getPointLogsSuccessThunk(response)))
      .catch((error) => dispatch(getPointLogsFailureThunk(error)));
  };

const getPointLogsSuccessThunk =
  (response: GetPointLogsResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { pointWallet } = response.data!;
    dispatch(getPointLogsSuccess(pointWallet.pointLogs));
    return Promise.resolve();
  };

const getPointLogsFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getPointLogsFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { getPointLogsThunk };
