import React from 'react';
import { Typography, Grid2 as Grid, Button } from '@mui/material';
import { styled } from '@mui/system';
import { LoadingButton } from '/@/components/shared/button/loadingButton/Index';
import { Modal } from '/@/components/shared/modal/Index';
import { FONT_SIZE, Points } from '../../points/Index';

const StyledMessageText = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '1rem',
  lineHeight: '1.3375rem',
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
});
const StyledPointTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  fontSize: '0.75rem',
  fontWeight: 'bold',
  color: theme.palette.grey[700],
}));
const StyledSecondaryAttention = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(1),
  textAlign: 'left',
  fontSize: '0.7rem',
  color: theme.palette.secondary.main,
}));
const StyledExchangeSubmitButton = styled(LoadingButton)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1.875),
  fontWeight: 'bold',
  lineHeight: '1',
  borderRadius: theme.spacing(2.75),
  boxShadow: 'none',
}));
const StyledCancelButton = styled(Button)(({ theme }) => ({
  fontWeight: 'bold',
  borderRadius: theme.spacing(2.75),
  padding: theme.spacing(1.625),
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  lineHeight: '1',
  border: `2px solid ${theme.palette.primary.main}`,
  boxShadow: 'none',
}));

type Props = {
  cancelCharge: () => void;
  handleSubmit: () => void;
  pointAmount: number | undefined;
  isLoading: boolean;
};
const Presenter: React.VFC<Props> = ({
  cancelCharge,
  handleSubmit,
  pointAmount,
  isLoading,
}) => (
  <Modal handleClose={cancelCharge} data-cy="pointChargeModal">
    <Grid container direction="column" justifyContent="center">
      <StyledMessageText>
        選択したギフトを
        <br />
        ポイント移行しますか？
      </StyledMessageText>
      <StyledPointTitle>移行されるポイント</StyledPointTitle>
      <Points points={pointAmount} fontSize={FONT_SIZE.LG} />
      <StyledSecondaryAttention>
        ※ ポイント移行したギフトは元に戻せません。
        <br />※
        交換対象の商品はご希望に沿えない場合がございます。ラインナップは順次拡大していく予定です。
      </StyledSecondaryAttention>
      <StyledExchangeSubmitButton
        variant="contained"
        color="primary"
        loading={isLoading}
        onClick={handleSubmit}
        disabled={!pointAmount || isLoading}
        data-gtm-click="shared-modal-pointChargeModal-submitButton"
      >
        移行する
      </StyledExchangeSubmitButton>
      <StyledCancelButton
        variant="contained"
        onClick={cancelCharge}
        data-gtm-click="shared-modal-pointChargeModal-cancelButton"
      >
        キャンセル
      </StyledCancelButton>
    </Grid>
  </Modal>
);

export { Presenter };
