import React from 'react';
import {
  Grid2 as Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { styled } from '@mui/system';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { MyGift } from '/@/components/shared/myGift/Index';
import { GroupedAvailableMyGifts } from '/@/store/app/availableMyGiftsConnection/selectors';
import { MyGiftBase } from '/@/store/app/shared/types';

const StyledEditGiftsContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  paddingBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.hint,
}));
const StyledGroupedLabelText = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  marginTop: theme.spacing(3),
  fontWeight: 'bold',
  lineHeight: '1',
  color: theme.palette.secondary.dark,
}));
const StyledEditableMyGiftForm = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  borderRadius: theme.spacing(1.5),
  boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.1)',
  overflow: 'hidden',
}));
const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '&.MuiFormControlLabel-root': {
    width: '100%',
    margin: '0',
    padding: 0,
    backgroundColor: theme.palette.common.white,
  },
  '&.MuiFormControlLabel-label': {
    minWidth: '0',
    width: '100%',
    pointerEvents: 'none',
  },
}));

type Props = {
  groupedAvailableMyGifts: GroupedAvailableMyGifts;
  infiniteScrollTarget: React.RefObject<HTMLDivElement>;
  getAvailableMyGiftsConnectionStatus: Status;
  handleEditingMyGift: (myGift: MyGiftBase, checked: boolean) => void;
  isSelectedMyGift: (myGift: MyGiftBase) => boolean;
};

const Presenter: React.VFC<Props> = ({
  groupedAvailableMyGifts,
  infiniteScrollTarget,
  getAvailableMyGiftsConnectionStatus,
  handleEditingMyGift,
  isSelectedMyGift,
}) => (
  <Grid data-cy="availableMyGifts">
    <StyledEditGiftsContainer>
      {Object.entries(groupedAvailableMyGifts).map(([date, myGifts]) => (
        <React.Fragment key={date}>
          <StyledGroupedLabelText variant="caption">
            {date}
          </StyledGroupedLabelText>
          <FormGroup>
            {myGifts?.map(
              (myGift) =>
                myGift && (
                  <StyledEditableMyGiftForm
                    data-cy={`availableMyGift-${myGift.urlCode}`}
                    key={myGift.urlCode}
                  >
                    <StyledFormControlLabel
                      control={
                        <Checkbox
                          checked={isSelectedMyGift(myGift)}
                          data-cy={`checkBox-${myGift.urlCode}`}
                          color="primary"
                          onChange={(_, checked) =>
                            handleEditingMyGift(myGift, checked)
                          }
                          style={{ padding: 0, marginLeft: 20 }}
                        />
                      }
                      label={<MyGift myGift={myGift} />}
                    />
                  </StyledEditableMyGiftForm>
                ),
            )}
          </FormGroup>
        </React.Fragment>
      ))}
    </StyledEditGiftsContainer>
    {getAvailableMyGiftsConnectionStatus === STATUS.LOADING && (
      <PartialLoader />
    )}
    <div ref={infiniteScrollTarget} />
  </Grid>
);

export { Presenter };
