import React from 'react';
import { Typography, Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/system';
import { Promotion } from '/@/components/shared/promotion/Index';
import { PromotionSpotsState } from '/@/store/app/promotionSpots';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { Status, STATUS } from '/@/store/api/constants';

const StyledTitleContainer = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(),
}));
const StyledTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
}));
const StyledTitleDecoration = styled(Typography)(({ theme }) => ({
  color: theme.palette.gifteeCoral.main,
  fontWeight: 'bold',
  paddingRight: theme.spacing(),
  paddingLeft: theme.spacing(),
}));

type Props = {
  promotionSpots: PromotionSpotsState;
  getPromotionSpotsStatus: Status;
};

const Presenter: React.VFC<Props> = ({
  promotionSpots,
  getPromotionSpotsStatus,
}) => (
  <div data-cy="promotions">
    {getPromotionSpotsStatus === STATUS.LOADING ? (
      <PartialLoader />
    ) : (
      promotionSpots &&
      promotionSpots.length > 0 && (
        <>
          <StyledTitleContainer container justifyContent="center">
            <StyledTitleDecoration variant="subtitle1" color="primary">
              ＼
            </StyledTitleDecoration>
            <StyledTitle variant="subtitle1" color="primary">
              さらにギフトをもらっちゃおう
            </StyledTitle>
            <StyledTitleDecoration variant="subtitle1" color="primary">
              ／
            </StyledTitleDecoration>
          </StyledTitleContainer>
          <Grid>
            {promotionSpots?.map((promotionSpot) => (
              <Promotion
                key={`${promotionSpot.name}${promotionSpot.order}`}
                promotionSpot={promotionSpot}
              />
            ))}
          </Grid>
        </>
      )
    )}
  </div>
);

export { Presenter };
