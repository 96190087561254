import { types } from './types';
import type { GetPointFeatureGroupsQuery } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const getPointFeatureGroupsStart = () =>
  ({
    type: types.GET_POINT_FEATURE_GROUPS_START,
  }) as const;

export const getPointFeatureGroupsSuccess = (
  pointFeatureGroupsResponse: GetPointFeatureGroupsQuery['pointFeatureGroups'],
) =>
  ({
    type: types.GET_POINT_FEATURE_GROUPS_SUCCESS,
    pointFeatureGroupsResponse,
  }) as const;

export const getPointFeatureGroupsFailure = (error: ApiError) =>
  ({
    type: types.GET_POINT_FEATURE_GROUPS_FAILURE,
    error,
  }) as const;
