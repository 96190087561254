import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Presenter } from './Presenter';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';
import { giftConvertToPointRequestSubscriptionThunk } from '/@/store/api/subscriptions/giftConvertToPointRequestSubscription';
import { useAppDispatch, useAppSelector } from '/@/store';
import {
  createGiftConvertToPointInitThunk,
  createGiftConvertToPointThunk,
} from '/@/store/api/mutations/createGiftConvertToPoint';
import createRequestUid from '/@/utils/createRequestUid';
import { initialiseGiftConvertToPointRequestSubscriptionStatus } from '/@/store/api/subscriptions/giftConvertToPointRequestSubscription/actions';
import { useDataLayer } from '/@/common/hooks/useDataLayer';
import { STATUS } from '/@/store/api/constants';
import { getGiftConvertToPointRequestThunk } from '/@/store/api/queries/getGiftConvertToPointRequest';
import { setAlertMessage } from '/@/store/ui/alertMessage/actions';
import * as Sentry from '@sentry/browser';

type OwnProps = {
  handleClose: () => void;
  skipConfirm?: boolean;
};

const Container: React.VFC<OwnProps> = ({ handleClose, skipConfirm }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const requestUid = createRequestUid();
  const { pushClickEvent, pushPointChargeEvent } = useDataLayer();
  const createGiftConvertToPointStatus = useAppSelector(
    (state) => state.api.createGiftConvertToPoint.status,
  );
  const getGiftConvertToPointRequestStatus = useAppSelector(
    (state) => state.api.getGiftConvertToPointRequest.status,
  );
  const giftConvertToPointRequestSubscriptionStatus = useAppSelector(
    (state) => state.api.giftConvertToPointRequestSubscription.status,
  );
  const myGift = useAppSelector((state) => state.app.myGift);
  const giftConvertToPointRequestResponce = useAppSelector(
    (state) => state.app.giftConvertToPointRequest,
  );
  const responseRef = useRef(giftConvertToPointRequestResponce);
  const [isPolling, setIsPolling] = useState<boolean>(false);

  const cancelCharge = () => {
    pushClickEvent({
      clickItem: 'cancelChargeButton',
      customData: {
        clickItem: 'cancelChargeButton',
        itemName: myGift?.contentName,
        point: myGift?.remainingPoint,
      },
    });
    handleClose();
  };

  const pollingInterval = 5000;
  const pollingFrequencyLimit = 12;
  const pollingGiftConvertToPointRequest = useCallback(() => {
    let pollingCount = 1;
    dispatch(
      getGiftConvertToPointRequestThunk({
        requestUid: requestUid,
      }),
    );

    const intervalId = setInterval(() => {
      if (responseRef?.current?.myGift.pointMergeable === false) {
        clearInterval(intervalId);
        setIsPolling(false);
        navigate(PATHS.POINT_CHARGE_COMPLETION);
      } else if (getGiftConvertToPointRequestStatus === STATUS.FAILURE) {
        clearInterval(intervalId);
        setIsPolling(false);
        handleClose();
        dispatch(
          setAlertMessage({
            message: 'ポイント移行に失敗しました。時間をおいて再度お試しください。',
            severity: 'error',
          })
        );
      } else if (pollingCount >= pollingFrequencyLimit) {
        clearInterval(intervalId);
        setIsPolling(false);
        handleClose();
        dispatch(
          setAlertMessage({
            message: 'ポイント移行に時間がかかっています。時間をおいてポイント履歴をご確認ください。',
            severity: 'error',
          })
        );
        Sentry.captureException(
          `createGiftConvertToPoint warning: Polling limit exceeded`,
        );
      } else {
        dispatch(
          getGiftConvertToPointRequestThunk({
            requestUid: requestUid,
          }),
        );
        pollingCount++;
      }
    }, pollingInterval);
  }, [dispatch, navigate, handleClose, requestUid, getGiftConvertToPointRequestStatus]);

  const handleSubmit = useCallback(() => {
    if (
      myGift?.urlCode &&
      myGift?.remainingPoint &&
      createGiftConvertToPointStatus === STATUS.IDLE
    ) {
      dispatch(
        createGiftConvertToPointThunk({
          myGiftUrlCode: myGift.urlCode,
          requestUid: requestUid,
          convertPointAmount: myGift.remainingPoint,
        }),
      ).then(() => {
        pushPointChargeEvent({
          itemName: myGift?.contentName,
          point: myGift?.remainingPoint,
          customData: {
            itemName: myGift?.contentName,
            point: myGift?.remainingPoint,
          },
        });
        dispatch(
          giftConvertToPointRequestSubscriptionThunk({
            requestUid: requestUid,
          }),
        );
        // MEMO: subscriptionが動かず画面遷移もしないケースがあるため、5秒間隔でステータスを確認する
        setIsPolling(true);
        setTimeout(() => pollingGiftConvertToPointRequest(), pollingInterval);
      });
    }
  }, [
    dispatch,
    myGift,
    createGiftConvertToPointStatus,
    requestUid,
    pushPointChargeEvent,
    pollingGiftConvertToPointRequest,
  ]);

  useEffect(() => {
    if (myGift?.urlCode && myGift?.remainingPoint) {
      dispatch(createGiftConvertToPointInitThunk());
    }
  }, [dispatch, myGift]);

  useEffect(() => {
    if (skipConfirm === true && myGift?.remainingPoint) {
      handleSubmit();
    }
  }, [dispatch, myGift, skipConfirm, handleSubmit]);

  useEffect(() => {
    responseRef.current = giftConvertToPointRequestResponce;
  }, [giftConvertToPointRequestResponce]);

  useEffect(() => {
    // MEMO: subscriptionにて移行成功通知取得時に画面遷移
    if (giftConvertToPointRequestSubscriptionStatus === STATUS.SUCCESS) {
      dispatch(initialiseGiftConvertToPointRequestSubscriptionStatus());
      navigate(PATHS.POINT_CHARGE_COMPLETION);
    } else if (giftConvertToPointRequestSubscriptionStatus === STATUS.FAILURE) {
      dispatch(initialiseGiftConvertToPointRequestSubscriptionStatus());
      handleClose();
      // MEMO:移行時エラーのalertMessageがページTOPに表示されるため
      window.scrollTo(0, 0);
    }
  }, [
    giftConvertToPointRequestSubscriptionStatus,
    dispatch,
    navigate,
    handleClose,
  ]);

  return myGift?.remainingPoint && !skipConfirm ? (
    <Presenter
      cancelCharge={cancelCharge}
      handleSubmit={handleSubmit}
      pointAmount={myGift.remainingPoint}
      isLoading={
        createGiftConvertToPointStatus === STATUS.LOADING ||
        giftConvertToPointRequestSubscriptionStatus === STATUS.LOADING ||
        isPolling
      }
    />
  ) : null;
};
export { Container as PointChargeModal };
