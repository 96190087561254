import { types } from './types';
import type { GetGiftConvertToPointRequestQuery } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';

export const getGiftConvertToPointRequestStart = () =>
  ({
    type: types.GET_GIFT_CONVERT_TO_POINT_REQUEST_START,
  }) as const;

export const getGiftConvertToPointRequestSuccess = (
  giftConvertToPointRequest: GetGiftConvertToPointRequestQuery['giftConvertToPointRequest'],
) =>
  ({
    type: types.GET_GIFT_CONVERT_TO_POINT_REQUEST_SUCCESS,
    giftConvertToPointRequest: giftConvertToPointRequest,
  }) as const;

export const getGiftConvertToPointRequestFailure = (error: ApiError) =>
  ({
    type: types.GET_GIFT_CONVERT_TO_POINT_REQUEST_FAILURE,
    error,
  }) as const;
