import { Reducer } from 'redux';
import { PointLogBase, PageInfo } from '/@/store/app/shared/types';
import { getPointLogsTypes } from '/@/store/api/queries/getPointLogs';
import type { RootAction } from '/@/store/actions';
import { combineReducers } from 'redux';

type PointLog = PointLogBase;
type PointLogsState = PointLog[] | null;
type PageInfoState = PageInfo | null;
type PointLogsPageState = {
  pointLogs: PointLogsState;
  pageInfo: PageInfoState;
};

const initialPointLogsState: PointLogsState = null;

const pointLogs: Reducer<PointLogsState, RootAction> = (
  state = initialPointLogsState,
  action,
) => {
  switch (action.type) {
    case getPointLogsTypes.GET_POINT_LOGS_SUCCESS: {
      if (!action.pointLogsResponse.nodes) return state;

      const filteredNodes = filterNodes(action.pointLogsResponse.nodes);
      if (state) {
        return [...state, ...filteredNodes];
      } else {
        return [...filteredNodes];
      }
    }
    default:
      return state;
  }
};

const filterNodes = (pointLogs: (PointLog | null)[]): PointLog[] =>
  pointLogs.filter((node): node is PointLog => node !== null);

const pageInfoInitialState: PageInfoState = null;

const pageInfo: Reducer<PageInfoState, RootAction> = (
  state = pageInfoInitialState,
  action,
) => {
  switch (action.type) {
    case getPointLogsTypes.GET_POINT_LOGS_SUCCESS:
      return action.pointLogsResponse.pageInfo;
    default:
      return state;
  }
};

const listPage = combineReducers({
  pointLogs,
  pageInfo,
});

export { listPage };
export type { PointLogsPageState, PointLogsState, PointLog };
