import React from 'react';
import { SelectedGifteeContentState } from '/@/store/app/selectedGifteeContent';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { GifteeContent } from '/@/components/shared/gifteeContent/Index';

type Props = {
  selectedGifteeContent: SelectedGifteeContentState;
  getGifteeContentStatus: Status;
};
const Presenter: React.VFC<Props> = ({
  selectedGifteeContent,
  getGifteeContentStatus,
}) => (
  <>
    {getGifteeContentStatus === STATUS.LOADING || !selectedGifteeContent ? (
      <PartialLoader />
    ) : (
      <GifteeContent gifteeContent={selectedGifteeContent} />
    )}
  </>
);

export { Presenter };
