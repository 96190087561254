import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';
import { Presenter } from './Presenter';

const Container: React.VFC<Record<string, never>> = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const urlSearchParams = useMemo<URLSearchParams>(
    () => new URLSearchParams(search),
    [search],
  );
  const destinationPath = useMemo<string>(() => {
    return (
      PATHS.SIGN_IN +
      (urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : '')
    );
  }, [urlSearchParams]);

  // MEMO: ちらつき防止用の描画遅延
  const delay = 1000;
  const [isRendered, setIsRendered] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsRendered(true);
    }, delay);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    navigate(destinationPath, { replace: false });
  }, [navigate, destinationPath]);

  return (
    <Presenter destinationPath={destinationPath} isRendered={isRendered} />
  );
};

export { Container as NonExsitentLpPage };
