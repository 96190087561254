import { Reducer } from 'redux';
import type { RootAction } from '/@/store/actions';
import type { Severity } from './constants';
import { SEVERITY } from './constants';
import { changePageTypes } from '/@/store/app/changePage';
import { discardMyGiftsTypes } from '/@/store/api/mutations/discardMyGifts';
import { readAnnouncementsTypes } from '/@/store/api/mutations/readAnnouncements';
import { giftsAvailabilityTabTypes } from '/@/store/ui/selectedGiftsAvailabilityTab';
import { types } from './types';

type AlertMessage = {
  severity: Severity;
  message: string;
  link?: {
    path: string;
    text: string;
  };
};
type AlertMessageState = AlertMessage | null;

const initialState: AlertMessageState = null;

const alertMessage: Reducer<AlertMessageState, RootAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case discardMyGiftsTypes.DISCARD_MY_GIFTS_SUCCESS:
      return {
        severity: SEVERITY.SUCCESS,
        message: action.message,
      };
    case readAnnouncementsTypes.READ_ANNOUNCEMENTS_SUCCESS:
      return {
        severity: SEVERITY.SUCCESS,
        message: action.message,
      };
    case readAnnouncementsTypes.READ_ANNOUNCEMENTS_FAILURE:
      return {
        severity: SEVERITY.ERROR,
        message: action.message,
      };
    case giftsAvailabilityTabTypes.SELECT_GIFTS_AVAILABILITY_TAB:
    case changePageTypes.CHANGE_PAGE:
    case types.INITIALISE_ALERT_MESSAGE:
      return null;
    case types.SET_ALERT_MESSAGE:
      return action.alertMessage;
    case types.SET_EXCHANGE_ALERT_MESSAGE:
      return action.errorPattern;
    default:
      return state;
  }
};

export { alertMessage };
export type { AlertMessageState };
