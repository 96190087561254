import { FetchResult } from '@apollo/client';
import { publicApiClient } from '/@/api/graphql/appApolloClient';
import { notifyInquiryGql } from './index.gql';
import {
  NotifyInquiryInput,
  NotifyInquiryMutation,
} from '/@/api/graphql/publicApi/types';

type NotifyInquiryResponse = FetchResult<NotifyInquiryMutation>;

const notifyInquiry = (
  input: NotifyInquiryInput,
): Promise<NotifyInquiryResponse> => {
  return publicApiClient.mutate({
    mutation: notifyInquiryGql,
    variables: {
      input,
    },
  });
};

export { notifyInquiry };

export type { NotifyInquiryResponse };
