import { getUserAuthenticators } from '/@/api/graphql/publicApi/queries/getUserAuthenticators/index';
import type { GetUserAuthenticatorsResponse } from '/@/api/graphql/publicApi/queries/getUserAuthenticators/index';
import type { AppThunk } from '/@/store';
import {
  getUserAuthenticatorsStart,
  getUserAuthenticatorsSuccess,
  getUserAuthenticatorsFailure,
} from './actions';
import { handleErrorThunk } from '../../handleError';
import type { ApiError } from '/@/api/graphql/client';

const getUserAuthenticatorsThunk =
  (): AppThunk<Promise<void>> => (dispatch) => {
    dispatch(getUserAuthenticatorsStart());

    return getUserAuthenticators()
      .then((response) => dispatch(getUserAuthenticatorsSuccessThunk(response)))
      .catch((error) => dispatch(getUserAuthenticatorsFailureThunk(error)));
  };

const getUserAuthenticatorsSuccessThunk =
  (response: GetUserAuthenticatorsResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { userAuthenticators } = response.data!;
    dispatch(getUserAuthenticatorsSuccess(userAuthenticators));
    return Promise.resolve();
  };

const getUserAuthenticatorsFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getUserAuthenticatorsFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { getUserAuthenticatorsThunk };
