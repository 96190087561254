import React from 'react';
import { Presenter } from './Presenter';

type OwnProps = {
  children?: React.ReactNode;
  highReturnBrandsImageURL: string;
  exchangeableBrandsImageURL: string;
};

const Container: React.FC<OwnProps> = ({
  children,
  highReturnBrandsImageURL,
  exchangeableBrandsImageURL,
}) => {
  return (
    <Presenter
      highReturnBrandsImageURL={highReturnBrandsImageURL}
      exchangeableBrandsImageURL={exchangeableBrandsImageURL}
    >
      {children}
    </Presenter>
  );
};

export { Container as CampaignSection };
