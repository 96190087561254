import React from 'react';
import { Typography, Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/system';
import type { Detail } from './Index';

const StyledCard = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
}));
const StyledCardContent = styled(Grid)({
  minWidth: 0,
});
const StyledTitle = styled(Typography)(({ theme }) => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-line-clamp': 2,
  '-webkit-box-orient': 'vertical',
  color: theme.palette.secondary.main,
  fontSize: '0.875rem',
  lineHeight: '1',
}));
const StyledDetailsGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
}));
const StyledDetails = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '0.875rem',
  lineHeight: '1',
  '& + &': {
    marginTop: theme.spacing(1),
  },
}));
const StyledPoint = styled(Typography)({
  fontSize: '1rem',
  lineHeight: '1.6',
  fontWeight: 'bold',
});

type Props = {
  title: string;
  details?: Detail[];
  point: number;
};

const Presenter: React.VFC<Props> = ({ title, details, point }) => (
  <StyledCard data-cy="pointLog">
    <Grid container wrap="nowrap" direction="row">
      <StyledCardContent
        size={{ xs: 9 }}
        container
        justifyContent="center"
        direction="column"
      >
        <StyledTitle variant="subtitle1">{title}</StyledTitle>
        {details && (
          <StyledDetailsGrid container alignItems="center" direction="row">
            {details.map((detail, index) => (
              <StyledDetails key={index} variant="subtitle2">
                {detail.key} : {detail.value}
              </StyledDetails>
            ))}
          </StyledDetailsGrid>
        )}
      </StyledCardContent>
      <Grid
        size={{ xs: 3 }}
        container
        justifyContent="flex-end"
        alignItems="center"
      >
        <StyledPoint variant="subtitle1">
          {point.toLocaleString('ja-JP')}
        </StyledPoint>
      </Grid>
    </Grid>
  </StyledCard>
);

export { Presenter };
