import React, { useState, useEffect, useRef } from 'react';
import { Presenter } from './Presenter';
import { useAppDispatch, useAppSelector } from '/@/store';
import { createPointExchangeThunk } from '/@/store/api/mutations/createPointExchange';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';
import { initialiseItemDetail } from '/@/store/app/itemDetail/actions';
import { PointLogType_StatusEnumType } from '/@/api/graphql/internalApi/types';
import { isExchangeErrorPatternTypeMatch } from '/@/store/ui/alertMessage/constants';
import { useDataLayer } from '/@/common/hooks/useDataLayer';
import { filteringExtensionsErrorCode } from '/@/utils/filteringExtensionsErrorCode';

type OwnProps = {
  handleClose: () => void;
  scrollToTop: () => void;
};

const Container: React.VFC<OwnProps> = ({ handleClose, scrollToTop }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pushClickEvent } = useDataLayer();
  const itemDetail = useAppSelector((state) => state.app.itemDetail);
  const pointAmount = useAppSelector(
    (state) => state.app.createPointExchangeInput?.pointExchange?.pointAmount,
  );
  const myPoints = useAppSelector((state) => state.app.pointWallet?.point);
  const hasGetMyPoints = useRef(false);
  const [displayMyPoints, setDisplayMyPoints] = useState<number | undefined>(
    undefined,
  );
  const createPointExchangeInput = useAppSelector(
    (state) => state.app.createPointExchangeInput,
  );
  const createPointExchangeStatus = useAppSelector(
    (state) => state.api.createPointExchange.status,
  );

  const cancelExchange = () => {
    pushClickEvent({
      clickItem: 'cancelExchangeButton',
      customData: {
        clickItem: 'cancelExchangeButton',
        point: pointAmount,
        itemUid: itemDetail?.uid,
        itemName: itemDetail?.contentName,
        brandUid: itemDetail?.brand?.uid,
        brandName: itemDetail?.brand?.name,
      },
    });
    handleClose();
  };

  const handleSubmit = () => {
    if (createPointExchangeInput && createPointExchangeStatus !== 'loading') {
      dispatch(createPointExchangeThunk(createPointExchangeInput.pointExchange))
        .then((res) => {
          const status =
            res?.createPointExchange?.pointExchange?.pointLog?.status;
          handleClose();

          const errorCode = filteringExtensionsErrorCode(
            res?.createPointExchange?.errors,
          );

          if (errorCode && isExchangeErrorPatternTypeMatch(errorCode)) {
            // MEMO:交換時エラーのalertMessageがモーダルTOPに表示されるため
            scrollToTop();
          } else {
            dispatch(initialiseItemDetail());
            if (status === PointLogType_StatusEnumType.Success) {
              navigate(PATHS.POINT_EXCHANGE_COMPLETION);
            } else if (status === PointLogType_StatusEnumType.Retrying) {
              navigate(PATHS.POINT_EXCHANGE_COMPLETION, {
                state: { pointLogStatus: PointLogType_StatusEnumType.Retrying },
              });
            }
          }
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    if (!hasGetMyPoints.current && myPoints) {
      hasGetMyPoints.current = true;
      setDisplayMyPoints(myPoints);
    }
  }, [myPoints]);

  return (
    <Presenter
      cancelExchange={cancelExchange}
      handleSubmit={handleSubmit}
      myPoints={displayMyPoints}
      pointAmount={pointAmount}
      createPointExchangeStatus={createPointExchangeStatus}
    />
  );
};
export { Container as ItemExchangeModal };
