import React from 'react';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import { useAppSelector } from '/@/store';

export type Detail = { key: string; value: string };
export const FONT_SIZE = {
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
} as const;
export type FontSize = 'md' | 'lg' | 'xl';

type Props = {
  points: number | null | undefined;
  fontSize?: FontSize;
  isMinus?: boolean;
};

const Container: React.VFC<Props> = ({
  points,
  fontSize = FONT_SIZE.MD,
  isMinus = false,
}) => {
  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return isRenewalDesign ? (
    <NeoPresenter points={points} fontSize={fontSize} isMinus={isMinus} />
  ) : (
    <Presenter points={points} fontSize={fontSize} isMinus={isMinus} />
  );
};

export { Container as Points };
