import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Presenter } from './Presenter';
import { useLocation } from 'react-router-dom';
import { refreshMyGiftThunk } from '/@/store/api/mutations/refreshMyGift';
import { PATHS } from '/@/routes/paths';
import { useAppDispatch, useAppSelector } from '/@/store';
import { setAlertMessage } from '/@/store/ui/alertMessage/actions';
import { SEVERITY } from '/@/store/ui/alertMessage';

const Container: React.VFC<Record<string, never>> = () => {
  const headerTitle = 'ギフト詳細';
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { state } = location;
  const myGift = useAppSelector((state) => state.app.myGift);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('my_gift_url_code') !== null) {
      dispatch(
        refreshMyGiftThunk({
          urlCode: params.get('my_gift_url_code') as string,
        }),
      );
    }
  }, [dispatch, location.search]);
  useEffect(() => {
    // 画面スキップの場合ボタンをdisabledで表示してユーザーフィードバックするという手が使えないのでhomeに飛ばす
    if (
      state?.skipConfirm === true &&
      myGift !== undefined &&
      myGift?.remainingPoint === 0
    ) {
      navigate(PATHS.HOME);
      // 画面移動するとalertがクリアされてしまうので少し遅延させる
      setTimeout(() => {
        dispatch(
          setAlertMessage({
            severity: SEVERITY.ERROR,
            message: '移行可能なポイントがありません。',
          }),
        );
      }, 500);
    }
  }, [dispatch, state?.skipConfirm, myGift, navigate]);

  return (
    <Presenter headerTitle={headerTitle} skipConfirm={state?.skipConfirm} />
  );
};

export { Container as PointChargePage };
