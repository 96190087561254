import React from 'react';
import { Box, Container, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { HEADER_HEIGHT } from '/@/components/templates/header/globalHeader/Presenter';
import { POINT_SECTION_WITH_BENEFIT_OF_POINT_CHARGE_HEIGHT } from '/@/components/shared/headingWithPoints/Presenter';
import { FOOTER_HEIGHT } from '/@/components/templates/footer/Presenter';
import { HeadingWithPoints } from '/@/components/shared/headingWithPoints/Index';
import { AvailablePointCharges } from './availablePointCharges/Index';
import { PointLogList } from './pointLogList/Index';
import { TAB_NAME } from './Index';

const StyledBackground = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.contrast,
}));
const StyledBaseBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
}));
const StyledBoxContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    borderRadius: theme.spacing(2),
  },
  backgroundColor: theme.palette.background.default,
  padding: 0,
}));
const StyledTab = styled(Tab)(({ theme }) => ({
  height: '3.5rem',
  borderBottom: '2px solid ' + theme.palette.grey[300],
}));
const StyledTabLabel = styled(Typography)({
  fontWeight: 'bold',
});
const StyledMainContainer = styled('div')({
  minHeight: `calc(100vh - ${HEADER_HEIGHT}px - ${POINT_SECTION_WITH_BENEFIT_OF_POINT_CHARGE_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
});

type Props = {
  headerTitle: string;
  activeTabStatus: TAB_NAME;
  setActiveTabStatus: (tag: TAB_NAME) => void;
};

const Presenter: React.VFC<Props> = ({
  headerTitle,
  activeTabStatus,
  setActiveTabStatus,
}) => (
  <MainLayout headerTitle={headerTitle}>
    <StyledBackground>
      <StyledBaseBox>
        <StyledBoxContainer maxWidth="sm">
          <HeadingWithPoints benefitOfPointChargeModalVisible={true} />
          <StyledMainContainer>
            <Tabs
              value={activeTabStatus}
              onChange={(_, value) => setActiveTabStatus(value)}
              aria-label="ポイントの表示を切り替えるタブ"
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
            >
              <StyledTab
                label={
                  <StyledTabLabel variant="button">
                    ポイント有効期限
                  </StyledTabLabel>
                }
                value="availablePointLogs"
                data-cy="availablePointLogs"
              />
              <StyledTab
                label={
                  <StyledTabLabel variant="button">ポイント履歴</StyledTabLabel>
                }
                value="logList"
                data-cy="logList"
              />
            </Tabs>
            {activeTabStatus === 'availablePointLogs' && (
              <AvailablePointCharges />
            )}
            {activeTabStatus === 'logList' && <PointLogList />}
          </StyledMainContainer>
        </StyledBoxContainer>
      </StyledBaseBox>
    </StyledBackground>
  </MainLayout>
);

export { Presenter };
