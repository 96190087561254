import { pointUpdatedSubscriptionObserver } from '/@/api/graphql/internalApi/subscriptions/pointUpdatedSubscription';
import type { AppThunk } from '/@/store';
import {
  pointUpdatedSubscriptionStart,
  pointUpdatedSubscriptionSuccess,
  pointUpdatedSubscriptionFailure,
} from './actions';
import { handleErrorThunk } from './handleError';
import type { ApiError } from '/@/api/graphql/client';
import { GetPointWalletQuery } from '/@/api/graphql/internalApi/types';

const pointUpdatedSubscriptionThunk =
  (): AppThunk<Promise<void>> => (dispatch) => {
    dispatch(pointUpdatedSubscriptionStart());
    pointUpdatedSubscriptionObserver()?.subscribe({
      next: (response) => {
        const { pointWallet } = response.data!.pointUpdated;
        if (pointWallet) {
          dispatch(pointUpdatedSubscriptionSuccessThunk(pointWallet));
        }
      },
      error: (error) => {
        dispatch(pointUpdatedSubscriptionFailureThunk(error));
      },
    });
    return Promise.resolve();
  };

const pointUpdatedSubscriptionSuccessThunk =
  (response: GetPointWalletQuery['pointWallet']): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(pointUpdatedSubscriptionSuccess(response));
    return Promise.resolve();
  };

const pointUpdatedSubscriptionFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(pointUpdatedSubscriptionFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { pointUpdatedSubscriptionThunk };
