import {
  getAnnouncementsConnection,
  GetAnnouncementsConnectionResponse,
} from '/@/api/graphql/internalApi/queries/getAnnouncementsConnection';
import type { AppThunk } from '/@/store';
import {
  getAnnouncementsConnectionStart,
  getAnnouncementsConnectionSuccess,
  getAnnouncementsConnectionFailure,
} from './actions';
import { handleErrorThunk } from '../../handleError';
import type { ApiError } from '/@/api/graphql/client';
import { GetAnnouncementsConnectionQueryVariables } from '/@/api/graphql/internalApi/types';

const getAnnouncementsConnectionThunk =
  (
    variables: GetAnnouncementsConnectionQueryVariables,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getAnnouncementsConnectionStart());

    return getAnnouncementsConnection(variables)
      .then((response) =>
        dispatch(getAnnouncementsConnectionSuccessThunk(response)),
      )
      .catch((error) =>
        dispatch(getAnnouncementsConnectionFailureThunk(error)),
      );
  };

const getAnnouncementsConnectionSuccessThunk =
  (response: GetAnnouncementsConnectionResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { userAnnouncements } = response.data!;
    dispatch(getAnnouncementsConnectionSuccess(userAnnouncements));
    return Promise.resolve();
  };

const getAnnouncementsConnectionFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getAnnouncementsConnectionFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { getAnnouncementsConnectionThunk };
