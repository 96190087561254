import { types } from './types';
import type { GetBrandsConnectionQuery } from '/@/api/graphql/internalApi/types';
import type { ApiError } from '/@/api/graphql/client';
import { BrandsPatternTypes } from './operations';

export const getBrandsConnectionStart = (brandsPattern: BrandsPatternTypes) =>
  ({
    type: types.GET_BRANDS_CONNECTION_START,
    pattern: brandsPattern,
  }) as const;

export const getBrandsConnectionSuccess = (
  brandsConnection: GetBrandsConnectionQuery['brands'],
  brandsPattern: BrandsPatternTypes,
) =>
  ({
    type: types.GET_BRANDS_CONNECTION_SUCCESS,
    pattern: brandsPattern,
    brandsConnection,
  }) as const;

export const getBrandsConnectionFailure = (
  error: ApiError,
  brandsPattern: BrandsPatternTypes,
) =>
  ({
    type: types.GET_BRANDS_CONNECTION_FAILURE,
    pattern: brandsPattern,
    error,
  }) as const;
