import React, { useMemo } from 'react';
import { useAppSelector } from '/@/store';
import { UserAnnouncementTypeCategoryUidEnumType } from '/@/api/graphql/internalApi/types';
import { AnnouncementBasePage } from '../announcementBasePage/Index';

const Container: React.VFC<Record<string, never>> = () => {
  const headerTitle = 'あなた宛';
  const noAnnouncementText =
    '各種特典や利用リマインドなどが\nこちらに通知されます';
  const unreadAnnouncementsCountForYou = useAppSelector(
    (state) =>
      state.app.currentUser?.unreadAnnouncementsCountByCategoryUid.forYou,
  );
  const isDisabledReadButton = useMemo(() => {
    return (
      unreadAnnouncementsCountForYou === undefined ||
      unreadAnnouncementsCountForYou === 0
    );
  }, [unreadAnnouncementsCountForYou]);

  return (
    <AnnouncementBasePage
      headerTitle={headerTitle}
      isDisabledReadButton={isDisabledReadButton}
      noAnnouncementText={noAnnouncementText}
      userAnnouncementTypeCategory={
        UserAnnouncementTypeCategoryUidEnumType.ForYou
      }
    />
  );
};

export { Container as AnnouncementsForYouPage };
