import { combineReducers, Reducer } from 'redux';
import { getOauthSessionTypes } from '/@/store/api/queries/getOauthSession';
import type { RootAction } from '/@/store/actions';
import { DestinationEnum, OriginEnum } from '/@/api/graphql/publicApi/types';

type GiftSavingOption = {
  url: string;
  origin: OriginEnum;
  convertToPoint: boolean | null;
  referer?: string | null;
};

type DestinationOption = {
  name: DestinationEnum;
};

type PointSerialOption = {
  serialCode: string;
  endAt?: string | null;
};

type OauthSession = {
  giftSavingOption?: GiftSavingOption | null;
  destinationOption?: DestinationOption | null;
  pointSerialOption?: PointSerialOption | null;
};

type OauthSessionState = OauthSession | null;

const oauthSession: Reducer<OauthSessionState, RootAction> = (
  state = null,
  action,
) => {
  switch (action.type) {
    case getOauthSessionTypes.GET_OAUTH_SESSION_SUCCESS:
      return action.oauthSession;
    default:
      return state;
  }
};

type AuthorizationPageState = { oauthSession: OauthSessionState };
const authorizationPage = combineReducers({ oauthSession });

export { authorizationPage };
export type { AuthorizationPageState };
