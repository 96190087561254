import React, { useCallback, useEffect, useMemo } from 'react';
import { Presenter } from './Presenter';
import { useAppDispatch, useAppSelector } from '/@/store';
import { useParams } from 'react-router-dom';
import { getAnnouncementThunk } from '/@/store/api/queries/getAnnouncement';
import { readAnnouncementThunk } from '/@/store/api/mutations/readAnnouncement';
import { initialiseAnnouncementsConnection } from '/@/store/app/announcementsConnection/actions';

const Container: React.VFC<Record<string, never>> = () => {
  const headerTitle = 'お知らせ詳細';

  const dispatch = useAppDispatch();
  const { uid } = useParams();
  const announcementDetail = useAppSelector(
    (state) => state.app.announcementDetail,
  );
  const getAnnouncementStatus = useAppSelector(
    (state) => state.api.getAnnouncement.status,
  );
  const isDisplayAnnouncementDetail = useMemo(() => {
    return announcementDetail?.uid === uid;
  }, [announcementDetail, uid]);

  const handleClickImage = useCallback(() => {
    if (announcementDetail?.content?.linkUrl) {
      window.location.href = announcementDetail.content.linkUrl;
    }
  }, [announcementDetail]);

  useEffect(() => {
    if (uid && (announcementDetail?.uid !== uid || !announcementDetail)) {
      dispatch(getAnnouncementThunk({ uid }));
    }
  }, [dispatch, uid, announcementDetail]);

  useEffect(() => {
    if (announcementDetail && !announcementDetail?.readAt) {
      dispatch(
        readAnnouncementThunk({ userAnnouncementUid: announcementDetail.uid }),
      ).then(() => {
        // MEMO: 一覧画面の未読バッジを再取得させるため初期化
        dispatch(initialiseAnnouncementsConnection());
      });
    }
  }, [dispatch, announcementDetail]);

  return (
    <Presenter
      headerTitle={headerTitle}
      isDisplayAnnouncementDetail={isDisplayAnnouncementDetail}
      announcementDetail={announcementDetail}
      getAnnouncementStatus={getAnnouncementStatus}
      handleClickImage={handleClickImage}
    />
  );
};

export { Container as AnnouncementDetailPage };
