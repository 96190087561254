import React from 'react';
import { Presenter } from './Presenter';

const Container: React.VFC<{
  handleToggleMenu: () => void;
  handleClickGlobalMenu: () => void;
}> = ({ handleToggleMenu, handleClickGlobalMenu }) => {
  return (
    <Presenter
      handleToggleMenu={handleToggleMenu}
      handleClickGlobalMenu={handleClickGlobalMenu}
    />
  );
};

export { Container as HeaderGrobalMenu };
