import React from 'react';
import { Container, Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/system';
import { BenefitOfPointCharge } from '/@/components/shared/benefitOfPointCharge/illustration/Index';
import { ChargeableGift } from './chargeableGift/Index';
import { BenefitOfPointChargeModalWithButton } from '/@/components/shared/benefitOfPointCharge/modalWithButton/Index';
import { PageLoader } from '/@/components/shared/loader/pageLoader/Index';
import { PointChargeModal } from '/@/components/shared/modal/pointChargeModal/Index';

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.contrast,
  padding: theme.spacing(2),
}));
const StyledBenefitOfPointChargeSection = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(2),
  marginBottom: theme.spacing(2),
  padding: theme.spacing(3),
}));
const StyledBenefitOfPointChargeModal = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

type Props = {
  hasCharged: boolean | null;
  skipConfirm?: boolean;
};
const Presenter: React.VFC<Props> = ({ hasCharged, skipConfirm }) => (
  <StyledContainer maxWidth="sm">
    {skipConfirm === true ? (
      <>
        <PageLoader />
        {/* skipConfirmがtrueの時、モーダル非表示で自動的にチャージ処理が実行される */}
        <PointChargeModal handleClose={() => {}} skipConfirm={skipConfirm} />
      </>
    ) : (
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        direction="column"
      >
        {hasCharged === false && (
          <StyledBenefitOfPointChargeSection data-cy="benefitOfPointCharge">
            <BenefitOfPointCharge />
          </StyledBenefitOfPointChargeSection>
        )}
        <ChargeableGift />
        {hasCharged === true && (
          <StyledBenefitOfPointChargeModal
            container
            alignContent="center"
            justifyContent="center"
          >
            <BenefitOfPointChargeModalWithButton />
          </StyledBenefitOfPointChargeModal>
        )}
      </Grid>
    )}
  </StyledContainer>
);

export { Presenter };
