import { FetchResult } from '@apollo/client';
import { internalApiClient } from '/@/api/graphql/appApolloClient';
import { getPointWalletGql } from './index.gql';
import { GetPointWalletQuery } from '/@/api/graphql/internalApi/types';

type GetPointWalletResponse = FetchResult<GetPointWalletQuery>;

const getPointWallet = (): Promise<GetPointWalletResponse> => {
  return internalApiClient.query({
    query: getPointWalletGql,
  });
};

export { getPointWallet };

export type { GetPointWalletResponse };
