import React, { FormEvent, ChangeEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Presenter } from './Presenter';
import { PATHS } from '/@/routes/paths';
import { useAppDispatch, useAppSelector } from '/@/store';
import { STATUS } from '/@/store/api/constants';
import { notifyInquiryThunk } from '/@/store/api/mutations/notifyInquiry';
import { notifyInquiryIdle } from '/@/store/api/mutations/notifyInquiry/actions';
import {
  selectApiStatus,
  selectIsLoading,
} from '/@/store/api/mutations/notifyInquiry/selectors';
import { TopicValues } from '/@/store/page/inquiriesPage/newPage';
import {
  updateContent,
  updateEmail,
  updateName,
  updateTopic,
  updateIsTermsAgreed,
} from '/@/store/page/inquiriesPage/newPage/actions';
import {
  selectContent,
  selectEmail,
  selectIsReadyToSubmit,
  selectIsTermsAgreed,
  selectName,
  selectTopic,
} from '/@/store/page/inquiriesPage/newPage/selectors';
import { useDataLayer } from '/@/common/hooks/useDataLayer';
import { SelectChangeEvent } from '@mui/material';

const Container: React.VFC<Record<string, never>> = () => {
  const dispatch = useAppDispatch();
  const { pushClickEvent } = useDataLayer();

  const apiStatus = useAppSelector(selectApiStatus);
  const isLoading = useAppSelector(selectIsLoading);
  const isReadyToSubmit = useAppSelector(selectIsReadyToSubmit);

  const navigate = useNavigate();

  useEffect(() => {
    if (apiStatus === STATUS.SUCCESS) {
      dispatch(notifyInquiryIdle());
      navigate(PATHS.INQUIRIES_COMPLETION);
    }
  }, [apiStatus, dispatch, navigate]);

  const name = useAppSelector(selectName);
  const email = useAppSelector(selectEmail);
  const topic = useAppSelector(selectTopic);
  const content = useAppSelector(selectContent);
  const isTermsAgreed = useAppSelector(selectIsTermsAgreed);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    pushClickEvent({
      clickItem: 'inquirySubmitButton',
      customData: {
        clickItem: 'inquirySubmitButton',
        topic,
      },
    });

    dispatch(
      notifyInquiryThunk({
        name,
        email,
        topic,
        content,
      }),
    ).catch(() => {});
  };

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateName(event.target.value));
  };

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateEmail(event.target.value));
  };

  const handleChangeTopic = (event: SelectChangeEvent<TopicValues>) => {
    dispatch(updateTopic(event.target.value as TopicValues));
  };

  const handleChangeContent = (event: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(updateContent(event.target.value));
  };

  const handleChangeIsTermsAgreed = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(updateIsTermsAgreed(event.target.checked));
  };

  return (
    <Presenter
      name={name}
      email={email}
      topic={topic}
      content={content}
      isTermsAgreed={isTermsAgreed}
      isReadyToSubmit={isReadyToSubmit}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      handleChangeName={handleChangeName}
      handleChangeEmail={handleChangeEmail}
      handleChangeTopic={handleChangeTopic}
      handleChangeContent={handleChangeContent}
      handleChangeIsTermsAgreed={handleChangeIsTermsAgreed}
    />
  );
};

export { Container as InquiriesNewPage };
