import { Reducer } from 'redux';
import type { GetAnnouncementQuery } from '/@/api/graphql/internalApi/types';
import type { RootAction } from '/@/store/actions';
import { getAnnouncementTypes } from '/@/store/api/queries/getAnnouncement';

type AnnouncementState = GetAnnouncementQuery['userAnnouncement'] | null;

const initialState: AnnouncementState = null;

const announcementDetail: Reducer<AnnouncementState, RootAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getAnnouncementTypes.GET_ANNOUNCEMENT_SUCCESS: {
      return action.announcement;
    }
    default:
      return state;
  }
};

export { announcementDetail };
export type { AnnouncementState };
