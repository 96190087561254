import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '/@/store';
import { getGifteeContentThunk } from '/@/store/api/queries/getGifteeContent';
import { Presenter } from './Presenter';
import { useParams } from 'react-router-dom';

const Container: React.VFC<Record<string, never>> = () => {
  const dispatch = useAppDispatch();
  const params = useParams<'urlCode'>();
  const selectedGifteeContent = useAppSelector(
    (state) => state.app.selectedGifteeContent,
  );
  const getGifteeContentStatus = useAppSelector(
    (state) => state.api.getGifteeContent.status,
  );

  useEffect(() => {
    dispatch(getGifteeContentThunk({ urlCode: params.urlCode })).catch(
      () => {},
    );
  }, [dispatch, params.urlCode]);

  return (
    <Presenter
      selectedGifteeContent={selectedGifteeContent}
      getGifteeContentStatus={getGifteeContentStatus}
    />
  );
};
export { Container as GifteeContent };
