import React from 'react';
import { styled } from '@mui/system';

export const UNREAD_BADGE_SIZE = 12;

const StyledUnreadBadge = styled('span')(({ theme }) => ({
  display: 'inline-block',
  width: `${UNREAD_BADGE_SIZE}px`,
  height: `${UNREAD_BADGE_SIZE}px`,
  borderRadius: '50%',
  backgroundColor: theme.palette.error.main,
}));

const Presenter: React.VFC<Record<string, never>> = () => <StyledUnreadBadge />;

export { Presenter };
