import React from 'react';
import { GifteeContentBase } from '/@/store/app/shared/types';
import { GiftCardGifteeContent } from './giftCardGifteeContent/Index';
import { GifteeBoxGifteeContent } from './gifteeBoxGifteeContent/Index';
import { typeGuards } from '/@/utils/typeGuards';

export type GifteeContent = GifteeContentBase;

type Props = {
  gifteeContent: GifteeContent;
};
const Presenter: React.VFC<Props> = ({ gifteeContent }) => {
  if (typeGuards.app.isGiftCardGifteeContent(gifteeContent))
    return (
      <div data-cy="gifteeContent">
        <GiftCardGifteeContent giftCardGifteeContent={gifteeContent} />
      </div>
    );
  if (typeGuards.app.isGifteeBoxGifteeContent(gifteeContent))
    return (
      <div data-cy="gifteeContent">
        <GifteeBoxGifteeContent gifteeBoxGifteeContent={gifteeContent} />
      </div>
    );
  return <></>;
};

export { Presenter };
