import React from 'react';
import Slider from 'react-slick';
import { Button, Container } from '@mui/material';
import { styled } from '@mui/system';
import { PointFeature } from '/@/api/graphql/internalApi/types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { decidePointFeatureDestination } from '/@/utils/decidePointFeatureDestination';
import { Location } from 'history';

// 特集が複数のパターン
const StyledPointFeaturesContainer = styled(Slider)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(4), // ドット表示分の余白
}));

// 特集が1つのパターン
const StyledPointFeatureContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(1),
  padding: 0,
  width: '100%',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.spacing(1),
  border: '1px solid ' + theme.palette.grey.A200,
  backgroundColor: theme.palette.common.white,
}));

const StyledImage = styled('img')({
  width: '100%',
  height: '80px',
  objectFit: 'contain',
});

type Props = {
  pointFeatures: PointFeature[];
  navigateDestination: (destination: Location) => void;
};

const Presenter: React.VFC<Props> = ({
  pointFeatures,
  navigateDestination,
}) => {
  return pointFeatures.length > 1 ? (
    <StyledPointFeaturesContainer dots autoplay infinite speed={1500}>
      {pointFeatures?.map((PointFeature) => (
        <StyledButton
          key={PointFeature.uid}
          onClick={() =>
            navigateDestination(
              decidePointFeatureDestination(PointFeature.destination),
            )
          }
        >
          <StyledImage src={PointFeature.imageUrl} alt={PointFeature.title} />
        </StyledButton>
      ))}
    </StyledPointFeaturesContainer>
  ) : (
    <StyledPointFeatureContainer>
      <StyledButton
        onClick={() =>
          navigateDestination(
            decidePointFeatureDestination(pointFeatures[0].destination),
          )
        }
      >
        <StyledImage
          src={pointFeatures[0].imageUrl}
          alt={pointFeatures[0].title}
        />
      </StyledButton>
    </StyledPointFeatureContainer>
  );
};

export { Presenter };
