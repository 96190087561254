// MEMO: 型が不明のものが多いため、any型を多用している。
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ApolloClient,
  ApolloLink,
  from,
  split,
  HttpLink,
} from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { createConsumer } from '@rails/actioncable';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import type {
  DefaultOptions,
  OperationVariables,
  MutationOptions,
  QueryOptions,
  ApolloQueryResult,
  ApolloError,
  Observable,
  FetchResult,
} from '@apollo/client';
import type { GraphQLError } from 'graphql';
import type { OperationDefinitionNode, NameNode } from 'graphql/language/ast';
import { MutationErrors } from './client';
import { getGiftWalletApiUri } from './uri';
import { getSessionTokenFromCookie } from '/@/utils/getSessionTokenFromCookie';
import convertFirstLetterToLowercase from '/@/utils/convertFirstLetterToLowercase';

const getBasicAuth = () => {
  const token = getSessionTokenFromCookie();
  const basicAuth = btoa(`${token}:`);
  return basicAuth;
};

const httpLink = new HttpLink({
  uri: `${getGiftWalletApiUri()}/internal_api/graphql`,
});

const cable = () =>
  createConsumer(
    `${getGiftWalletApiUri()}/cable?token=${getSessionTokenFromCookie()}`,
  );

const isSubscriptionOperation = ({
  query: { definitions },
}: {
  query: { definitions: Array<{ kind: string; operation: string }> | any };
}) =>
  definitions.some(
    ({ kind, operation }: { kind: string; operation: string }) =>
      kind === 'OperationDefinition' && operation === 'subscription',
  );

const authMiddleware = () =>
  new ApolloLink((operation, forward): Observable<FetchResult> | null => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: `Basic ${getBasicAuth()}`,
      },
    }));

    return forward ? forward(operation) : null;
  });

// eslint-disable-next-line no-empty-pattern
const errorMiddleware = onError(({}) => {
  // Do something
  // if (networkError.statusCode === 401) {
  // }
});

const cache = new InMemoryCache({ addTypename: false });

const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const createClient = (isRejectError: boolean = false) => {
  const options = {
    link: split(
      isSubscriptionOperation,
      new ActionCableLink({
        cable: cable(),
        channelName: 'InternalAPI::GraphqlChannel',
      }),
      from([authMiddleware(), errorMiddleware, httpLink]),
    ),
    cache,
    defaultOptions,
  };
  return isRejectError
    ? new AppApolloClient(options)
    : new ApolloClient(options);
};

type MutationResponse = FetchResult<{ [key: string]: any }>;
type QueryResponse = ApolloQueryResult<{ [key: string]: any }>;
export type Response = MutationResponse | QueryResponse;

export type ApiError =
  | ApolloError
  | ReadonlyArray<GraphQLError>
  | MutationErrors;

type MutationName = NameNode['value'];

export class AppApolloClient<TCacheSape> extends ApolloClient<TCacheSape> {
  private hasGraphqlError(response: Response): boolean {
    return response.errors !== undefined;
  }

  private rejectGraphqlErrorIfNeeded<TResult extends Response>(): (
    response: TResult,
  ) => Promise<TResult> {
    return (response) => {
      if (this.hasGraphqlError(response)) {
        return Promise.reject(response.errors);
      }
      return Promise.resolve(response);
    };
  }

  private getMutationName<T = any, TVariables = OperationVariables>(
    options: MutationOptions<T, TVariables>,
  ): MutationName {
    const definition = options.mutation.definitions.find(
      (definition) => definition.kind === 'OperationDefinition',
    ) as OperationDefinitionNode;
    const mutationName = definition?.name ? definition.name.value : '';
    const convertedMutationName = convertFirstLetterToLowercase(mutationName);
    return convertedMutationName;
  }

  private hasMutationError(
    mutationName: MutationName,
    response: FetchResult<{ [key: string]: any }>,
  ): boolean {
    const { data } = response;
    const mutationNameObject = data ? data[mutationName] : undefined;
    const errors = mutationNameObject
      ? (mutationNameObject.errors as Array<any>)
      : undefined;
    return !!data && !!errors && errors.length > 0;
  }

  private rejectMutationErrorIfNeeded<T = any, TVariables = OperationVariables>(
    options: MutationOptions<T, TVariables>,
  ): (response: Response) => Promise<Response> {
    return (response) => {
      const mutationName = this.getMutationName(options);
      if (this.hasMutationError(mutationName, response)) {
        const mutationErrors: MutationErrors =
          !!response.data && response.data[mutationName].errors;
        return Promise.reject(mutationErrors);
      }
      return Promise.resolve(response);
    };
  }

  private rejectErrorIfNeeded<T = any, TVariables = OperationVariables>(
    options: MutationOptions<T, TVariables>,
  ): (response: FetchResult<any>) => Promise<FetchResult<any>> {
    return (response) =>
      this.rejectMutationErrorIfNeeded<T, TVariables>(options)(response).then(
        this.rejectGraphqlErrorIfNeeded<FetchResult<typeof response>>(),
      );
  }

  mutate<T = any, TVariables = OperationVariables>(
    options: MutationOptions<T, TVariables>,
  ): Promise<FetchResult<T>>;
  mutate<T = any, TVariables = OperationVariables>(
    options: MutationOptions<T, TVariables>,
  ): Promise<FetchResult<T>>;

  public mutate<
    T extends MutationResponse['data'],
    TVariables extends OperationVariables,
  >(options: MutationOptions<T, TVariables>): Promise<FetchResult<T>> {
    return super
      .mutate(options)
      .then(this.rejectErrorIfNeeded<T, TVariables>(options));
  }

  query<T = any, TVariables = OperationVariables>(
    options: QueryOptions<TVariables>,
  ): Promise<ApolloQueryResult<T>>;

  public query<
    T extends QueryResponse['data'],
    TVariables extends OperationVariables,
  >(options: QueryOptions<TVariables>): Promise<ApolloQueryResult<T>> {
    return super
      .query(options)
      .then(this.rejectGraphqlErrorIfNeeded<ApolloQueryResult<T>>());
  }
}

export const internalApiAppSubscriptionClient = () =>
  getSessionTokenFromCookie() ? createClient(true) : undefined;
