import dayjs from 'dayjs';

export const formatToMonthJa = (dateString: string): string =>
  dayjs(dateString).format('YYYY年 M月');
export const formatToEndMonthJa = (dateString: string): string => {
  const dayjsDate = getSubstractedDateIfNeeded(dateString);
  return dayjsDate.format('YYYY年 M月');
};

export const formatToDateJa = (dateString: string): string =>
  dayjs(dateString).format('YYYY年 M月D日');

export const formatToEndDateJa = (dateString: string): string => {
  const dayjsDate = getSubstractedDateIfNeeded(dateString);
  return dayjsDate.format('YYYY年 M月D日');
};

export const formatToDateAndTimeJa = (dateString: string): string => {
  const dayjsDate = getSubstractedDateIfNeeded(dateString);
  return dayjsDate.format('YYYY年 M月D日 HH:mm');
};

const getSubstractedDateIfNeeded = (dateString: string): dayjs.Dayjs => {
  const dayjsDate = dayjs(dateString);
  return isMidNight(dayjsDate) ? dayjsDate.subtract(1, 'd') : dayjsDate;
};

const isMidNight = (dayjsDate: dayjs.Dayjs): boolean =>
  dayjsDate.hour() === 0 &&
  dayjsDate.minute() === 0 &&
  dayjsDate.second() === 0;

export const formatedISO8601Date = (dateString: string): string => {
  const formatedDate = dayjs(dateString).format('YYYY-MM-DD HH:mm:ss');
  return formatedDate;
};
