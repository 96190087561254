import {
  PointFeatureDestination,
  PointFeatureDestinationKindEnum,
} from '/@/api/graphql/internalApi/types';
import { PATHS } from '/@/routes/paths';
import { Location } from 'history';

const decidePointFeatureDestination = (
  pointFeatureDestination: PointFeatureDestination,
): Location => {
  if (pointFeatureDestination.kind === PointFeatureDestinationKindEnum.Brand) {
    return {
      pathname: PATHS.BRAND_ITEMS.replace(':uid', pointFeatureDestination.uid),
      search: '',
      hash: '',
      state: undefined,
      key: PATHS.BRAND_ITEMS.replace(':uid', pointFeatureDestination.uid),
    };
  } else {
    // TODO: itemの場合の処理を追加(詳細モーダル表示のみ？)
    return {
      pathname: PATHS.POINT_BRANDS,
      search: '',
      hash: '',
      state: undefined,
      key: PATHS.POINT_BRANDS,
    };
  }
};

export { decidePointFeatureDestination };
