import { FetchResult } from '@apollo/client';
import { publicApiClient } from '/@/api/graphql/appApolloClient';
import { getOauthSessionGql } from './index.gql';
import {
  GetOauthSessionQuery,
  GetOauthSessionQueryVariables,
} from '/@/api/graphql/publicApi/types';

type GetOauthSessionResponse = FetchResult<GetOauthSessionQuery>;

const getOauthSession = (
  variables: GetOauthSessionQueryVariables,
): Promise<GetOauthSessionResponse> =>
  publicApiClient.query({
    query: getOauthSessionGql,
    variables,
  });

export { getOauthSession };

export type { GetOauthSessionResponse };
