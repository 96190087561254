import { getPointFeatureGroups } from '/@/api/graphql/internalApi/queries/getPointFeatureGroups';
import type { GetPointFeatureGroupsResponse } from '/@/api/graphql/internalApi/queries/getPointFeatureGroups';
import { GetPointFeatureGroupsQueryVariables } from '/@/api/graphql/internalApi/types';
import type { AppThunk } from '/@/store';
import {
  getPointFeatureGroupsStart,
  getPointFeatureGroupsSuccess,
  getPointFeatureGroupsFailure,
} from './actions';
import { handleErrorThunk } from '../../handleError';
import type { ApiError } from '/@/api/graphql/client';

const getPointFeatureGroupsThunk =
  (variables: GetPointFeatureGroupsQueryVariables): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getPointFeatureGroupsStart());

    return getPointFeatureGroups({
      after: null,
      first: 10,
      ...variables,
    })
      .then((response) => dispatch(getPointFeatureGroupsSuccessThunk(response)))
      .catch((error) => dispatch(getPointFeatureGroupsFailureThunk(error)));
  };

const getPointFeatureGroupsSuccessThunk =
  (response: GetPointFeatureGroupsResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { pointFeatureGroups } = response.data!;
    dispatch(getPointFeatureGroupsSuccess(pointFeatureGroups));
    return Promise.resolve();
  };

const getPointFeatureGroupsFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getPointFeatureGroupsFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { getPointFeatureGroupsThunk };
