import React from 'react';
import { Presenter } from './Presenter';
import { LpTypes } from '../types';

type OwnProps = {
  lpType: LpTypes;
  subsection1ImageURL: string;
  subsection3ImageURL: string;
};
const Container: React.FC<OwnProps> = ({
  lpType,
  subsection1ImageURL,
  subsection3ImageURL,
}) => {
  return (
    <Presenter
      lpType={lpType}
      subsection1ImageURL={subsection1ImageURL}
      subsection3ImageURL={subsection3ImageURL}
    />
  );
};
export { Container as BenefitsOfConvertSection };
