import React from 'react';
import { RecommendedContents } from './recommendedContents/Index';
import { ExchangeableContents } from './exchangeableContents/Index';
import { Button, Checkbox, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { DisplayOnlyExchangeableBrandsState } from '/@/store/app/displayOnlyExchangeableBrands';

const StyledSwitchDisplayOnlyExchangeableContainer = styled(Button)({
  display: 'flex',
  justifyContent: 'flex-end',
  marginLeft: 'auto',
  padding: 0,
});
const StyledSwitchDisplayOnlyExchangeableText = styled(Typography)({
  fontSize: '0.75rem',
  lineHeight: '0.75rem',
  color: 'initial',
});
const StyledSectionTitle = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  fontSize: '0.75rem',
  lineHeight: '0.75rem',
  fontWeight: 'bold',
  '&:not(:first-child)': {
    marginTop: theme.spacing(3),
  },
}));

type Props = {
  isDisplayOnlyExchangeableBrands: DisplayOnlyExchangeableBrandsState;
  handleDisplayOnlyExchangeable: () => void;
};
const Presenter: React.VFC<Props> = ({
  isDisplayOnlyExchangeableBrands,
  handleDisplayOnlyExchangeable,
}) => (
  <>
    <StyledSwitchDisplayOnlyExchangeableContainer
      onClick={handleDisplayOnlyExchangeable}
    >
      <StyledSwitchDisplayOnlyExchangeableText variant="subtitle2">
        交換可能なチケットに絞る
      </StyledSwitchDisplayOnlyExchangeableText>
      <Checkbox
        checked={isDisplayOnlyExchangeableBrands}
        data-cy="checkBox-displayOnlyExchangeable"
        color="primary"
        style={{ padding: 0, marginLeft: 4 }}
      />
    </StyledSwitchDisplayOnlyExchangeableContainer>
    <StyledSectionTitle variant="subtitle1">
      今交換するとお得なチケット
    </StyledSectionTitle>
    <RecommendedContents />
    <StyledSectionTitle variant="subtitle1">チケット一覧</StyledSectionTitle>
    <ExchangeableContents />
  </>
);

export { Presenter };
