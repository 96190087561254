import React from 'react';
import { Typography, Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/system';
import { formatToDateAndTimeJa } from '/@/utils/formatDate';
import ArrowRightIcon from '/@/assets/shared/icon/arrow-right-icon.svg';
import { AnnouncementItemType } from './Index';
import { UnreadBadge } from '/@/components/shared/badge/unreadBadge/Index';
import { UNREAD_BADGE_SIZE } from '/@/components/shared/badge/unreadBadge/Presenter';

const StyledCard = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
}));
const StyledCardContent = styled(Grid)(({ theme }) => ({
  minWidth: 0,
  paddingLeft: theme.spacing(4),
  textAlign: 'left',
  position: 'relative',
}));
const StyledTitle = styled(Typography)(({ theme }) => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-line-clamp': 2,
  '-webkit-box-orient': 'vertical',
  color: theme.palette.secondary.dark,
  fontSize: '0.875rem',
  lineHeight: '1.2',
  paddingBottom: theme.spacing(1.5),
  fontWeight: 'bold',
}));
const StyledCreatedAt = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
  fontSize: '0.875rem',
  lineHeight: '1',
  fontWeight: 'bold',
}));
const StyledArrowRightIcon = styled('img')({});
const StyledUnreadBadge = styled('div')({
  position: 'absolute',
  top: `calc(50% - ${UNREAD_BADGE_SIZE}px)`,
  left: '0',
});

type Props = {
  announcement: AnnouncementItemType;
  navigateAnnouncementDetail: () => void;
};

const Presenter: React.VFC<Props> = ({
  announcement,
  navigateAnnouncementDetail,
}) => (
  <StyledCard data-cy="pointLog" onClick={navigateAnnouncementDetail}>
    <Grid container wrap="nowrap" direction="row">
      <StyledCardContent
        size={{ xs: 9 }}
        container
        justifyContent="left"
        direction="column"
      >
        {announcement.readAt === null && (
          <StyledUnreadBadge>
            <UnreadBadge />
          </StyledUnreadBadge>
        )}
        <StyledTitle variant="subtitle1">{announcement.title}</StyledTitle>
        <StyledCreatedAt variant="subtitle2">
          {formatToDateAndTimeJa(announcement.createdAt)}
        </StyledCreatedAt>
      </StyledCardContent>
      <Grid
        size={{ xs: 3 }}
        container
        justifyContent="flex-end"
        alignItems="center"
      >
        <StyledArrowRightIcon src={ArrowRightIcon} alt="arrowRightIcon" />
      </Grid>
    </Grid>
  </StyledCard>
);

export { Presenter };
