import React from 'react';
import { Typography } from '@mui/material';
import { Grid2 as Grid, Container } from '@mui/material';
import { styled } from '@mui/system';
import { HeroSection } from './heroSection/Index';
import { AboutSection } from '/@/components/shared/lp/aboutSection/Index';
import { BenefitsOfConvertSection } from '/@/components/shared/lp/benefitsOfConvertSection/Index';
import { HowToUseSection } from '/@/components/shared/lp/howToUseSection/Index';
import { CampaignSection } from '/@/components/shared/lp/campaignSection/Index';
import { CautionText } from '/@/components/shared/cautionText/Index';
import { Footer } from '/@/components/templates/footer/Index';
import {
  LoginButton,
  LoginOption,
} from '/@/components/shared/loginButton/Index';
import backgroundImage from '/@/assets/page/lpPage/lp-hero-background.png';
import { LP_TYPES } from '/@/components/shared/lp/types';
import { PATHS } from '/@/routes/paths';
import { formatedISO8601Date } from '/@/utils/formatDate';
import { publicAssetsListForLpChargeSerialCodePage } from '/@/utils/constPublicAssetsList';

const StyledContainer = styled(Container)({
  padding: 0,
});
const StyledLoginSectionContainer = styled('section')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
}));
const StyledSerialCodeOutOfPeriodMessage = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  textAlign: 'center',
  fontWeight: 'bold',
  color: theme.palette.warning.main,
}));
const StyledSerialCodePeriodMessage = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  textAlign: 'center',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
}));
const StyledHeroSection = styled('section')({
  width: '100%',
  backgroundImage: `url(${backgroundImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});
const StyledAboutSection = styled('section')(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.contrast,
}));
const StyledUsageSectionContainer = styled('section')(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.hint,
}));
const StyledHowToUseSectionContainer = styled('section')({
  width: '100%',
});
const StyledLoginContainer = styled('section')(({ theme }) => ({
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  width: '100%',
  backgroundColor: theme.palette.background.contrast,
}));
const StyledCautionTextContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

type Props = LoginOption & {
  isSerialCodeOutOfPeriod: boolean;
  serialCodeEndAt: string | null;
  publicAssetsImage: (key: string) => string;
};

const Presenter: React.VFC<Props> = ({
  url,
  origin,
  referer,
  convertToPoint,
  destination,
  serialCode,
  isSerialCodeOutOfPeriod,
  serialCodeEndAt,
  publicAssetsImage,
}) => (
  <Grid container direction="column" alignContent="center">
    <StyledHeroSection>
      <StyledContainer maxWidth="sm">
        <HeroSection>
          <StyledContainer maxWidth="sm">
            {isSerialCodeOutOfPeriod && (
              <StyledSerialCodeOutOfPeriodMessage
                variant="body2"
                data-cy="serialCodePage-expiredAlertMessage"
              >
                {`このURLは有効期限切れです`}
              </StyledSerialCodeOutOfPeriodMessage>
            )}
            {serialCodeEndAt && !isSerialCodeOutOfPeriod && (
              <StyledSerialCodePeriodMessage
                variant="body2"
                data-cy="serialCodePage-expiredAlertMessage"
              >
                {`チャージの有効期限は${formatedISO8601Date(serialCodeEndAt.replace(' ', '+'))}です`}
              </StyledSerialCodePeriodMessage>
            )}
            <StyledLoginSectionContainer>
              <LoginButton
                url={url}
                origin={origin}
                referer={referer}
                convertToPoint={convertToPoint}
                destination={destination}
                serialCode={serialCode}
                flow={PATHS.LP_CHARGE_SERIAL_CODE}
              >
                ポイントをチャージする
              </LoginButton>
            </StyledLoginSectionContainer>
          </StyledContainer>
        </HeroSection>
      </StyledContainer>
    </StyledHeroSection>
    <section>
      <StyledContainer maxWidth="sm">
        <CampaignSection
          highReturnBrandsImageURL={publicAssetsImage(
            publicAssetsListForLpChargeSerialCodePage.HIGH_RETURN_BRANDS_IMAGE,
          )}
          exchangeableBrandsImageURL={publicAssetsImage(
            publicAssetsListForLpChargeSerialCodePage.EXCHANGEABLE_BRANDS_IMAGE,
          )}
        />
      </StyledContainer>
    </section>
    <StyledAboutSection>
      <StyledContainer maxWidth="sm">
        <AboutSection lpType={LP_TYPES.LP_CHARGE_SERIAL_CODE}>
          <StyledLoginSectionContainer>
            <LoginButton
              url={url}
              origin={origin}
              referer={referer}
              convertToPoint={convertToPoint}
              destination={destination}
              serialCode={serialCode}
              flow={PATHS.LP_CHARGE_SERIAL_CODE}
            >
              ポイントをチャージする
            </LoginButton>
          </StyledLoginSectionContainer>
        </AboutSection>
      </StyledContainer>
    </StyledAboutSection>
    <StyledUsageSectionContainer>
      <StyledContainer maxWidth="sm">
        <BenefitsOfConvertSection
          lpType={LP_TYPES.LP_CHARGE_SERIAL_CODE}
          subsection1ImageURL={publicAssetsImage(
            publicAssetsListForLpChargeSerialCodePage.BENEFITS_OF_CONVERT_SECTION_SUBSECTION1,
          )}
          subsection3ImageURL={publicAssetsImage(
            publicAssetsListForLpChargeSerialCodePage.BENEFITS_OF_CONVERT_SECTION_SUBSECTION3,
          )}
        />
      </StyledContainer>
    </StyledUsageSectionContainer>
    <StyledHowToUseSectionContainer>
      <StyledContainer maxWidth="sm">
        <HowToUseSection />
      </StyledContainer>
    </StyledHowToUseSectionContainer>
    <StyledLoginContainer>
      <StyledContainer maxWidth="sm">
        <StyledLoginSectionContainer>
          <LoginButton
            url={url}
            origin={origin}
            referer={referer}
            convertToPoint={convertToPoint}
            destination={destination}
            serialCode={serialCode}
            flow={PATHS.LP_CHARGE_SERIAL_CODE}
          >
            ポイントをチャージする
          </LoginButton>
        </StyledLoginSectionContainer>
        <StyledCautionTextContainer>
          <CautionText />
        </StyledCautionTextContainer>
      </StyledContainer>
    </StyledLoginContainer>
    <Footer />
  </Grid>
);

export { Presenter };
