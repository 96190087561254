import React from 'react';
import {
  Grid2 as Grid,
  Typography,
  Button,
  ButtonProps,
  Divider,
} from '@mui/material';
import { styled } from '@mui/system';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { MyGift } from '/@/components/shared/myGift/Index';
import { GroupedAvailableMyGifts } from '/@/store/app/availableMyGiftsConnection/selectors';
import { PATHS } from '/@/routes/paths';
import { Link } from 'react-router-dom';

const StyledAvailableGiftsContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  paddingBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.hint,
}));
const StyledGroupedLabelText = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  marginTop: theme.spacing(3),
  fontWeight: 'bold',
  lineHeight: '1',
  color: theme.palette.secondary.dark,
}));
const StyledMyGiftCard = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  borderRadius: theme.spacing(1.5),
  boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.1)',
  overflow: 'hidden',
}));
const StyledAvailableMyGiftsNotFoundContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
}));
const StyledAvailableMyGiftsNotFoundTextContainer = styled('div')(
  ({ theme }) => ({
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  }),
);
const StyledAvailableMyGiftsNotFoundText = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
}));
type StyledButtonProps = ButtonProps & { to: string };
const StyledPromotionsPageButton = styled(Button)<StyledButtonProps>(
  ({ theme }) => ({
    fontWeight: 'bold',
    borderRadius: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
  }),
);

type Props = {
  groupedAvailableMyGifts: GroupedAvailableMyGifts;
  infiniteScrollTarget: React.RefObject<HTMLDivElement>;
  getAvailableMyGiftsConnectionStatus: Status;
};

const Presenter: React.VFC<Props> = ({
  groupedAvailableMyGifts,
  infiniteScrollTarget,
  getAvailableMyGiftsConnectionStatus,
}) => (
  <Grid data-cy="availableMyGifts">
    {Object.keys(groupedAvailableMyGifts).length ? (
      <StyledAvailableGiftsContainer>
        {Object.entries(groupedAvailableMyGifts).map(([date, myGifts]) => (
          <React.Fragment key={date}>
            <StyledGroupedLabelText variant="caption">
              {date}
            </StyledGroupedLabelText>
            {myGifts?.map(
              (myGift) =>
                myGift && (
                  <StyledMyGiftCard key={myGift.urlCode}>
                    <MyGift myGift={myGift} />
                  </StyledMyGiftCard>
                ),
            )}
          </React.Fragment>
        ))}
      </StyledAvailableGiftsContainer>
    ) : (
      getAvailableMyGiftsConnectionStatus !== STATUS.IDLE &&
      getAvailableMyGiftsConnectionStatus !== STATUS.LOADING && (
        <StyledAvailableMyGiftsNotFoundContainer
          container
          justifyContent="center"
          direction="column"
        >
          <StyledAvailableMyGiftsNotFoundTextContainer>
            <StyledAvailableMyGiftsNotFoundText variant="body2">
              現在ご利用可能なギフトはありません。
            </StyledAvailableMyGiftsNotFoundText>
            <StyledAvailableMyGiftsNotFoundText variant="body2">
              以下のページからギフトをもらえます！
            </StyledAvailableMyGiftsNotFoundText>
          </StyledAvailableMyGiftsNotFoundTextContainer>
          <Divider />
          <StyledPromotionsPageButton
            variant="contained"
            color="primary"
            disableElevation
            component={Link}
            to={PATHS.PROMOTIONS}
            data-cy="promotionsPageButton"
          >
            もらえるギフトを確認する
          </StyledPromotionsPageButton>
        </StyledAvailableMyGiftsNotFoundContainer>
      )
    )}
    {getAvailableMyGiftsConnectionStatus === STATUS.LOADING && (
      <PartialLoader />
    )}
    <div ref={infiniteScrollTarget} />
  </Grid>
);

export { Presenter };
