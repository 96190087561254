import React, { useEffect, useState } from 'react';
import { Presenter } from './Presenter';
import { isPointsCharged } from '/@/api/graphql/internalApi/queries/getPointLogs';

type OwnProps = {
  skipConfirm?: boolean;
};

const Container: React.VFC<OwnProps> = ({ skipConfirm }) => {
  const [hasCharged, setHasCharged] = useState<boolean | null>(null);

  useEffect(() => {
    isPointsCharged(setHasCharged);
  }, []);

  return <Presenter hasCharged={hasCharged} skipConfirm={skipConfirm} />;
};

export { Container as PointChargeSection };
