import { FetchResult } from '@apollo/client';
import { internalApiClient } from '/@/api/graphql/appApolloClient';
import { readAnnouncementsGql } from './index.gql';
import {
  ReadUserAnnouncementsInput,
  ReadAnnouncementsMutation,
} from '/@/api/graphql/internalApi/types';

type ReadUserAnnouncementsResponse = FetchResult<ReadAnnouncementsMutation>;

const readAnnouncements = (
  input: ReadUserAnnouncementsInput,
): Promise<ReadUserAnnouncementsResponse> => {
  return internalApiClient.mutate({
    mutation: readAnnouncementsGql,
    variables: input,
  });
};

export { readAnnouncements };

export type { ReadUserAnnouncementsResponse };
