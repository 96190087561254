import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '/@/store';
import { App } from './App';
import { useLocation, useNavigate } from 'react-router-dom';
import { changePageActions } from '/@/store/app/changePage';
import { PATHS } from '../routes/paths';
import { ApolloClientErrors } from '/@/store/api/handleError';
import { useDataLayer } from '/@/common/hooks/useDataLayer';
import { OriginEnum } from '../api/graphql/publicApi/types';

const Container: React.VFC<Record<string, never>> = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { pushPageViewEvent } = useDataLayer();

  // クエリストリング集計
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const origin = urlSearchParams.get('gift_origin') as OriginEnum | null;
  const referer = urlSearchParams.get('referer');
  const minPoint = urlSearchParams.get('minPoint');
  const maxPoint = urlSearchParams.get('maxPoint');

  useEffect(() => {
    dispatch(changePageActions.changePage(location));
    pushPageViewEvent({
      page: location.pathname,
      customData: {
        page: location.pathname,
        ...(origin && { origin: origin }),
        ...(referer && { referer: referer }),
        ...(minPoint && { minPoint: minPoint }),
        ...(maxPoint && { maxPoint: maxPoint }),
      },
    });
    // MEMO: pushPageViewEventはcurrentUserやcurrentUserApiStatusに依存し更新されるため一旦監視対象から除外
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location, origin, referer, minPoint, maxPoint]);

  const navigate = useNavigate();
  const pageError = useAppSelector((state) => state.app.pageError);

  useEffect(() => {
    // MEMO: 401エラー発生時はログイン画面にリダイレクトする
    if (
      location.pathname !== PATHS.SIGN_IN &&
      pageError?.status === ApolloClientErrors.AUTHENTICATION_ERROR.status
    ) {
      navigate(PATHS.SIGN_IN, {
        state: { errorMessage: pageError.message },
        replace: true,
      });
    }
  }, [navigate, pageError, location.pathname]);

  return <App pageError={pageError} />;
};

export { Container as App };
