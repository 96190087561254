import React, { FormEvent, ChangeEvent } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid2 as Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Box,
  Chip,
  Container,
  SelectChangeEvent,
} from '@mui/material';
import { styled } from '@mui/system';
import { MainLayout } from '/@/components/templates/mainLayout/Index';
import { LoginModal } from './loginModal/Index';
import type {
  InquiriesNewPageInputState,
  TopicValues,
} from '/@/store/page/inquiriesPage/newPage';
import { TOPICS } from '/@/store/page/inquiriesPage/newPage';
import { LoadingButton } from '/@/components/shared/button/loadingButton/Index';
import { HeadingWithTitle } from '/@/components/shared/headingWithTitle/Index';

type Props = {
  name: InquiriesNewPageInputState['name'];
  email: InquiriesNewPageInputState['email'];
  topic: InquiriesNewPageInputState['topic'];
  content: InquiriesNewPageInputState['content'];
  isTermsAgreed: boolean;
  isReadyToSubmit: boolean;
  isLoading: boolean;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  handleChangeName: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeEmail: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeTopic: (event: SelectChangeEvent<TopicValues>) => void;
  handleChangeContent: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  handleChangeIsTermsAgreed: (event: ChangeEvent<HTMLInputElement>) => void;
};

const StyledBackground = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.contrast,
}));
const StyledMainContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
}));
const StyledBaseBox = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.spacing(2),
}));
const StyledCautionBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.hint,
  borderRadius: theme.spacing(),
}));
const StyledGridItem = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));
const StyledSubmitButton = styled(LoadingButton)(({ theme }) => ({
  padding: theme.spacing(),
  fontWeight: 'bold',
  borderRadius: theme.spacing(2.8),
}));
const StyledFormControlLabel = styled(FormControlLabel)({
  '& .MuiFormControlLabel-asterisk': {
    display: 'none',
  },
});
const StyledFormLabelContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(),
}));
const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
}));
const StyledFormLabelChip = styled(Chip)(({ theme }) => ({
  fontWeight: 'bold',
  borderRadius: theme.spacing(0.5),
  backgroundColor: theme.palette.primary.dark,
  marginRight: theme.spacing(),
  marginLeft: theme.spacing(),
}));
const StyledInquiryContentDescription = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(),
  color: theme.palette.secondary.dark,
}));
const StyledPrivacyPolicyLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const Presenter: React.VFC<Props> = ({
  name,
  email,
  topic,
  content,
  isTermsAgreed,
  isReadyToSubmit,
  isLoading,
  handleSubmit,
  handleChangeName,
  handleChangeEmail,
  handleChangeTopic,
  handleChangeContent,
  handleChangeIsTermsAgreed,
}) => (
  <MainLayout>
    <StyledBackground>
      <StyledMainContainer maxWidth="sm">
        <HeadingWithTitle title="CONTACT" subTitle="お問い合わせ" />
        <form onSubmit={handleSubmit}>
          <StyledBaseBox>
            <Grid container direction="column" alignItems="stretch" spacing={2}>
              <StyledGridItem>
                <FormControl fullWidth>
                  <StyledFormLabelContainer
                    container
                    direction="row"
                    alignItems="center"
                  >
                    <StyledFormLabel>お名前</StyledFormLabel>
                    <StyledFormLabelChip
                      label="必須"
                      color="primary"
                      size="small"
                    />
                  </StyledFormLabelContainer>
                  <TextField
                    id="inquiry-name"
                    placeholder="山田太郎"
                    required
                    variant="standard"
                    value={name}
                    autoComplete="name"
                    onChange={handleChangeName}
                    slotProps={{
                      inputLabel: { shrink: true },
                    }}
                  />
                </FormControl>
              </StyledGridItem>
              <StyledGridItem>
                <FormControl fullWidth>
                  <StyledFormLabelContainer
                    container
                    direction="row"
                    alignItems="center"
                  >
                    <StyledFormLabel>メールアドレス</StyledFormLabel>
                    <StyledFormLabelChip
                      label="必須"
                      color="primary"
                      size="small"
                    />
                  </StyledFormLabelContainer>
                  <TextField
                    id="inquiry-email-address"
                    placeholder="giftwallet@example.com"
                    required
                    variant="standard"
                    value={email}
                    autoComplete="email"
                    onChange={handleChangeEmail}
                    slotProps={{
                      htmlInput: {
                        type: 'email',
                      },
                      inputLabel: { shrink: true },
                    }}
                  />
                </FormControl>
              </StyledGridItem>
              <StyledGridItem>
                <FormControl fullWidth>
                  <StyledFormLabelContainer
                    container
                    direction="row"
                    alignItems="center"
                  >
                    <StyledFormLabel id="inquiry-topic-label">
                      お問い合わせの種類
                    </StyledFormLabel>
                    <StyledFormLabelChip
                      label="必須"
                      color="primary"
                      size="small"
                    />
                  </StyledFormLabelContainer>
                  <Select
                    autoWidth
                    labelId="inquiry-topic-label"
                    id="inquiry-topic"
                    variant="standard"
                    value={topic}
                    onChange={handleChangeTopic}
                  >
                    {Object.entries(TOPICS).map(([key, value]) => (
                      <MenuItem key={key} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </StyledGridItem>
              <StyledGridItem>
                <FormControl fullWidth>
                  <StyledFormLabelContainer
                    container
                    direction="row"
                    alignItems="center"
                  >
                    <StyledFormLabel>お問い合わせ内容</StyledFormLabel>
                    <StyledFormLabelChip
                      label="必須"
                      color="primary"
                      size="small"
                    />
                    <StyledInquiryContentDescription variant="body2">
                      できるだけ具体的に記入してください。より早く、より適切なお返事を送ることができます。
                    </StyledInquiryContentDescription>
                  </StyledFormLabelContainer>
                  <TextField
                    id="inquiry-content"
                    fullWidth
                    multiline
                    minRows={5}
                    required
                    variant="outlined"
                    value={content}
                    placeholder="お問い合わせ内容"
                    onChange={handleChangeContent}
                    slotProps={{
                      inputLabel: { shrink: true },
                    }}
                  />
                </FormControl>
              </StyledGridItem>
              <StyledGridItem>
                <StyledCautionBox>
                  <Typography variant="body2" gutterBottom>
                    ※迷惑メールの対策、キャリアメール等、ドメイン指定を行っている場合、メールが受信できない場合がございます。「@giftee.co」を受信設定してください。
                  </Typography>
                  <Typography variant="body2">
                    ※メールの対応は平日のみとなっております。週末や祝日にいただいたお問い合わせは、翌営業日以降（平日）に確認させていただきますのでご了承ください。
                  </Typography>
                </StyledCautionBox>
              </StyledGridItem>
              <StyledGridItem>
                <StyledFormControlLabel
                  label={
                    <Typography variant="body2">
                      <StyledPrivacyPolicyLink
                        href="https://giftee.com/privacy_policy/"
                        target="_blank"
                        rel="noopener noreferrer"
                        data-cy="termsOfServiceLink"
                      >
                        「お問い合わせに関する個人情報のお取り扱いについて」
                      </StyledPrivacyPolicyLink>
                      に同意する
                    </Typography>
                  }
                  control={
                    <Checkbox
                      name="agreeWithTheTreatmentOfPersonalData"
                      color="primary"
                      required
                      checked={isTermsAgreed}
                      onChange={handleChangeIsTermsAgreed}
                    />
                  }
                />
              </StyledGridItem>
              <StyledGridItem>
                <StyledSubmitButton
                  disableElevation
                  data-cy="submitButton"
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  disabled={!isReadyToSubmit}
                  loading={isLoading}
                >
                  送信する
                </StyledSubmitButton>
              </StyledGridItem>
            </Grid>
          </StyledBaseBox>
        </form>
      </StyledMainContainer>
    </StyledBackground>
    <LoginModal />
  </MainLayout>
);

export { Presenter };
