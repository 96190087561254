import {
  getMyGiftsConnection,
  GetMyGiftsConnectionResponse,
} from '/@/api/graphql/internalApi/queries/getMyGiftsConnection';
import type { AppThunk } from '/@/store';
import {
  getMyGiftsConnectionStart,
  getMyGiftsConnectionSuccess,
  getMyGiftsConnectionFailure,
} from './actions';
import { handleErrorThunk } from '../../handleError';
import type { ApiError } from '/@/api/graphql/client';
import {
  GetMyGiftsConnectionQueryVariables,
  MyGiftSortConditionTargetEnumType,
  MyGiftUsageStatusEnum,
  PageInfo,
  SortDirectionEnum,
} from '/@/api/graphql/internalApi/types';
import dayjs from 'dayjs';

const getPointConvertableMyGiftsConnectionThunk = (
  variables: GetMyGiftsConnectionQueryVariables,
): AppThunk<Promise<void>> =>
  getMyGiftsConnectionThunk(
    {
      ...variables,
      pointMergeableEq: true,
      usageStatusIn: [MyGiftUsageStatusEnum.Available],
    },
    MYGIFTS_PATTERN.POINT_CONVERTABLE,
  );

const getAvailableMyGiftsConnectionThunk = (
  after: PageInfo['endCursor'] | null = null,
): AppThunk<Promise<void>> =>
  getMyGiftsConnectionThunk(
    {
      usageStatusIn: [MyGiftUsageStatusEnum.Available],
      first: 20,
      after,
    },
    MYGIFTS_PATTERN.AVAILABLE,
  );

const getExpiringMyGiftsConnectionThunk = (
  after: PageInfo['endCursor'] | null = null,
): AppThunk<Promise<void>> =>
  getMyGiftsConnectionThunk(
    {
      sortConditions: [
        {
          direction: SortDirectionEnum.Asc,
          target: MyGiftSortConditionTargetEnumType.ExpiredAt,
        },
      ],
      usageStatusIn: [MyGiftUsageStatusEnum.Available],
      expiredAtGteq: dayjs().toISOString(),
      expiredAtLteq: dayjs().add(7, 'days').toISOString(),
      first: 20,
      after,
    },
    MYGIFTS_PATTERN.EXPIRING,
  );

const getUnavailableMyGiftsConnectionThunk = (
  after: PageInfo['endCursor'] | null = null,
): AppThunk<Promise<void>> =>
  getMyGiftsConnectionThunk(
    {
      sortConditions: [
        {
          direction: SortDirectionEnum.Desc,
          target: MyGiftSortConditionTargetEnumType.UnavailableAt,
        },
      ],
      usageStatusIn: [
        MyGiftUsageStatusEnum.Discarded,
        MyGiftUsageStatusEnum.Expired,
      ],
      first: 20,
      after,
    },
    MYGIFTS_PATTERN.UNAVAILABLE,
  );

const getMyGiftsConnectionThunk =
  (
    variables: GetMyGiftsConnectionQueryVariables,
    myGiftsPattern: MyGiftsPatternTypes,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getMyGiftsConnectionStart(myGiftsPattern));

    return getMyGiftsConnection(variables)
      .then((response) =>
        dispatch(getMyGiftsConnectionSuccessThunk(response, myGiftsPattern)),
      )
      .catch((error) =>
        dispatch(getMyGiftsConnectionFailureThunk(error, myGiftsPattern)),
      );
  };

const getMyGiftsConnectionSuccessThunk =
  (
    response: GetMyGiftsConnectionResponse,
    myGiftsPattern: MyGiftsPatternTypes,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { myGiftsConnection } = response.data!;
    dispatch(getMyGiftsConnectionSuccess(myGiftsConnection, myGiftsPattern));
    return Promise.resolve();
  };

const getMyGiftsConnectionFailureThunk =
  (
    error: ApiError,
    myGiftsPattern: MyGiftsPatternTypes,
  ): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getMyGiftsConnectionFailure(error, myGiftsPattern));
    return dispatch(handleErrorThunk(error));
  };

const MYGIFTS_PATTERN = {
  POINT_CONVERTABLE: 'POINT_CONVERTABLE',
  AVAILABLE: 'AVAILABLE',
  EXPIRING: 'EXPIRING',
  UNAVAILABLE: 'UNAVAILABLE',
};
type MyGiftsPatternTypes =
  (typeof MYGIFTS_PATTERN)[keyof typeof MYGIFTS_PATTERN];

export type { MyGiftsPatternTypes };
export {
  getPointConvertableMyGiftsConnectionThunk,
  getAvailableMyGiftsConnectionThunk,
  getExpiringMyGiftsConnectionThunk,
  getUnavailableMyGiftsConnectionThunk,
  MYGIFTS_PATTERN,
};
