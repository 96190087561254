const types = {
  GET_GIFT_CONVERT_TO_POINT_REQUEST_START:
    'api/GET_GIFT_CONVERT_TO_POINT_REQUEST_START',
  GET_GIFT_CONVERT_TO_POINT_REQUEST_SUCCESS:
    'api/GET_GIFT_CONVERT_TO_POINT_REQUEST_SUCCESS',
  GET_GIFT_CONVERT_TO_POINT_REQUEST_FAILURE:
    'api/GET_GIFT_CONVERT_TO_POINT_REQUEST_FAILURE',
} as const;

export { types };
