import { getBrand } from '/@/api/graphql/internalApi/queries/getBrand/index';
import type { GetBrandResponse } from '/@/api/graphql/internalApi/queries/getBrand/index';
import type { AppThunk } from '/@/store';
import { getBrandStart, getBrandSuccess, getBrandFailure } from './actions';
import type {
  GetBrandQuery,
  GetBrandQueryVariables,
} from '/@/api/graphql/internalApi/types';
import { handleErrorThunk } from '../../handleError';
import type { ApiError } from '/@/api/graphql/client';

const getBrandThunk =
  (
    variables: GetBrandQueryVariables,
  ): AppThunk<Promise<GetBrandQuery | void>> =>
  (dispatch) => {
    dispatch(getBrandStart());

    return getBrand(variables)
      .then((response) => {
        dispatch(getBrandSuccessThunk(response));
        return response.data!;
      })
      .catch((error) => dispatch(getBrandFailureThunk(error)));
  };

const getBrandSuccessThunk =
  (response: GetBrandResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { brand } = response.data!;
    dispatch(getBrandSuccess(brand));
    return Promise.resolve();
  };

const getBrandFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(getBrandFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { getBrandThunk };
