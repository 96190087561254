import React from 'react';
import { Typography, Grid2 as Grid, Box, Card } from '@mui/material';
import { styled } from '@mui/system';
import { Status, STATUS } from '/@/store/api/constants';
import { PartialLoader } from '/@/components/shared/loader/partialLoader/Index';
import { ExpiringMyGiftsState } from '/@/store/app/expiringMyGiftsConnection';
import { MyGift } from '/@/components/shared/myGift/Index';

const StyledRootContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    backgroundColor: theme.palette.background.default,
  },
  backgroundColor: theme.palette.background.contrast,
}));
const StyledContentBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
}));
const StyledCaption = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(),
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
}));
const StyledMyGiftsContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
  overflowX: 'auto',
  flexWrap: 'nowrap',
}));
const StyledMyGiftContainer = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(1),
  maxWidth: '90%',
}));
const StyledMyGift = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(),
}));

type Props = {
  expiredMyGifts: ExpiringMyGiftsState;
  infiniteScrollTarget: React.RefObject<HTMLDivElement>;
  getExpiringMyGiftsConnectionStatus: Status;
};

const Presenter: React.VFC<Props> = ({
  expiredMyGifts,
  infiniteScrollTarget,
  getExpiringMyGiftsConnectionStatus,
}) => (
  <>
    {expiredMyGifts?.length ? (
      <StyledRootContainer data-cy="expiringMyGifts">
        <StyledContentBox>
          <StyledCaption variant="caption">
            有効期限が近づいています
          </StyledCaption>
          <StyledMyGiftsContainer>
            {expiredMyGifts.map(
              (myGift) =>
                myGift && (
                  <StyledMyGiftContainer key={myGift.urlCode}>
                    <StyledMyGift>
                      <MyGift myGift={myGift} />
                    </StyledMyGift>
                  </StyledMyGiftContainer>
                ),
            )}
          </StyledMyGiftsContainer>
        </StyledContentBox>
      </StyledRootContainer>
    ) : (
      <></>
    )}
    {getExpiringMyGiftsConnectionStatus === STATUS.LOADING && <PartialLoader />}
    <div ref={infiniteScrollTarget} />
  </>
);

export { Presenter };
