import { Reducer } from 'redux';
import { getCurrentUserTypes } from '/@/store/api/queries/getCurrentUser';
import { readAnnouncementsTypes } from '/@/store/api/mutations/readAnnouncements';
import type { RootAction } from '/@/store/actions';
import { GetCurrentUserQuery } from '/@/api/graphql/internalApi/types';

type CurrentUserState = CurrentUser | null;
type CurrentUser = GetCurrentUserQuery['currentUser'];

const currentUser: Reducer<CurrentUserState, RootAction> = (
  state = null,
  action,
) => {
  switch (action.type) {
    case getCurrentUserTypes.GET_CURRENT_USER_SUCCESS:
      return action.currentUser;
    case readAnnouncementsTypes.READ_ANNOUNCEMENTS_SUCCESS:
      return action.user;
    default:
      return state;
  }
};

export { currentUser };
export type { CurrentUserState, CurrentUser };
