import { Reducer } from 'redux';
import { getPublicAssetsTypes } from '/@/store/api/queries/getPublicAssets';
import type { RootAction } from '/@/store/actions';
import { GetPublicAssetsQuery } from '/@/api/graphql/publicApi/types';

type PublicAssetsState = PublicAssets | null;
type PublicAssets = GetPublicAssetsQuery['publicAssets'];

const publicAssets: Reducer<PublicAssetsState, RootAction> = (
  state = null,
  action,
) => {
  switch (action.type) {
    case getPublicAssetsTypes.GET_PUBLIC_ASSETS_SUCCESS:
      return action.publicAssets;
    default:
      return state;
  }
};

export { publicAssets };
export type { PublicAssetsState, PublicAssets };
