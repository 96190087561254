import React from 'react';
import { Typography, Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';

export const FOOTER_HEIGHT = 92;
const StyledFooter = styled('footer')(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  backgroundColor: theme.palette.background.hint,
  width: '100%',
  height: `${FOOTER_HEIGHT}px`,
  minHeight: `${FOOTER_HEIGHT}px`,
}));
const StyledCopyright = styled(Typography)(({ theme }) => ({
  display: 'block',
  textAlign: 'center',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  color: theme.palette.secondary.main,
}));
const StyledItemGrid = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(),
}));
const StyledATag = styled('a')({
  textDecoration: 'none',
});
const StyledLink = styled(Link)({
  textDecoration: 'none',
});
const StyledLinkTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
}));

const Presenter: React.VFC<Record<string, never>> = () => (
  <StyledFooter data-cy="footer">
    <Grid container justifyContent="center" alignItems="center">
      <StyledItemGrid>
        <StyledATag
          href="https://giftee.co.jp/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledLinkTitle variant="caption">運営会社</StyledLinkTitle>
        </StyledATag>
      </StyledItemGrid>
      <StyledItemGrid>
        <StyledLink to={PATHS.FREQUENTLY_ASKED_QUESTIONS}>
          <StyledLinkTitle variant="caption">よくあるご質問</StyledLinkTitle>
        </StyledLink>
      </StyledItemGrid>
      <StyledItemGrid>
        <StyledLink to={PATHS.TERMS_OF_SERVICE}>
          <StyledLinkTitle variant="caption">サービス利用規約</StyledLinkTitle>
        </StyledLink>
      </StyledItemGrid>
    </Grid>
    <StyledCopyright>©︎giftee, inc.</StyledCopyright>
  </StyledFooter>
);

export { Presenter };
