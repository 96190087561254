const PATHS = {
  ROOT: '/',
  LP_CONVERT_TO_POINT: '/lp/convert_to_point',
  LP_CHARGE_SERIAL_CODE: '/lp/charge_serial_code',
  LP_CAMPAIGN_TIKTOK: '/lp/campaign_tiktok',
  NON_EXISTENT_LP: '/lp/*',
  SIGN_IN: '/sign_in',
  OAUTH_AUTHORIZATION: '/oauth/authorization',
  ANNOUNCEMENTS_FOR_YOU: '/announcements/for_you',
  ANNOUNCEMENTS_FOR_ALL: '/announcements/for_all',
  ANNOUNCEMENT_DETAIL: '/announcements/:uid',
  PROMOTIONS: '/promotions',
  HOME: '/home',
  GIFTS: '/gifts',
  GIFTS_NEW: '/gifts/new',
  GIFT: '/gifts/:urlCode',
  GIFTS_EDIT: '/gifts/edit',
  INQUIRIES_NEW: '/inquiries/new',
  INQUIRIES_COMPLETION: '/inquiries/completion',
  TERMS_OF_SERVICE: '/terms_of_service',
  FREQUENTLY_ASKED_QUESTIONS: '/frequently_asked_questions',
  USER_PROFILE_NEW: '/user/profile/new',
  POINT_BRANDS: '/point/brands',
  BRAND_ITEMS: '/point/brands/:uid/items',
  ITEMS: '/point/brands/items',
  POINT_POINT_LOGS: '/point/point_logs',
  POINT_CHARGE: '/point/charge',
  POINT_CHARGE_COMPLETION: '/point/charge/completion',
  POINT_CHARGE_SERIAL_CODE_NEW: '/point/charge/serial_code/new',
  POINT_CHARGE_SERIAL_CODE_COMPLETION: '/point/charge/serial_code/completion',
  POINT_CHARGE_SERIAL_CODE_ERROR: '/point/charge/serial_code/error',
  POINT_EXCHANGE_COMPLETION: '/point_exchange/completion',
  NOT_FOUND: '*',
} as const;

type PathKeys = keyof typeof PATHS;
export type PathValues = (typeof PATHS)[PathKeys];

export { PATHS };
