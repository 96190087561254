import { Reducer } from 'redux';
import type { GetPointFeatureGroupsQuery } from '/@/api/graphql/internalApi/types';
import type { RootAction } from '/@/store/actions';
import { combineReducers } from 'redux';
import { getPointFeatureGroupsTypes } from '/@/store/api/queries/getPointFeatureGroups';

type PointFeatureGroups =
  GetPointFeatureGroupsQuery['pointFeatureGroups']['nodes'];
type PageInfoState =
  | GetPointFeatureGroupsQuery['pointFeatureGroups']['pageInfo']
  | null;
type PointFeatureGroupsState = {
  pointFeatureGroups: PointFeatureGroups;
  pageInfo: PageInfoState;
};

const initialState: PointFeatureGroups = null;
const pageInfoInitialState: PageInfoState = null;

const nodes: Reducer<PointFeatureGroups, RootAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getPointFeatureGroupsTypes.GET_POINT_FEATURE_GROUPS_SUCCESS: {
      if (state === null) {
        return action.pointFeatureGroupsResponse.nodes;
      } else {
        const uniquePointFeatureGroups = Array.from(
          new Map(
            [...state, ...(action.pointFeatureGroupsResponse.nodes ?? [])].map(
              (pointFeatureGroups) => [
                pointFeatureGroups?.uid,
                pointFeatureGroups,
              ],
            ),
          ).values(),
        );
        return uniquePointFeatureGroups;
      }
    }
    default:
      return state;
  }
};

const pageInfo: Reducer<PageInfoState, RootAction> = (
  state = pageInfoInitialState,
  action,
) => {
  switch (action.type) {
    case getPointFeatureGroupsTypes.GET_POINT_FEATURE_GROUPS_SUCCESS: {
      return action.pointFeatureGroupsResponse.pageInfo;
    }
    default:
      return state;
  }
};

const pointFeatureGroups = combineReducers({
  nodes,
  pageInfo,
});

export { pointFeatureGroups };
export type { PointFeatureGroupsState, PointFeatureGroups };
