import { RootState } from '/@/store';
import { createSelector } from 'reselect';
import { formatToDateJa } from '/@/utils/formatDate';
import {
  GetMyGiftsConnectionQuery,
  MyGiftSortConditionTargetEnumType,
} from '/@/api/graphql/internalApi/types';
import { MyGiftBase } from '/@/store/app/shared/types';
import { AvailableMyGiftsState } from '/@/store/app/availableMyGiftsConnection';

export type GroupedAvailableMyGifts = {
  [key: string]: GetMyGiftsConnectionQuery['myGiftsConnection']['nodes'];
};

const formatGroupTarget = (
  target: MyGiftSortConditionTargetEnumType,
  myGift: MyGiftBase,
) => {
  switch (target) {
    case MyGiftSortConditionTargetEnumType.ChangedAt:
      return formatToDateJa(myGift.changedAt);
    case MyGiftSortConditionTargetEnumType.UnavailableAt:
      return myGift.unavailableAt
        ? formatToDateJa(myGift.unavailableAt)
        : '発行中';
    default:
      return formatToDateJa(myGift.changedAt);
  }
};

const selectAvailableMyGifts = (state: RootState) =>
  state.app.availableMyGiftsConnection.myGifts;

const groupAvailableMyGifts = (
  availableMyGifts: AvailableMyGiftsState,
  groupTarget: MyGiftSortConditionTargetEnumType,
): GroupedAvailableMyGifts => {
  if (!availableMyGifts) {
    return {};
  }

  return availableMyGifts.reduce((groupedAvailableMyGifts, availableMyGift) => {
    const formattedGroupTarget = formatGroupTarget(
      groupTarget,
      availableMyGift as MyGiftBase,
    );

    const availableMyGiftsGroup = groupedAvailableMyGifts[formattedGroupTarget];

    if (availableMyGiftsGroup) {
      return {
        ...groupedAvailableMyGifts,
        [formattedGroupTarget]: [...availableMyGiftsGroup, availableMyGift],
      };
    } else {
      return {
        ...groupedAvailableMyGifts,
        [formattedGroupTarget]: [availableMyGift],
      };
    }
  }, {} as GroupedAvailableMyGifts);
};

export const selectUnavailableAtGroupedAvailableMyGifts = createSelector(
  [selectAvailableMyGifts],
  (availableMyGifts): GroupedAvailableMyGifts =>
    groupAvailableMyGifts(
      availableMyGifts,
      MyGiftSortConditionTargetEnumType.UnavailableAt,
    ),
);

export const selectChangedAtGroupedAvailableMyGifts = createSelector(
  [selectAvailableMyGifts],
  (availableMyGifts): GroupedAvailableMyGifts =>
    groupAvailableMyGifts(
      availableMyGifts,
      MyGiftSortConditionTargetEnumType.ChangedAt,
    ),
);

export const selectIsGiftsEditPageButtonDisabled = createSelector(
  [selectAvailableMyGifts],
  (availableMyGifts): boolean =>
    !availableMyGifts || availableMyGifts.length === 0,
);
