import React, { useEffect } from 'react';
import { Presenter } from './Presenter';
import { useAppDispatch, useAppSelector } from '/@/store';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '/@/routes/paths';
import { createPointChargeWithSerialCodeThunk } from '/@/store/api/mutations/createPointChargeWithSerialCode';
import { STATUS } from '/@/store/api/constants';
import { getCurrentUserThunk } from '/@/store/api/queries/getCurrentUser';
import { selectCurrentUserProfile } from '/@/store/app/currentUser/selectors';

const Container: React.VFC<Record<string, never>> = () => {
  const { search } = location;
  const urlSearchParams = new URLSearchParams(search);
  const serialCode = urlSearchParams.get('serial_code');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const createPointChargeWithSerialCodeStatus = useAppSelector(
    (state) => state.api.createPointChargeWithSerialCode.status,
  );
  const pointChargeWithSerialCodeError = useAppSelector(
    (state) => state.app.pointChargeWithSerialCodeError?.errorMessage,
  );
  const currentUserProfile = useAppSelector(selectCurrentUserProfile);
  const getCurrentUserStatus = useAppSelector(
    (state) => state.api.getCurrentUser.status,
  );

  useEffect(() => {
    if (getCurrentUserStatus === STATUS.IDLE) {
      dispatch(getCurrentUserThunk()).catch(() => {});
    }
  }, [dispatch, getCurrentUserStatus]);

  useEffect(() => {
    if (getCurrentUserStatus === STATUS.SUCCESS && !currentUserProfile) {
      // プロフィール未登録の場合はプロフィール登録画面へ遷移
      navigate(
        {
          pathname: PATHS.USER_PROFILE_NEW,
          search: `?serial_code=${serialCode}`,
          hash: '',
        },
        { replace: true },
      );
    } else if (
      getCurrentUserStatus === STATUS.SUCCESS &&
      currentUserProfile &&
      serialCode &&
      createPointChargeWithSerialCodeStatus === STATUS.IDLE
    ) {
      // プロフィール登録済みの場合はシリアルコードを使ってポイントチャージ
      dispatch(
        createPointChargeWithSerialCodeThunk({ serialCode: serialCode }),
      );
    } else if (!serialCode) {
      // シリアルコードがない場合はホーム画面へ遷移
      navigate(PATHS.HOME);
    }
  }, [
    getCurrentUserStatus,
    currentUserProfile,
    navigate,
    serialCode,
    createPointChargeWithSerialCodeStatus,
    dispatch,
  ]);

  useEffect(() => {
    if (
      createPointChargeWithSerialCodeStatus === 'success' &&
      !pointChargeWithSerialCodeError
    ) {
      navigate(PATHS.POINT_CHARGE_SERIAL_CODE_COMPLETION);
    } else if (pointChargeWithSerialCodeError) {
      navigate(PATHS.POINT_CHARGE_SERIAL_CODE_ERROR);
    }
  }, [
    createPointChargeWithSerialCodeStatus,
    pointChargeWithSerialCodeError,
    navigate,
  ]);

  return <Presenter />;
};

export { Container as NewPage };
