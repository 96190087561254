import React from 'react';
import { Presenter } from './Presenter';
import { Presenter as NeoPresenter } from './NeoPresenter';
import { useAppSelector } from '/@/store';
import { selectIsGiftsEditPageButtonDisabled } from '/@/store/app/availableMyGiftsConnection/selectors';

const Container: React.VFC<Record<string, never>> = () => {
  const isGiftsEditPageButtonDisabled = useAppSelector(
    selectIsGiftsEditPageButtonDisabled,
  );
  const isRenewalDesign = useAppSelector((state) => state.app.renewalDesignFlag);
  return isRenewalDesign ? (
    <NeoPresenter isGiftsEditPageButtonDisabled={isGiftsEditPageButtonDisabled} />
  ) : (
    <Presenter isGiftsEditPageButtonDisabled={isGiftsEditPageButtonDisabled} />
  );
};

export { Container as HomePage };
