import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '/@/store';
import { getPromotionSpotsThunk } from '/@/store/api/queries/getPromotionSpots';
import { Presenter } from './Presenter';
import { PromotionSpotNameEnum } from '/@/api/graphql/internalApi/types';

const Container: React.VFC<Record<string, never>> = () => {
  const dispatch = useAppDispatch();
  const promotionSpots = useAppSelector((state) => state.app.promotionSpots);
  const getPromotionSpotsStatus = useAppSelector(
    (state) => state.api.getPromotionSpots.status,
  );

  useEffect(() => {
    dispatch(getPromotionSpotsThunk(PromotionSpotNameEnum.GetGift)).catch(
      () => {},
    );
  }, [dispatch]);

  return (
    <Presenter
      promotionSpots={promotionSpots}
      getPromotionSpotsStatus={getPromotionSpotsStatus}
    />
  );
};

export { Container as PromotionsPage };
