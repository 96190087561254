import { readAnnouncements } from '/@/api/graphql/internalApi/mutations/readAnnouncements';
import type { AppThunk } from '/@/store';
import {
  readAnnouncementsStart,
  readAnnouncementsSuccess,
  readAnnouncementsFailure,
} from './actions';
import type { ReadUserAnnouncementsInput } from '/@/api/graphql/internalApi/types';
import type { ReadUserAnnouncementsResponse } from '/@/api/graphql/internalApi/mutations/readAnnouncements';
import { handleErrorThunk } from '../../handleError';
import type { ApiError } from '/@/api/graphql/client';

const readAnnouncementsThunk =
  (input: ReadUserAnnouncementsInput): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(readAnnouncementsStart());
    return readAnnouncements(input)
      .then((response) => dispatch(readAnnouncementsSuccessThunk(response)))
      .catch((error) => dispatch(readAnnouncementsFailureThunk(error)));
  };

const readAnnouncementsSuccessThunk =
  (response: ReadUserAnnouncementsResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { user } = response.data!.readUserAnnouncements;
    dispatch(readAnnouncementsSuccess(user));
    return Promise.resolve();
  };

const readAnnouncementsFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(readAnnouncementsFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { readAnnouncementsThunk };
