import React from 'react';
import { CircularProgress, Grid2 as Grid } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Grid)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
});

const Presenter: React.VFC<Record<string, never>> = () => (
  <StyledContainer
    container
    justifyContent="center"
    alignItems="center"
    data-cy="pageLoader"
  >
    <CircularProgress size="3rem" color="primary" />
  </StyledContainer>
);

export { Presenter };
