import React from 'react';
import { Presenter } from './Presenter';

type OwnProps = {
  children: React.ReactNode;
};

const Container: React.FC<OwnProps> = ({ children }) => {
  return <Presenter>{children}</Presenter>;
};

export { Container as HeroSection };
