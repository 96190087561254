import React from 'react';
import { Presenter } from './Presenter';
import { selectLineAuthenticationFailedMessage } from '/@/store/api/mutations/signInOrSignUp/selectors';
import { useAppSelector } from '/@/store';
import { LoginOption } from '/@/components/shared/loginButton/Index';

type Props = LoginOption & {
  isSerialCodeOutOfPeriod: boolean;
  serialCodeEndAt: string | null;
  errorMessage: string | null;
};

const Container: React.VFC<Props> = ({
  url,
  origin,
  convertToPoint,
  destination,
  serialCode,
  isSerialCodeOutOfPeriod,
  serialCodeEndAt,
  errorMessage,
}) => {
  const lineAuthenticationFailedMessage = useAppSelector(
    selectLineAuthenticationFailedMessage,
  );

  return (
    <Presenter
      url={url}
      origin={origin}
      referer={null}
      convertToPoint={convertToPoint}
      destination={destination}
      serialCode={serialCode}
      authenticationErrorMessage={
        errorMessage ?? lineAuthenticationFailedMessage
      }
      isSerialCodeOutOfPeriod={isSerialCodeOutOfPeriod}
      serialCodeEndAt={serialCodeEndAt}
    />
  );
};

export { Container as LoginSection };
