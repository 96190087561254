import React from 'react';
import { styled } from '@mui/system';
import logo from '/@/assets/page/signInPage/heroSection/signin-hero-gift-wallet-logo.svg';

const StyledLogo = styled('img')({
  width: '100%',
});

type Props = Record<string, never>;

const Presenter: React.VFC<Props> = () => (
  <div data-cy="signInPageHeroSection">
    <StyledLogo src={logo} alt="gift wallet logo" />
  </div>
);

export { Presenter };
