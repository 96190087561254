import React, { useEffect, useState } from 'react';
import { Presenter } from './Presenter';
import { useAppSelector } from '/@/store';
import { useAppDispatch } from '/@/store/hooks';
import { getPointWalletThunk } from '/@/store/api/queries/getPointWallet';
import { isPointsCharged } from '/@/api/graphql/internalApi/queries/getPointLogs';
import { getPointConvertableMyGiftsConnectionThunk } from '/@/store/api/queries/getMyGiftsConnection';
import { initialisePointConvertableMyGiftsConnection } from '/@/store/app/pointConvertableMyGiftsConnection/actions';
import { formattedPointConvertableMyGifts } from '/@/store/page/homePage/pointSection/selectors';

const Container: React.VFC<Record<string, never>> = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPointWalletThunk()).catch(() => {});
  }, [dispatch]);

  const point = useAppSelector((state) => state.app.pointWallet?.point);
  const [display, setDisplay] = useState<boolean>(false);
  const pointMergeableMyGifts = useAppSelector(
    formattedPointConvertableMyGifts,
  );
  const pointConvertableMyGiftsConnection = useAppSelector(
    (state) => state.app.pointConvertableMyGiftsConnection.myGifts,
  );
  const pointConvertableMyGiftsConnectionPageInfo = useAppSelector(
    (state) => state.app.pointConvertableMyGiftsConnection.pageInfo,
  );
  const pointConvertableMyGiftsConnectionStatus = useAppSelector(
    (state) => state.app.pointConvertableMyGiftsConnection.status,
  );

  useEffect(() => {
    if (!display) {
      if (
        (typeof point === 'number' && point > 0) ||
        pointMergeableMyGifts.length > 0
      ) {
        setDisplay(true);
      } else if (point === 0) {
        // MEMO: ポイントロードしたがポイントが0の場合にチャージ実績を確認する
        isPointsCharged(setDisplay);
      }
    }
  }, [dispatch, display, point, pointMergeableMyGifts]);

  useEffect(() => {
    if (pointConvertableMyGiftsConnection) {
      dispatch(initialisePointConvertableMyGiftsConnection());
    }
    dispatch(
      getPointConvertableMyGiftsConnectionThunk({
        after: null,
        first: 20,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (pointConvertableMyGiftsConnectionPageInfo?.hasNextPage) {
      dispatch(
        getPointConvertableMyGiftsConnectionThunk({
          after: pointConvertableMyGiftsConnectionPageInfo.endCursor,
          first: 20,
        }),
      );
    }
  }, [dispatch, pointConvertableMyGiftsConnectionPageInfo]);

  return (
    <Presenter
      display={display}
      pointMergeableMyGifts={pointMergeableMyGifts}
      pointConvertableMyGiftsConnectionStatus={pointConvertableMyGiftsConnectionStatus}
    />
  );
};

export { Container as PointSection };
