import { discardMyGifts } from '/@/api/graphql/internalApi/mutations/discardMyGifts';
import type { DiscardMyGiftsResponse } from '/@/api/graphql/internalApi/mutations/discardMyGifts';
import type { AppThunk } from '/@/store';
import {
  discardMyGiftsIdle,
  discardMyGiftsStart,
  discardMyGiftsSuccess,
  discardMyGiftsFailure,
} from './actions';
import type { DiscardMyGiftsInput } from '/@/api/graphql/internalApi/types';
import { handleErrorThunk } from '../../handleError';
import type { ApiError } from '/@/api/graphql/client';

const discardMyGiftsThunk =
  (input: DiscardMyGiftsInput): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(discardMyGiftsStart());
    return discardMyGifts(input)
      .then((response) => dispatch(discardMyGiftsSuccessThunk(response)))
      .catch((error) => dispatch(discardMyGiftsFailureThunk(error)))
      .finally(() => dispatch(discardMyGiftsIdle()));
  };

const discardMyGiftsSuccessThunk =
  (response: DiscardMyGiftsResponse): AppThunk<Promise<void>> =>
  (dispatch) => {
    const { myGifts } = response.data!.discardMyGifts;
    dispatch(
      discardMyGiftsSuccess(
        myGifts,
        `${myGifts.length}件のマイギフトを「利用済み」にしました`,
      ),
    );
    return Promise.resolve();
  };

const discardMyGiftsFailureThunk =
  (error: ApiError): AppThunk<Promise<void>> =>
  (dispatch) => {
    dispatch(discardMyGiftsFailure(error));
    return dispatch(handleErrorThunk(error));
  };

export { discardMyGiftsThunk };
