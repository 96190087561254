import { Reducer } from 'redux';
import { GetBrandsConnectionQuery } from '/@/api/graphql/internalApi/types';
import { getBrandsConnectionTypes } from '/@/store/api/queries/getBrandsConnection';
import { BrandsConnectionBase } from '/@/store/app/shared/types';
import type { RootAction } from '/@/store/actions';
import { combineReducers } from 'redux';
import { STATUS, Status } from '/@/store/api/constants';
import {
  BRANDS_PATTERN,
  BrandsPatternTypes,
} from '/@/store/api/queries/getBrandsConnection/operations';

type RecommendedBrandsState = GetBrandsConnectionQuery['brands']['nodes'];
type PageInfoState = GetBrandsConnectionQuery['brands']['pageInfo'] | null;
type RecommendedBrandsConnectionState = {
  brands: RecommendedBrandsState;
  status: Status;
  pageInfo: PageInfoState;
};
type RecommendedBrand = BrandsConnectionBase;

const initialState: RecommendedBrandsState = null;
const pageInfoInitialState: PageInfoState = null;

const brands: Reducer<RecommendedBrandsState, RootAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case getBrandsConnectionTypes.GET_BRANDS_CONNECTION_SUCCESS: {
      if (!isMatchedPattern(action.pattern)) {
        return state;
      }
      if (!action.brandsConnection?.nodes) {
        return state;
      }

      if (state === null) {
        return action.brandsConnection.nodes;
      } else {
        const uniqueBrands = Array.from(
          new Map(
            [...state, ...action.brandsConnection.nodes].map((brand) => [
              brand?.uid,
              brand,
            ]),
          ).values(),
        );
        return uniqueBrands;
      }
    }
    default:
      return state;
  }
};

const status: Reducer<Status, RootAction> = (state = STATUS.IDLE, action) => {
  switch (action.type) {
    case getBrandsConnectionTypes.GET_BRANDS_CONNECTION_START: {
      if (!isMatchedPattern(action.pattern)) {
        return state;
      } else {
        return STATUS.LOADING;
      }
    }
    case getBrandsConnectionTypes.GET_BRANDS_CONNECTION_SUCCESS: {
      if (!isMatchedPattern(action.pattern)) {
        return state;
      } else {
        return STATUS.SUCCESS;
      }
    }
    case getBrandsConnectionTypes.GET_BRANDS_CONNECTION_FAILURE: {
      if (!isMatchedPattern(action.pattern)) {
        return state;
      } else {
        return STATUS.FAILURE;
      }
    }
    default:
      return state;
  }
};

const pageInfo: Reducer<PageInfoState, RootAction> = (
  state = pageInfoInitialState,
  action,
) => {
  switch (action.type) {
    case getBrandsConnectionTypes.GET_BRANDS_CONNECTION_SUCCESS: {
      if (!isMatchedPattern(action.pattern)) {
        return state;
      }
      return action.brandsConnection.pageInfo;
    }
    default:
      return state;
  }
};

const recommendedBrandsConnection = combineReducers({
  brands,
  status,
  pageInfo,
});

const isMatchedPattern = (pattern: BrandsPatternTypes): boolean =>
  pattern === BRANDS_PATTERN.RECOMMENDED;

export { recommendedBrandsConnection };
export type {
  RecommendedBrandsConnectionState,
  RecommendedBrand,
  RecommendedBrandsState,
};
