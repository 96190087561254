import { RootState } from '/@/store';
import { createSelector } from 'reselect';
import { typeGuards } from '/@/utils/typeGuards';
import { MutationError, MutationErrors } from '/@/api/graphql/client';
import { STATUS, Status } from '/@/store/api/constants';
import { ErrorCodeEnumType } from '/@/api/graphql/internalApi/types';

export type ErrorMessage = string | null;

const selectSaveGifteeContentError = (state: RootState) =>
  state.api.saveGifteeContent.error;

export const selectSaveGifteeContentStatus = (state: RootState): Status =>
  state.api.saveGifteeContent.status;

export const selectIsSaveGifteeContentSuccess = createSelector(
  [selectSaveGifteeContentStatus],
  (saveGifteeContentStatus): boolean =>
    saveGifteeContentStatus === STATUS.SUCCESS,
);

const selectMutationErrors = createSelector(
  [selectSaveGifteeContentError],
  (saveGifteeContentError): MutationErrors | null => {
    if (!saveGifteeContentError) {
      return null;
    }

    if (!typeGuards.api.isMutationErrors(saveGifteeContentError)) {
      return null;
    }
    return saveGifteeContentError;
  },
);

export const selectDuplicatedGifteeContentErrorMessage = createSelector(
  [selectMutationErrors],
  (mutationErrors): ErrorMessage => {
    if (!mutationErrors) return null;

    const duplicatedError = mutationErrors.find((error) =>
      isDuplicatedGifteeContentError(error),
    );

    return duplicatedError ? duplicatedError.message : null;
  },
);

const isDuplicatedGifteeContentError = (error: MutationError) =>
  error.extensions.code === ErrorCodeEnumType.DuplicatedGifteeContent;
